import styled from '@emotion/styled';
import { createElement } from 'react';

import { Grey, White } from '../../styles/Colors';
import { FontSize, FontWeight } from '../../styles/Typography';
import LargeBulletIcon from '../assets/icons/LargeBulletIcon.svg';
import MediumBulletIcon from '../assets/icons/MediumBulletIcon.svg';
import SmallBulletIcon from '../assets/icons/SmallBulletIcon.svg';
import { TitleProps } from './Title';

export const StyledTitle = styled((props: TitleProps) => {
  const { size, tag, children, ...rest } = props;
  let Tag;
  if (tag) {
    Tag = tag;
  } else {
    Tag = size === 'lg' ? 'h3' : size === 'md' ? 'h4' : 'h5';
  }
  return createElement(Tag, rest, children);
})(({ size, color }: TitleProps) => {
  const beforeStyles = {
    content: '""',
    display: 'inline-block',
    marginRight: '0.5rem',
  };
  if (size === 'lg') {
    return {
      marginTop: '0',
      marginBottom: '0',
      color: color || White,
      fontSize: FontSize['Xl'],
      fontWeight: FontWeight['Bold'],
      lineHeight: '150%',
      '&:before': {
        ...beforeStyles,
        width: '2rem',
        height: '0.875rem',
        backgroundColor: color || White,
        maskImage: `url(${LargeBulletIcon})`,
        maskRepeat: 'no-repeat',
      },
    };
  } else if (size === 'md') {
    return {
      marginTop: '0',
      marginBottom: '0',
      color: color || Grey['50'],
      fontSize: FontSize['Lg'],
      fontWeight: FontWeight['SemiBold'],
      lineHeight: '150%',
      '&:before': {
        ...beforeStyles,
        backgroundColor: color || Grey['50'],
        maskImage: `url(${MediumBulletIcon})`,
        maskRepeat: 'no-repeat',
        width: '1.25rem',
        height: '0.75rem',
      },
    };
  } else {
    return {
      marginTop: '0',
      marginBottom: '0',
      color: color || Grey['100'],
      fontSize: FontSize['Sm'],
      fontWeight: FontWeight['SemiBold'],
      lineHeight: '150%',
      '&:before': {
        ...beforeStyles,
        backgroundColor: color || Grey['100'],
        maskImage: `url(${SmallBulletIcon})`,
        maskRepeat: 'no-repeat',
        width: '0.75rem',
        height: '0.625rem',
      },
    };
  }
});
