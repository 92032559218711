import classNames from 'classnames';
import {
  PropsWithChildren,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';

import {
  SLIDER_DEFAULT_VALUE,
  SLIDER_MARK_LENGTH,
  SLIDER_MAX,
  SLIDER_MIN,
  SLIDER_STEP,
  SLIDER_THUMB_WIDTH,
} from './const';
import { StyledSlider } from './StyledSlider';

interface SliderProps {
  min?: number;
  max?: number;
  step?: number;
  defaultValue?: number;
  showMarks?: boolean;
  minMark?: boolean;
  maxMark?: boolean;
  markLength?: number;
  minLabel?: string;
  maxLabel?: string;
  showLabel?: boolean;
  disabled?: boolean;
  className?: string;
  value?: number;
  onChange: (value: number) => void;
  onEnd?: (value: number) => void;
}

export interface Mark {
  value: number;
  label?: string;
}

const Slider = ({
  min = SLIDER_MIN,
  max = SLIDER_MAX,
  defaultValue = SLIDER_DEFAULT_VALUE,
  showMarks = true,
  minMark = true,
  maxMark = true,
  markLength = SLIDER_MARK_LENGTH,
  minLabel,
  maxLabel,
  showLabel = true,
  step = SLIDER_STEP,
  disabled = false,
  className = '',
  children,
  value = defaultValue,
  onChange,
  onEnd,
}: PropsWithChildren<SliderProps>) => {
  const [thumbPosition, setThumbPosition] = useState<number>(0);
  const sliderRef = useRef<HTMLInputElement>(null);
  const [marks, setMarks] = useState<Mark[]>([]);

  const updateMarks = useCallback(() => {
    const MARK_LENGTH = markLength;
    let items: Mark[] = new Array(MARK_LENGTH).fill(undefined).map((_, i) => {
      const item: Mark = { value: min + (max - min) * (i / (MARK_LENGTH - 1)) };
      return item;
    });
    if (minMark) {
      items[0] = { value: min, label: `${min}` };
    }
    if (maxMark) {
      items[items.length - 1] = { value: max, label: `${max}` };
    }
    setMarks(items);
  }, [min, max, minMark, maxMark, markLength]);

  const resetValue = useCallback(() => {
    onChange(defaultValue || 0);
  }, [defaultValue, onChange]);

  useEffect(() => {
    if (!sliderRef.current) return;
    const resizeObserver = new ResizeObserver(() => {
      setThumbPosition(
        ((value - min) / (max - min)) *
          ((sliderRef.current?.offsetWidth ?? 0) - SLIDER_THUMB_WIDTH)
      );
    });
    resizeObserver.observe(sliderRef.current);
    return () => {
      resizeObserver.disconnect();
    };
  }, [value, min, max, sliderRef]);

  useEffect(() => {
    updateMarks();
  }, [updateMarks]);

  return (
    <StyledSlider
      className={classNames(`sup-slider`, className, { simple: !showMarks })}
    >
      {minLabel && <p className="slider-label">{minLabel}</p>}
      <div
        className={classNames(`slider`, {
          'hide-label': !showLabel || !(minLabel && maxLabel),
        })}
      >
        <input
          type="range"
          className="slider-range"
          min={min}
          max={max}
          step={step}
          value={value}
          onMouseUp={() => onEnd?.(value)}
          onChange={(e) => onChange(parseFloat(e.target.value))}
          ref={sliderRef}
          onDoubleClick={() => resetValue()}
          disabled={disabled}
        />
        <div
          className="slider-track"
          style={{
            width: `${thumbPosition}px`,
          }}
        ></div>
        <div
          className="slider-thumb"
          style={{
            left: `${thumbPosition}px`,
          }}
        ></div>
      </div>
      {showMarks && (
        <div className="slider-marks">
          {marks.map((mark, i) => (
            <div
              key={i}
              className="slider-mark"
              style={{
                left: `${((mark.value - min) / (max - min)) * 100}%`,
              }}
              onClick={() => onChange(mark.value)}
            >
              {mark.label && (
                <span className="slider-mark-label">{mark.label}</span>
              )}
            </div>
          ))}
        </div>
      )}
      {maxLabel && <p className="slider-label">{maxLabel}</p>}
      {children}
    </StyledSlider>
  );
};

export default Slider;
