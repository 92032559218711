import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { formatSToMMSS } from '../../../util/formatter';
import { ReactComponent as BackwardIcon } from '../assets/icons/BackwardIcon.svg';
import { ReactComponent as ForwardIcon } from '../assets/icons/ForwardIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/PauseIcon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/PlayIcon.svg';
import {
  AudioSource,
  useMultiAudioController,
} from '../hooks/useMultiAudioController';
import {
  AudioFile,
  audioFileMapModel,
  audioPlayerStateModel,
  currentPlaybackModel,
  currentPlayingAudioInfoListModel,
  currentPlayingAudioItemSelector,
} from '../stores/audios';
import { defaultAudioFileList } from '../stores/data/bgm';
import {
  editingLineIdModel,
  lineListModel,
  projectLoadingModel,
  selectedLineIdSelector,
  selectedProjectIdModel,
} from '../stores/project';
import {
  timelineListSelector,
  timelinePlaybackModel,
} from '../stores/timeline';
import { videoPlayerStateModel } from '../stores/video';
import { StyledGlobalAudioPlayer } from '../styles/StyledGlobalAudioPlayer';
import PanelButtonGroup from './PanelButtonGroup';
import VolumeSlider from './VolumeSlider';

const GlobalAudioPlayer = () => {
  const setCurrentPlayingAdioInfo = useSetRecoilState(
    currentPlayingAudioInfoListModel
  );
  const selectedProjectId = useRecoilValue(selectedProjectIdModel);

  const timelineList = useRecoilValue(timelineListSelector);
  const currentPlayingAudio = useRecoilValue(currentPlayingAudioItemSelector);
  const audioFileMap = useRecoilValue(audioFileMapModel);
  const [timePlayback, setTimePlayback] = useRecoilState(timelinePlaybackModel);
  const editingLineId = useRecoilValue(editingLineIdModel);
  const lineList = useRecoilValue(lineListModel);
  const selectedLineId = useRecoilValue(selectedLineIdSelector);

  const selectedLine = useMemo(() => {
    return lineList.find((line) => line.id === selectedLineId);
  }, [lineList, selectedLineId]);

  const setAudioFileMap = useSetRecoilState(audioFileMapModel);
  const setCurrentPlayback = useSetRecoilState(currentPlaybackModel);
  const [audioPlayerState, setAudioPlayerState] = useRecoilState(
    audioPlayerStateModel
  );
  const setVideoPlayerState = useSetRecoilState(videoPlayerStateModel);

  const projectLoading = useRecoilValue(projectLoadingModel);

  const [isLoaded, setIsLoaded] = useState(false);
  const [audioSources, setAudioSources] = useState<AudioSource[]>([]);

  // TODO: Bgm 로딩도 기존 오디오 파일과 동시에 할지 확인 필요
  const fetchAudioFiles = useCallback(async () => {
    const audioContext = new AudioContext();
    const audioFileMap = await Promise.all(
      defaultAudioFileList.map(async (audio) => {
        const response = await fetch(audio.src);
        const arrayBuffer = await response.arrayBuffer();
        const audioBuffer = await audioContext.decodeAudioData(arrayBuffer);
        const fileName = audio?.fileName || audio.src.split('/').pop() || '';

        return {
          id: audio.id,
          src: audio.src,
          audioBuffer,
          fileName,
        };
      })
    );
    const audioFileMapObject = audioFileMap.reduce((acc, audio) => {
      acc[audio.id] = {
        src: audio.src,
        audioBuffer: audio.audioBuffer,
        fileName: audio.fileName,
      };
      return acc;
    }, {} as Record<string, AudioFile>);
    setAudioFileMap(audioFileMapObject);
  }, [setAudioFileMap]);

  // 기본 설정된 오디오 파일이 있다면 map에 설정
  useEffect(() => {
    fetchAudioFiles();
  }, [setAudioFileMap, fetchAudioFiles]);

  useEffect(() => {
    setIsLoaded(!projectLoading);
  }, [projectLoading]);

  const {
    currentPosition,
    totalDuration,
    gainValue,
    isPlaying,
    isNext,
    isPrev,
    currentAudioInfo,
    play,
    pause,
    stop,
    forward,
    backward,
    updateVolume,
    seek,
  } = useMultiAudioController({
    audioSources,
  });

  const handlePlayPause = useCallback(() => {
    if (isPlaying) {
      pause();
    } else {
      play();
    }
  }, [isPlaying, play, pause]);

  // audioPlayerState에 오디오 재생 상태를 저장
  useEffect(() => {
    setAudioPlayerState({
      type: 'timeline',
      isPlaying,
    });
  }, [isPlaying, setAudioPlayerState]);

  // timeline 외(Line 플레이어 등)에서 재생 중이면 멈춤
  useEffect(() => {
    if (!audioPlayerState) {
      pause();
      return;
    }
    if (audioPlayerState?.type === 'timeline') return;
    if (audioPlayerState?.isPlaying) {
      pause();
    }
  }, [audioPlayerState, pause]);

  useEffect(() => {
    if (!isLoaded || !timelineList?.length) return;
    const audioSources = timelineList.reduce((acc, { items, voice }) => {
      items.forEach((item) => {
        if (!audioFileMap[item.id] || voice.disabled) return;
        const audioSource = {
          id: item.id,
          lineId: item.lineId,
          fileName: item.content,
          type: item.type,
          position: item.position,
          duration:
            item.type !== 'audio'
              ? audioFileMap[item.id].audioBuffer?.duration || 0
              : item.duration || 0,
          audioBuffer: audioFileMap[item.id].audioBuffer,
        };
        acc.push(audioSource);
      });
      return acc.sort((a, b) => a.position - b.position);
    }, [] as AudioSource[]);
    setAudioSources(audioSources);
  }, [timelineList, isLoaded, audioFileMap, setAudioSources]);

  useEffect(() => {
    if (!isLoaded) return;
    setCurrentPlayingAdioInfo(currentAudioInfo);
  }, [currentAudioInfo, setCurrentPlayingAdioInfo, isLoaded]);

  useEffect(() => {
    setCurrentPlayback(currentPosition);
  }, [currentPosition, setCurrentPlayback]);

  useEffect(() => {
    setVideoPlayerState({
      startPosition: currentPosition,
      isPlaying,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isPlaying, setVideoPlayerState]);

  useEffect(() => {
    seek(timePlayback);
    setVideoPlayerState({
      startPosition: timePlayback,
      isPlaying: false,
    });
  }, [timePlayback, seek, setVideoPlayerState]);

  // TODO: Register hotkeys (tmp code)
  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (editingLineId) return;
      switch (e.code) {
        case 'Enter':
          e.preventDefault();
          seek(0);
          break;
        case 'Space':
          e.preventDefault();
          handlePlayPause();
          break;
        case 'ArrowRight':
          e.preventDefault();
          forward();
          break;
        case 'ArrowLeft':
          e.preventDefault();
          backward();
          break;
        default:
          break;
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [editingLineId, forward, backward, handlePlayPause, seek]);

  useEffect(() => {
    if (!selectedLine) return;
    // 아직 생성되지 않은 line의 경우 이전 line의 position으로 이동
    if (typeof selectedLine.position !== 'number') {
      // 이전 line의 id를 찾아서 해당 line의 position으로 이동
      const currentLineIdx = lineList.findIndex(
        (line) => line.id === selectedLine.id
      );
      if (currentLineIdx === -1) return;
      const prevLine = lineList[currentLineIdx - 1];
      if (!prevLine?.position) return;
      setTimePlayback(prevLine.position);
      return;
    }
    setTimePlayback(selectedLine.position);
  }, [selectedLine, lineList, setTimePlayback]);

  // audioSource의 변경이 발생한 경우(bgm on/off, timeline에 아이템 추가) 일시정지
  useEffect(() => {
    if (isPlaying) {
      pause();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [audioSources]);

  // Reset current playback when selected project changed
  useEffect(() => {
    requestAnimationFrame(() => {
      stop();
    });
  }, [selectedProjectId, stop]);

  const progressPosition = useMemo(() => {
    const position =
      totalDuration === 0
        ? -100
        : ((currentPosition - totalDuration) / totalDuration) * 100;
    return position >= 0 ? 0 : position;
  }, [currentPosition, totalDuration]);

  return (
    <StyledGlobalAudioPlayer className="sup-player">
      <section className="progress">
        <div
          className="progress-bar"
          style={{
            transform: `translateX(${progressPosition}%)`,
          }}
        ></div>
      </section>
      <PanelButtonGroup />
      <section className="control-audio">
        <IconButton
          className={classNames('btn-play', isPlaying && 'active')}
          variant="none"
          onClick={handlePlayPause}
          disabled={!isLoaded}
        >
          {isPlaying ? <PauseIcon /> : <PlayIcon />}
        </IconButton>
        <IconButton
          className="btn-backward"
          variant="none"
          disabled={!isPrev}
          onClick={backward}
        >
          <BackwardIcon />
        </IconButton>
        <IconButton
          className="btn-forward"
          variant="none"
          disabled={!isNext}
          onClick={forward}
        >
          <ForwardIcon />
        </IconButton>
      </section>
      <section
        className={classNames('scene-info', !currentPlayingAudio && 'empty')}
      >
        {currentPlayingAudio && currentPlayingAudio.type === 'tts' && (
          <div className="scene-info-tts">
            <div className="number">{currentPlayingAudio.takeLabel}</div>
            <div
              className="thumb"
              style={{
                background: `url(${currentPlayingAudio.thumbnail}) center center / cover no-repeat`,
              }}
            ></div>
            <div className="content">{currentPlayingAudio.content}</div>
          </div>
        )}
        {currentPlayingAudio && currentPlayingAudio.type === 'audio' && (
          <p className="scene-info-audio">PLAYING</p>
        )}
      </section>
      <section className="time-info">
        {`${formatSToMMSS(currentPosition)} / ${formatSToMMSS(totalDuration)}`}
      </section>
      <VolumeSlider value={gainValue} update={updateVolume} />
    </StyledGlobalAudioPlayer>
  );
};
export default GlobalAudioPlayer;
