import classNames from 'classnames';
import i18next from 'i18next';
import { useState } from 'react';

import StyledControlPanel from '../styles/StyledControlPanel';
import { StyledTabArea } from '../styles/StyledTabArea';
import CommentTab from './CommentTab';
import ScriptTab from './ScriptTab';
import SpeechTab from './SpeechTab';

const tabCaptions = [
  i18next.t('screenPlay:Speech'),
  i18next.t('screenPlay:Script'),
  i18next.t('screenPlay:Comment'),
];

const ControlPanel = () => {
  const [selectedTab, setSelectedTab] = useState(0);
  return (
    <StyledControlPanel className="sp-top-panel sp-control">
      <StyledTabArea className="tab-area">
        <ul>
          {tabCaptions.map((caption, index) => (
            <li
              key={index}
              onClick={() => setSelectedTab(index)}
              className={classNames(selectedTab === index && 'on')}
            >
              <span>{caption}</span>
            </li>
          ))}
        </ul>
      </StyledTabArea>
      <section className="tab-content">
        {selectedTab === 0 && <SpeechTab />}
        {selectedTab === 1 && <ScriptTab />}
        {selectedTab === 2 && <CommentTab />}
      </section>
    </StyledControlPanel>
  );
};

export default ControlPanel;
