import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import Slider from '@/components/Slider/Slider';
import classNames from 'classnames';
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { ReactComponent as ControlIcon } from '../assets/icons/ControlIcon.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import { ReactComponent as LineStopIcon } from '../assets/icons/LineStopIcon.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import { ReactComponent as VerticalControlIcon } from '../assets/icons/VerticalControlIcon.svg';
import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import { getLanguage } from '../stores/data/voices';
import {
  ExtendVoice,
  Voice,
  enabledVoiceListSelector,
} from '../stores/project';
import StyledVoiceProfile from '../styles/StyledVoiceProfile';
import LoadingDots from './Loading';

interface VoiceLibraryItemProps {
  voice: ExtendVoice;
  isOpen: boolean;
  onComplete: (voice: Voice, age: number, gender: number) => void;
  toggleItem: (voiceId: string) => void;
  isPlaying: boolean;
  playAudio: (audioBuffer: AudioBuffer) => void;
  stopAudio: () => void;
  playingId: string | null;
  updatePlayingId: (id: string | null) => void;
  isLoading: boolean;
  updateIsLoading: (isLoading: boolean) => void;
}
const VoiceLibraryItem: React.FC<VoiceLibraryItemProps> = ({
  voice,
  isOpen,
  onComplete,
  toggleItem,
  playAudio,
  isPlaying,
  stopAudio,
  playingId,
  updatePlayingId,
  isLoading,
  updateIsLoading,
}) => {
  const { t } = useTranslation('screenPlay');
  const ref = useRef<HTMLLIElement>(null);
  useEffect(() => {
    isOpen &&
      ref?.current?.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
  }, [isOpen, ref]);

  const [age, setAge] = useState(voice.age);
  const [gender, setGender] = useState(voice.gender);
  const isChanged = useMemo(
    () => age !== voice.age || gender !== voice.gender,
    [age, gender, voice.age, voice.gender]
  );

  const [isCompleted, setCompleted] = useState(false);
  const [isAdded, setIsAdded] = useState(voice.used);
  const [isUsed, setIsUsed] = useState(voice.used);

  useEffect(() => {
    if (age !== voice.age || gender !== voice.gender) {
      setCompleted(false);
    }
  }, [age, gender, voice.age, voice.gender]);

  const updateModel = useCallback(() => {
    setCompleted(true);
    onComplete(voice, age, gender);
    setIsAdded(true);
    if (isAdded && !isUsed) {
      setIsUsed(true);
    }
  }, [
    setCompleted,
    onComplete,
    voice,
    age,
    gender,
    setIsAdded,
    isAdded,
    isUsed,
  ]);
  const buttonLabel = useMemo(() => {
    if (isCompleted) {
      return isAdded && isUsed ? t('Saved') : t('Added');
    } else {
      return isAdded ? t('Save') : t('Add');
    }
  }, [isCompleted, isAdded, isUsed, t]);

  // age or gender가 변경되면 voice file을 다시 가져온다.
  const { loadVoiceFile } = useSessionStorage();
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer | null>(null);
  const getVoiceFile = useCallback(
    async (settingAge: number = age, settingGender: number = gender) => {
      updateIsLoading(true);
      const { audioBuffer } = await loadVoiceFile(voice, {
        age: settingAge,
        gender: settingGender,
      });
      setAudioBuffer(audioBuffer);
      updateIsLoading(false);
      return audioBuffer;
    },
    [loadVoiceFile, age, gender, voice, updateIsLoading]
  );

  const handleParameterChange = useCallback(() => {
    updatePlayingId(voice.id);
    getVoiceFile();
  }, [getVoiceFile, updatePlayingId, voice.id]);

  const playVoice = useCallback(
    async (e: React.MouseEvent) => {
      e.stopPropagation();
      updatePlayingId(voice.id);
      if (isPlaying) {
        stopAudio();
        return;
      }
      const buffer = audioBuffer || (await getVoiceFile());
      playAudio(buffer);
    },
    [
      audioBuffer,
      getVoiceFile,
      playAudio,
      voice.id,
      isPlaying,
      stopAudio,
      updatePlayingId,
    ]
  );

  const defaultVoiceList = useRecoilValue(enabledVoiceListSelector);

  const defaultVoice = useMemo(
    () => defaultVoiceList.find((v) => v.id === voice.id),
    [voice, defaultVoiceList]
  );
  const isDefault = useMemo(() => {
    if (!defaultVoice) {
      return true;
    }

    return (
      (defaultVoice?.age | 0) === (age | 0) &&
      (defaultVoice?.gender | 0) === (gender | 0)
    );
  }, [defaultVoice, age, gender]);

  const resetVoice = useCallback(() => {
    if (defaultVoice) {
      setAge(defaultVoice?.age);
      setGender(defaultVoice?.gender);
      getVoiceFile(defaultVoice.age, defaultVoice.gender);
    }
  }, [defaultVoice, getVoiceFile]);

  return (
    <li
      className={classNames('voice-library-item', isOpen && 'open')}
      ref={ref}
    >
      <section className="item-summary" onClick={() => toggleItem(voice.id)}>
        <StyledVoiceProfile className="voice-profile">
          {voice.thumbnail ? (
            <span
              className="voice-image"
              style={{ backgroundImage: `url(${voice.thumbnail})` }}
            />
          ) : (
            <span className="voice-image empty">
              <em>{voice.name.charAt(0)}</em>
            </span>
          )}
        </StyledVoiceProfile>
        <span className="voice-control">
          <IconButton
            className="btn-voice-icon"
            isFillCurrentColor={false}
            onClick={playVoice}
            disabled={isLoading && playingId !== voice.id}
          >
            {isLoading && playingId === voice.id && <LoadingDots />}
            {(!isLoading || playingId !== voice.id) &&
              (isPlaying ? <LineStopIcon /> : <LinePlayIcon />)}
          </IconButton>
          <IconButton
            className="btn-voice-icon btn-open-slider"
            isFillCurrentColor={false}
            onClick={(e) => {
              e.stopPropagation();
              toggleItem(voice.id);
            }}
          >
            {isOpen ? <VerticalControlIcon /> : <ControlIcon />}
          </IconButton>
        </span>
        <span className="voice-name cell">{voice.name}</span>
        <span className="voice-gender cell">{voice.character}</span>
        <span className="voice-locale cell">{getLanguage(voice.language)}</span>
        <section className="voice-action">
          <Button
            className="btn-voice-action btn-reset"
            onClick={(e) => {
              e.stopPropagation();
              resetVoice();
            }}
            disabled={isDefault}
          >
            {t('Reset')}
          </Button>
          <Button
            className={classNames(
              'btn-voice-action',
              isCompleted && 'complete'
            )}
            onClick={(e) => {
              e.stopPropagation();
              updateModel();
            }}
            disabled={voice.used && !isChanged}
          >
            {buttonLabel}
          </Button>
        </section>
        <section className="voice-profile-btn">
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
              toggleItem(voice.id);
            }}
            color="transparent"
            className="btn-voice-profile"
          >
            {isOpen ? <BottomArrow /> : <RightArrow />}
          </IconButton>
        </section>
      </section>
      {isOpen && (
        <>
          <section className="intro-voice">
            {voice.description?.ko && (
              <p className="ko">{voice.description?.ko}</p>
            )}
            {voice.description?.en && (
              <p className="en">{voice.description?.en}</p>
            )}
          </section>
          <section className="item-detail">
            <section className="slider-control">
              <section className="slider-control-header">
                <span className="slider-title">{t('Age')}</span>
                <span className="slider-value">{age.toFixed(0)}</span>
              </section>
              <Slider
                min={7}
                max={70}
                value={age}
                step={1}
                defaultValue={voice.age}
                onEnd={() => handleParameterChange()}
                onChange={(value) => setAge(value)}
              />
            </section>
            <section className="slider-control">
              <section className="slider-control-header">
                <span className="slider-title">{t('Gender')}</span>
                <span className="slider-value">{gender.toFixed(0)}</span>
              </section>
              <Slider
                min={-15}
                max={15}
                minLabel="Female"
                maxLabel="Male"
                value={gender}
                step={1}
                defaultValue={voice.gender}
                onEnd={() => handleParameterChange()}
                onChange={(value) => setGender(value)}
              />
            </section>
          </section>
        </>
      )}
    </li>
  );
};
export default VoiceLibraryItem;
