import ScreenPlayHeader from '../../layout/ScreenPlayHeader';
import SourceResultContextProvider from './SourceResultProvider';
import StyledZeroShotPage from './StyledZeroShotPage';
import ZeroShotContextProvider from './ZeroShotContextProvider';
import ZeroShotResultPanel from './ZeroShotResultPanel/ZeroShotResultPanel';
import ZeroShotSourcePanel from './ZeroShotSourcePanel/ZeroShotSourcePanel';
import ZeroShotTTSPanel from './ZeroShotTTSPanel';

// [workaround]
// 현재 파일 분리의 필요성이 없어 보여서, 페이지 내에 정의
const ZeroShotContent = () => {
  return (
    <ZeroShotContextProvider>
      <SourceResultContextProvider>
        <section className="admin-grid zero-shot">
          <ZeroShotSourcePanel />
          <ZeroShotTTSPanel />
          <ZeroShotResultPanel />
        </section>
      </SourceResultContextProvider>
    </ZeroShotContextProvider>
  );
};
const ZeroShotPage = () => {
  return (
    <StyledZeroShotPage>
      <ScreenPlayHeader />
      <ZeroShotContent />
    </StyledZeroShotPage>
  );
};

export default ZeroShotPage;
