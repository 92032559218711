import { Black, Grey, Primary, Secondary } from '@/styles/Colors';
import styled from '@emotion/styled';

import { FontSize, FontWeight } from '../../../styles/Typography';
import {
  COLUMN_HEIGHT,
  LEFT_OFFSET,
  X_AXIS_HEIGHT,
} from '../TimelinePanel/Timeline/const';

const StyledTimelinePanel = styled.section`
  background-color: ${Black};
  width: 100%;
  /* height: 24%; */
  /* height: 16.25rem; */
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  position: relative;
  .drag-handle {
    position: absolute;
    top: -2px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: transparent;
    cursor: row-resize;
    z-index: 1;
    &:hover,
    &.dragging {
      background-color: ${Grey[300]};
    }
  }
  .sup-timeline {
    width: 100%;
    height: 100%;
    position: relative;
    .timeline-axis-x {
      box-sizing: border-box;
      border-bottom: 1px solid ${Grey[400]};
      text {
        font-size: ${FontSize['Md']};
        font-weight: ${FontWeight['SemiBold']};
        user-select: none;
      }
    }
    .timeline-body {
      position: relative;
      width: 100%;
      /* height: calc(16.25rem - 3rem); */
      overflow-x: hidden;
      overflow-y: scroll;
      display: flex;
      &::-webkit-scrollbar {
        border-right: 0.75rem;
        width: 1.4rem;
        background-color: transparent;
      }
      &::-webkit-scrollbar-track {
        background-color: transparent;
      }
      &::-webkit-scrollbar-thumb {
        width: 0.4rem;
        background-clip: padding-box;
        border: 0.5rem solid rgba(0, 0, 0, 0);
        border-radius: 4.5rem;
        background-color: rgba(217, 217, 217, 0.2);
        box-shadow: inset 0 0 0 0.75px ${Grey[400]};
      }
    }
    .timeline-loading {
      width: 100%;
      /* height: calc(16.25rem - 3rem); */
      background: ${Grey[700]};
      position: absolute;
      top: 3rem;
      left: 0;
      z-index: 1;
      section {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
    }
    .timeline-axis-y {
      width: ${LEFT_OFFSET - 1}px;
      border-right: 1px solid ${Grey[700]};
      z-index: 1;
      .axis-y-item {
        position: relative;
        height: ${COLUMN_HEIGHT}px;
        background-color: ${Grey[800]};
        display: flex;
        align-items: center;
        justify-content: center;
        user-select: none;
        /* 짝수번째 하위 rect에 background 적용 */
        &:nth-of-type(even) {
          background-color: ${Black};
        }
        img {
          display: block;
          border-radius: 50%;
          width: 2rem;
          height: 2rem;
        }
        .checkbox {
          display: flex;
          align-items: center;
          justify-content: center;
          width: 2rem;
          height: 2rem;
          &:hover {
            opacity: 1;
            svg {
              fill: ${Primary[200]};
            }
          }
          input {
            display: none;
          }
          label {
            display: flex;
            align-items: center;
            justify-content: center;
            width: 100%;
            height: 100%;
            cursor: pointer;
            svg {
              fill: ${Primary[400]};
            }
            &.disabled {
              svg {
                fill: ${Grey[500]};
              }
            }
          }
        }
      }
    }
    .timeline-block-container {
      width: calc(100% - ${LEFT_OFFSET}px);
      position: relative;
      .grids {
        width: 100%;
        position: relative;
        .grid {
          width: 100%;
          height: ${COLUMN_HEIGHT}px;
          background-color: ${Grey[800]};
          /* 짝수번째 하위 rect에 background 적용 */
          &:nth-of-type(even) {
            background-color: ${Black};
          }
        }
      }
      .block {
        position: absolute;
        top: 0;
        left: 0;
        box-sizing: border-box;
        padding: calc(0.64rem - 1px) 0.75rem;
        border-radius: 0.25rem;
        border: 1px solid ${Grey[400]};
        background-color: ${Grey[700]};
        cursor: grab;
        &.dragging {
          opacity: 0.5;
          cursor: grabbing;
        }
        &.not-draggable {
          cursor: not-allowed;
        }
        &.hide {
          display: none;
        }
        &.active {
          border-color: ${Grey[50]};
          background-color: ${Primary[300]};
          .audio-text {
            font-weight: ${FontWeight['SemiBold']};
          }
        }
        &.audio {
          border: none;
          background-color: ${Primary[700]};
          &.active {
            background-color: ${Primary[500]};
          }
          &.disabled {
            background-color: ${Primary[800]};
            .block-text {
              color: ${Grey[500]};
              svg {
                fill: ${Grey[500]};
              }
            }
          }
          .block-text {
            display: flex;
            align-items: center;
            justify-content: flex-start;
            user-select: none;
            svg {
              fill: ${Grey[50]};
              margin-right: 0.5rem;
              width: 1.25rem;
              height: 1.25rem;
            }
          }
        }
      }
      .block-text {
        font-size: ${FontSize['Sm']};
        font-weight: ${FontWeight['Regular']};
        display: block;
        width: 100%;
        line-height: 1.5;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        user-select: none;
      }
    }
    .timeline-indicator {
      position: absolute;
      top: ${X_AXIS_HEIGHT}px;
      left: 0;
      z-index: 1;
      cursor: pointer;
      svg {
        position: absolute;
        top: -6px;
        left: -5.5px;
      }
      .line {
        position: absolute;
        top: 0;
        left: -2.5px;
        width: 1px;
        /* height: calc(16.25rem - 3rem); */
        background-color: ${Secondary[200]};
      }
    }
    .timeline-scroll {
      margin-top: -0.8rem;
      margin-left: ${LEFT_OFFSET}px;
      .sup-scrollbar-thumb {
        background-color: rgba(217, 217, 217, 0.2);
        box-shadow: inset 0 0 0 0.75px ${Grey[400]};
        cursor: pointer;
      }
    }
  }
`;
export default StyledTimelinePanel;
