import BgmBright from '../../assets/audios/bgm/bright.mp3';
import BgmBrighter from '../../assets/audios/bgm/brighter.mp3';
import BgmDark from '../../assets/audios/bgm/dark.mp3';
import BgmMagnificent from '../../assets/audios/bgm/magnificent.mp3';
import { TimelineListItem } from '../timeline';
export const defaultBgmList: TimelineListItem[] = [
  {
    voice: {
      id: 'demo-bgm-3',
      name: 'Bright',
      type: 'audio',
      disabled: false,
    },
    items: [
      {
        id: 'demo-bgm-3-item-1',
        type: 'audio',
        content: 'Sunshine Serenade',
        position: 0,
        duration: 0,
      },
    ],
  },
  {
    voice: {
      id: 'demo-bgm-1',
      name: 'Magnificent',
      type: 'audio',
      disabled: true,
    },
    items: [
      {
        id: 'demo-bgm-1-item-1',
        type: 'audio',
        content: 'The Dreamscape',
        position: 0,
        duration: 0,
      },
    ],
  },
  {
    voice: {
      id: 'demo-bgm-2',
      name: 'Dark',
      type: 'audio',
      disabled: true,
    },
    items: [
      {
        id: 'demo-bgm-2-item-1',
        type: 'audio',
        content: 'The Road of Victory',
        position: 0,
        duration: 0,
      },
    ],
  },
  {
    voice: {
      id: 'demo-bgm-4',
      name: 'Brighter',
      type: 'audio',
      disabled: true,
    },
    items: [
      {
        id: 'demo-bgm-4-item-1',
        type: 'audio',
        content: 'Magical Merry Melody',
        position: 0,
        duration: 0,
      },
    ],
  },
];

export const defaultAudioFileList: {
  id: string;
  src: string;
  fileName?: string;
}[] = [
  {
    id: 'demo-bgm-1-item-1',
    src: BgmMagnificent,
  },
  {
    id: 'demo-bgm-2-item-1',
    src: BgmDark,
  },
  {
    id: 'demo-bgm-3-item-1',
    src: BgmBright,
  },
  {
    id: 'demo-bgm-4-item-1',
    src: BgmBrighter,
  },
];
