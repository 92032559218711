import Checkbox from '@/components/Checkbox/Checkbox';
import { Grey } from '@/styles/Colors';
import classNames from 'classnames';
import React, {
  MouseEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import useAudioController from '../../../hooks/useAudioController';
import { ReactComponent as DirectWithAudioIcon } from '../assets/icons/DirectWithAudioIcon.svg';
import { ReactComponent as FillStopIcon } from '../assets/icons/FillStopIcon.svg';
import { ReactComponent as LineAddIcon } from '../assets/icons/LineAddIcon.svg';
import { ReactComponent as LineDropDownIcon } from '../assets/icons/LineDropDownIcon.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import { ReactComponent as LineToggleIconOver } from '../assets/icons/LineToggleIcon_over.svg';
import { ReactComponent as LineToggleIcon } from '../assets/icons/LineToggleIcon.svg';
import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import {
  audioFileMapModel,
  audioPlayerStateModel,
  isPlayingSelector,
} from '../stores/audios';
import {
  Line,
  checkedLineSelectorById,
  checkedTakeModel,
  editNameProjectIdModel,
  editingLineIdModel,
  editingSceneIdModel,
  lineListModel,
  selectedLineIdModel,
  selectedLineIdSelector,
  selectedTakeIdModel,
  takeListSelector,
  voiceSelector,
} from '../stores/project';
import StyledVoiceProfile from '../styles/StyledVoiceProfile';
import CvcDropDownLayer from './CvcDropDownLayer';
import LanguageDropDownLayer, { LanguageMap } from './LanguageDropDownLayer';
import SceneWriterTakeItem from './SceneWriterTakeItem';
import VoiceListDropDownLayer from './VoiceListDropDownLayer';

interface SceneWriterLineProps {
  line: Line;
  num: number;
  onUpdateLine: (line: Line, newText: string, addNew?: boolean) => void;
}
const SceneWriterLineItem: React.FC<SceneWriterLineProps> = ({
  line,
  num,
  onUpdateLine,
}) => {
  const { t } = useTranslation('screenPlay');
  const [selectedLineId, setSelectedLineId] =
    useRecoilState(selectedLineIdModel);
  const setSelectedTakeId = useSetRecoilState(selectedTakeIdModel);
  const activeLineId = useRecoilValue(selectedLineIdSelector);
  const resetSelectedTake = useResetRecoilState(selectedTakeIdModel);
  const selectedTakeId = useRecoilValue(selectedTakeIdModel);
  const isSelectedLine = useMemo(
    () => !selectedTakeId && selectedLineId === line.id,
    [selectedTakeId, selectedLineId, line.id]
  );
  const takes = useRecoilValue(takeListSelector(line.id));
  const { thumbnail, name } = useRecoilValue(voiceSelector(line.voiceId));
  const onClickLine = useCallback(() => {
    setSelectedLineId(line.id);
    resetSelectedTake();
  }, [line.id, setSelectedLineId, resetSelectedTake]);
  const ref = useRef<HTMLElement>(null);
  const [lineList, setLineList] = useRecoilState(lineListModel);
  const hasNextLine = useMemo(
    () => lineList.findIndex((l) => l.id === line.id) !== lineList.length - 1,
    [lineList, line.id]
  );
  const { addEmptyLine } = useSessionStorage();
  const addNewLine = useCallback(
    (e?: React.MouseEvent) => {
      e?.stopPropagation();
      const newLine = addEmptyLine(line);
      newLine.id && setSelectedLineId(newLine.id);
    },
    [addEmptyLine, setSelectedLineId, line]
  );
  const onPaste = useCallback((e: React.ClipboardEvent) => {
    e.preventDefault();
    const newText = e.clipboardData.getData('text/plain');
    window.document.execCommand('insertText', false, newText);
  }, []);
  const onKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (!e.shiftKey && e.code === 'ArrowUp') {
        const tempRange = document.createRange();
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        if (range) {
          tempRange.selectNodeContents(e.target as Node);
          tempRange.setEnd(range.startContainer, range.startOffset);
          if (!tempRange.toString()) {
            e.preventDefault();
            const prevId =
              lineList[lineList.findIndex((l) => l.id === line.id) - 1]?.id;
            prevId && setSelectedLineId(prevId);
          }
        }
      }
      if (!e.shiftKey && e.code === 'ArrowDown') {
        const tempRange = document.createRange();
        const selection = window.getSelection();
        const range = selection?.getRangeAt(0);
        if (range) {
          tempRange.selectNodeContents(e.target as Node);
          tempRange.setStart(range.startContainer, range.startOffset);
          if (!tempRange.toString()) {
            e.preventDefault();
            const nextId =
              lineList[lineList.findIndex((l) => l.id === line.id) + 1]?.id;
            nextId && setSelectedLineId(nextId);
          }
        }
      }
      if (e.code === 'Escape') {
        if (ref.current) {
          ref.current.innerHTML = line.text;
          ref.current.blur();
        }
      }
      if (e.code === 'Enter') {
        e.stopPropagation();
        if (e.nativeEvent.isComposing === false) {
          e.preventDefault();
          const newText = ref.current?.innerText.trim() as string;
          if (newText && newText !== line.text) {
            hasNextLine
              ? ref.current?.blur()
              : onUpdateLine(line, newText, !hasNextLine);
          } else {
            if (newText && !hasNextLine) {
              addNewLine();
            }
          }
        }
      } else if (e.code === 'Delete') {
        if (!ref.current?.innerText) {
          e.preventDefault();
          const nextId =
            lineList[lineList.findIndex((l) => l.id === line.id) + 1]?.id;
          setLineList((prev) => {
            if (!nextId) {
              return prev;
            }
            const newLineList = prev.filter((l) => l.id !== line.id);
            return newLineList;
          });
          nextId && setSelectedLineId(nextId);
        }
      }
    },
    [
      line,
      onUpdateLine,
      lineList,
      setSelectedLineId,
      setLineList,
      hasNextLine,
      addNewLine,
    ]
  );
  const setEditingLineId = useSetRecoilState(editingLineIdModel);
  const onChangeData = useCallback(() => {
    const newText = ref.current?.innerText.trim() ?? line.text;
    setEditingLineId(null);
    if (!newText && ref.current) {
      return (ref.current.innerText = line.text);
    }
    if (newText && newText !== line.text) {
      onUpdateLine(line, newText, false);
    }
  }, [line, ref, setEditingLineId, onUpdateLine]);
  const onFocus = useCallback(() => {
    setEditingLineId(line.id);
  }, [line.id, setEditingLineId]);

  const editingSceneId = useRecoilValue(editingSceneIdModel);
  const editingProjectId = useRecoilValue(editNameProjectIdModel);
  useEffect(() => {
    if (isSelectedLine && !editingSceneId && !editingProjectId) {
      setTimeout(() => ref.current?.focus(), 200);
    }
  }, [isSelectedLine, editingSceneId, editingProjectId, setEditingLineId]);
  const toggleMultiTakes = useCallback(
    (id: string) => {
      if (activeLineId === id) {
        setSelectedLineId(null);
        setSelectedTakeId(null);
      } else {
        setSelectedLineId(id);
      }
    },
    [activeLineId, setSelectedLineId, setSelectedTakeId]
  );

  const [isOpenVoiceListLayer, setIsOpenVoiceListLayer] = useState(false);
  const onClickVoice = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsOpenVoiceListLayer(!isOpenVoiceListLayer);
    },
    [setIsOpenVoiceListLayer, isOpenVoiceListLayer]
  );
  const [isOpenCvcLayer, setIsOpenCvcLayer] = useState(false);
  const onClickCvc = useCallback(() => {
    setIsOpenCvcLayer(!isOpenCvcLayer);
  }, [setIsOpenCvcLayer, isOpenCvcLayer]);
  const [isOpenLangLayer, setIsOpenLangLayer] = useState(false);
  const onClickLang = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setIsOpenLangLayer(!isOpenLangLayer);
    },
    [setIsOpenLangLayer, isOpenLangLayer]
  );

  const isEmpty = useMemo(() => !line.text, [line.text]);

  const cvcRef = useRef<HTMLButtonElement>(null);
  const voiceRef = useRef<HTMLButtonElement>(null);
  const langRef = useRef<HTMLButtonElement>(null);

  const isPlayingLine = useRecoilValue(isPlayingSelector(line.id));

  const changeLineSetting = useCallback(
    (updatedLine: Line) => {
      setLineList((prev) => {
        const newLineList = prev.map((l) => {
          if (l.id === line.id) {
            return {
              ...l,
              ...updatedLine,
            };
          }
          return l;
        });
        return newLineList;
      });
      !!line.text.trim() &&
        onUpdateLine({ ...line, ...updatedLine }, line.text, false);
    },
    [setLineList, line, onUpdateLine]
  );

  const checked = useRecoilValue(checkedLineSelectorById(line.id));
  const setCheckedTake = useSetRecoilState(checkedTakeModel);
  const onChange = useCallback(
    (val: boolean) => {
      setCheckedTake((prev) => {
        const newMap = { ...prev };
        takes.forEach((take) => {
          if (take.lineId === line.id) {
            newMap[take.id] = val;
          }
        });
        return newMap;
      });
    },
    [line.id, takes, setCheckedTake]
  );

  const audioFileMap = useRecoilValue(audioFileMapModel);
  const [audioPlayerState, setAudioPlayerState] = useRecoilState(
    audioPlayerStateModel
  );
  const { audioBuffer, loadAudio, playAudio, stopAudio, isPlaying } =
    useAudioController();

  const handlePlayStop = useCallback(
    (e: MouseEvent) => {
      e.stopPropagation();
      if (isPlaying) {
        stopAudio();
      } else {
        playAudio();
      }
      setAudioPlayerState({
        type: 'standalone',
        takeId: line.selectedTakeId,
        isPlaying: !isPlaying,
      });
      return () => {
        stopAudio();
      };
    },
    [isPlaying, stopAudio, playAudio, setAudioPlayerState, line.selectedTakeId]
  );

  useEffect(() => {
    if (!line.selectedTakeId) return;
    if (audioFileMap[line.selectedTakeId]?.audioBuffer) {
      loadAudio(audioFileMap[line.selectedTakeId].audioBuffer);
    }
  }, [audioFileMap, line.selectedTakeId, loadAudio, audioBuffer]);

  useEffect(() => {
    if (!audioPlayerState) {
      stopAudio();
      return;
    }
    if (
      audioPlayerState.type === 'standalone' &&
      audioPlayerState.takeId === line.selectedTakeId &&
      isPlaying
    )
      return;
    if (
      (audioPlayerState.type === 'timeline' && audioPlayerState.isPlaying) ||
      (audioPlayerState.type === 'standalone' &&
        audioPlayerState.takeId !== line.selectedTakeId &&
        audioPlayerState.isPlaying)
    ) {
      stopAudio();
    }
  }, [audioPlayerState, stopAudio, isPlaying, line.selectedTakeId]);

  const isMultiTakesOpen = useMemo(
    () => activeLineId === line.id,
    [activeLineId, line.id]
  );

  return (
    <li
      className={classNames(
        'scene-line-item',
        takes.length === 1 && 'single-take',
        isPlayingLine && 'playing'
      )}
      key={line.id}
      onClick={onClickLine}
    >
      <section
        className={classNames('line-item line', isSelectedLine && 'selected')}
      >
        <span className="line-content">
          <span className="line-left-content">
            <span onClick={(e) => e.stopPropagation()}>
              <Checkbox
                onChange={onChange}
                color={Grey[400]}
                checked={checked}
              />
            </span>
            <span className={classNames('line-number')}>{num}</span>
            <span className="line-voice" ref={voiceRef}>
              <StyledVoiceProfile
                className="voice-profile"
                onClick={onClickVoice}
              >
                {thumbnail ? (
                  <span
                    className="voice-image"
                    style={{ backgroundImage: `url(${thumbnail})` }}
                  />
                ) : (
                  <span className="voice-image empty initial-name">
                    <em>{name.charAt(0)}</em>
                  </span>
                )}
              </StyledVoiceProfile>
              <button
                className="button-reset btn-dropdown"
                onClick={onClickVoice}
              >
                <LineDropDownIcon />
              </button>
              <VoiceListDropDownLayer
                onChange={changeLineSetting}
                voiceId={line.voiceId}
                preventRef={voiceRef}
                isOpenLayer={isOpenVoiceListLayer}
                onClose={() => setIsOpenVoiceListLayer(false)}
              />
            </span>
            <span className="line-lang" ref={langRef}>
              <span onClick={onClickLang}>
                {LanguageMap[line.language]?.label || 'ENG'}
              </span>
              <button
                className="button-reset btn-dropdown btn-lang"
                onClick={onClickLang}
              >
                <LineDropDownIcon />
              </button>
              <LanguageDropDownLayer
                onChange={changeLineSetting}
                preventRef={langRef}
                isOpenLayer={isOpenLangLayer}
                onClose={() => setIsOpenLangLayer(false)}
                language={line.language}
              />
            </span>
          </span>
          <section className="line-text">
            <section
              className={classNames(
                'content-editor',
                !isSelectedLine && isEmpty && 'empty'
              )}
              onFocus={onFocus}
              onBlur={onChangeData}
              ref={ref}
              contentEditable={isSelectedLine}
              data-placeholder={t('Write your story here')}
              onPaste={onPaste}
              onKeyDown={onKeyDown}
              dangerouslySetInnerHTML={{
                __html:
                  line.text ||
                  (!isSelectedLine ? t('Write your story here') : ''),
              }}
            />
          </section>
        </span>
        <span className="line-right-content">
          <span
            className={classNames(
              'line-control line',
              isMultiTakesOpen && 'play-hidden'
            )}
          >
            <button
              disabled={isEmpty || !audioBuffer}
              className={classNames(
                'button-reset btn-line-control-icon btn-play',
                {
                  'btn-line-play': !isPlaying,
                  'btn-line-stop': isPlaying,
                  disabled: !line.isGenerated,
                }
              )}
              onClick={handlePlayStop}
            >
              {isPlaying ? <FillStopIcon /> : <LinePlayIcon />}
            </button>
            <button
              disabled={isEmpty}
              onClick={onClickCvc}
              className={classNames(
                'btn-line-control-icon btn-direct-with-audio',
                !line.isGenerated && 'disabled'
              )}
              ref={cvcRef}
            >
              <DirectWithAudioIcon />
            </button>
            <CvcDropDownLayer
              line={line}
              preventRef={cvcRef}
              onClose={() => setIsOpenCvcLayer(false)}
              isOpenLayer={isOpenCvcLayer}
            />
          </span>
          {line.isGenerated && (
            <button
              className="button-reset btn-line-dropdown"
              onClick={(e) => {
                e.stopPropagation();
                toggleMultiTakes(line.id);
              }}
            >
              {isMultiTakesOpen ? <LineToggleIconOver /> : <LineToggleIcon />}
            </button>
          )}
        </span>
        <section className="line-add">
          <button className="add" onClick={addNewLine}>
            <LineAddIcon />
          </button>
        </section>
      </section>
      {(line.isGenerated || line.isRegenerating) && isMultiTakesOpen && (
        <ul className="take-list">
          {takes.map((take, index) => (
            <SceneWriterTakeItem
              isRegenerating={line.isRegenerating}
              selected={line.selectedTakeId === take.id}
              key={take.id}
              take={take}
              takeNum={index + 1}
              lineNum={num}
            />
          ))}
        </ul>
      )}
    </li>
  );
};
export default SceneWriterLineItem;
