import screenPlay from '@/pages/screenplay/assets/locale/en.json';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';

import voiceToolkit from './en.json';

// Default language is English, Another language is not supported yet.
const DEFAULT_LANGUAGE = 'en';
i18n.use(initReactI18next).init({
  resources: {
    en: {
      voiceToolkit,
      screenPlay,
    },
  },
  lng: DEFAULT_LANGUAGE,
  fallbackLng: DEFAULT_LANGUAGE,
  interpolation: {
    escapeValue: false,
  },
  defaultNS: 'voiceToolkit',
});
