import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as SettingIcon } from '../assets/images/SettingIcon.svg';
import VoiceDefaultImage from '../assets/images/VoiceDefaultImage.svg';
import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import useSingleAudioController from '../hooks/useSingleAudioController';
import { audioPlayerStateModel, voiceFileMapModel } from '../stores/audios';
import {
  Voice,
  editingVoiceIdModel,
  usedVoiceListSelector,
} from '../stores/project';
import StyledVoiceProfile from '../styles/StyledVoiceProfile';
import LoadingDots from '../VoiceLibrary/Loading';
import VoiceLibrary from '../VoiceLibrary/VoiceLibrary';

const VoicesTab = () => {
  const { t } = useTranslation('screenPlay');

  const [isOpenVoiceLibrary, setIsOpenVoiceLibrary] = useState(false);
  const createNewVoice = useCallback(() => {
    setIsOpenVoiceLibrary(true);
  }, [setIsOpenVoiceLibrary]);
  const setEditingVoice = useSetRecoilState(editingVoiceIdModel);
  const openVoiceLibrary = useCallback(
    (voiceId: string) => {
      setEditingVoice(voiceId);
      setIsOpenVoiceLibrary(true);
    },
    [setIsOpenVoiceLibrary, setEditingVoice]
  );
  const closeVoiceLibrary = useCallback(() => {
    setIsOpenVoiceLibrary(false);
  }, [setIsOpenVoiceLibrary]);
  const voiceList = useRecoilValue(usedVoiceListSelector);

  const voiceFileMap = useRecoilValue(voiceFileMapModel);
  const { loadVoiceFile } = useSessionStorage();
  const { play, stop, isPlaying } = useSingleAudioController();
  const [playingVoiceId, setPlayingVoiceId] = useState<string | null>(null);

  const [audioPlayerState, setAudioPlayerState] = useRecoilState(
    audioPlayerStateModel
  );

  const getStorageId = useCallback((voice: Voice) => {
    return `V-${voice.id}_A-${voice.age}_G-${voice.gender}`;
  }, []);

  const [isLoading, setIsLoading] = useState(false);

  const playAudio = useCallback(
    async (voice: Voice) => {
      const storageId = getStorageId(voice);
      if (isPlaying && playingVoiceId === voice.id) {
        stop();
        setPlayingVoiceId(null);
        return;
      }
      setPlayingVoiceId(voice.id);
      if (voiceFileMap[storageId]) {
        play(0, voiceFileMap[storageId].audioBuffer);
      } else {
        setIsLoading(true);
        const { audioBuffer } = await loadVoiceFile(voice, {
          age: voice.age,
          gender: voice.gender,
        });
        setIsLoading(false);
        play(0, audioBuffer);
      }
      setAudioPlayerState({
        type: 'standalone',
        voiceId: voice.id,
        isPlaying: true,
      });
    },
    [
      voiceFileMap,
      loadVoiceFile,
      play,
      stop,
      isPlaying,
      playingVoiceId,
      getStorageId,
      setAudioPlayerState,
    ]
  );

  useEffect(() => {
    if (!audioPlayerState) return;
    if (
      (audioPlayerState.type === 'timeline' && audioPlayerState.isPlaying) ||
      (audioPlayerState.type === 'standalone' &&
        audioPlayerState.takeId &&
        audioPlayerState.isPlaying)
    ) {
      stop();
    }
  }, [playingVoiceId, audioPlayerState, stop, isPlaying, setAudioPlayerState]);

  useEffect(() => {
    if (!isOpenVoiceLibrary) return;
    isPlaying && stop();
    setPlayingVoiceId(null);
  }, [isOpenVoiceLibrary, isPlaying, stop]);

  return (
    <section className="voice-tab">
      <section className="create-new add-voice">
        <Button
          size="lg"
          color="transparent"
          className="btn-create-new-item"
          onClick={createNewVoice}
        >
          {t('Add New Voice')}
        </Button>
      </section>
      <section className="voice-list">
        <ul>
          {voiceList?.map((voice) => (
            <li key={voice.id}>
              <section className="list-item voice">
                <StyledVoiceProfile className="voice-profile">
                  <span
                    className={classNames(
                      'voice-image',
                      !voice.thumbnail && 'empty'
                    )}
                  >
                    <button
                      className={classNames('btn-voice', {
                        // TODO: 추후 playing 상태에 따라 클래스명 변경
                        playing: isPlaying && playingVoiceId === voice.id,
                      })}
                      onClick={() => playAudio(voice)}
                      disabled={isLoading && playingVoiceId !== voice.id}
                    >
                      {voice.thumbnail ? (
                        <img
                          className={classNames({ empty: !voice.thumbnail })}
                          src={
                            voice.thumbnail
                              ? voice.thumbnail
                              : VoiceDefaultImage
                          }
                          alt={voice.name}
                        />
                      ) : (
                        <em>{voice.name.charAt(0)}</em>
                      )}
                      {isLoading && playingVoiceId === voice.id && (
                        <LoadingDots />
                      )}
                    </button>
                  </span>
                  <span className="voice-name">{voice.name}</span>
                </StyledVoiceProfile>
                <span className="list-buttons">
                  <IconButton
                    onClick={() => openVoiceLibrary(voice.id)}
                    className="btn-voice-icon btn-voice-setting"
                  >
                    <SettingIcon />
                  </IconButton>
                </span>
              </section>
            </li>
          ))}
        </ul>
        {isOpenVoiceLibrary && <VoiceLibrary onClose={closeVoiceLibrary} />}
      </section>
    </section>
  );
};
export default VoicesTab;
