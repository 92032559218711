import { ReactNode } from 'react';

import { Primary, White } from '../../../styles/Colors';
import Button from '../../Button/Button';
import Modal, { ModalProps } from '../Modal';
import ModalBody from '../ModalBody';
import ModalFooter from '../ModalFooter';
import ModalHeader from '../ModalHeader';

interface ConfirmModalProps
  extends Pick<
    ModalProps,
    'isOpen' | 'isPortal' | 'hideBackdrop' | 'backdropColor' | 'className'
  > {
  onConfirm: () => void;
  onCancel: () => void;
  title?: ReactNode;
  message?: ReactNode;
  confirmText?: string;
  cancelText?: string;
}

const ConfirmModal = ({
  isOpen,
  isPortal,
  title,
  message,
  onConfirm,
  confirmText,
  onCancel,
  cancelText,
  backdropColor,
  hideBackdrop,
  className,
}: ConfirmModalProps) => (
  <Modal
    isOpen={isOpen}
    isPortal={isPortal}
    onClose={onCancel}
    backdropColor={backdropColor}
    hideBackdrop={hideBackdrop}
    hideCloseButton={!title}
    className={className}
  >
    {title && <ModalHeader>{title}</ModalHeader>}
    <ModalBody>{message}</ModalBody>
    <ModalFooter>
      <Button color={White} variant="outlined" onClick={onCancel}>
        {cancelText || 'Cancel'}
      </Button>
      <Button color={Primary[400]} onClick={onConfirm}>
        {confirmText || 'Confirm'}
      </Button>
    </ModalFooter>
  </Modal>
);

export default ConfirmModal;
