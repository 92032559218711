import Button from '@/components/Button/Button';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import {
  editingSceneIdModel,
  lineListModel,
  sceneListModel,
  selectedProjectIdModel,
  selectedSceneIdModel,
  selectedSceneIdSelector,
} from '../stores/project';
import StyledSceneTab from '../styles/StyledSceneTab';
import LineItem from './LineItem';
import SceneName from './SceneName';

const ScenesTab = () => {
  const { t } = useTranslation('screenPlay');
  const setSceneList = useSetRecoilState(sceneListModel);
  const selectedSceneId = useRecoilValue(selectedSceneIdSelector);
  const setSelectedSceneId = useSetRecoilState(selectedSceneIdModel);
  const setEditingSelectedId = useSetRecoilState(editingSceneIdModel);
  const selectedProjectId = useRecoilValue(selectedProjectIdModel);
  const { saveScene, addNewSceneObject, loadScene } = useSessionStorage();
  const addNewScene = useCallback(() => {
    // todo before create new project, check if there is any unsaved changes
    // input add name scene
    // todo add Scene info such as project
    saveScene();
    const newItem = addNewSceneObject(selectedProjectId as string);
    setSceneList((oldSceneList) => {
      const newSceneList = [...oldSceneList];
      newSceneList.push(newItem);
      return newSceneList;
    });
    setSelectedSceneId(newItem.id);
    setEditingSelectedId(newItem.id);
    loadScene(selectedProjectId as string, newItem.id);
  }, [
    loadScene,
    addNewSceneObject,
    saveScene,
    setSceneList,
    setSelectedSceneId,
    setEditingSelectedId,
    selectedProjectId,
  ]);
  const sceneList = useRecoilValue(sceneListModel);
  const lineList = useRecoilValue(lineListModel);

  const onClickScene = useCallback(
    (sceneId: string) => {
      saveScene();
      setSelectedSceneId(sceneId);
      loadScene(selectedProjectId as string, sceneId);
    },
    [setSelectedSceneId, saveScene, selectedProjectId, loadScene]
  );
  return (
    <StyledSceneTab className="scenes-tab">
      <section className="create-new">
        <Button
          size="lg"
          color="transparent"
          className="btn-create-new-item"
          onClick={addNewScene}
        >
          {t('Add New Scene')}
        </Button>
      </section>
      <section className="scene-list">
        <ul className="scenes">
          {sceneList?.map((scene) => (
            <li key={scene.id} onClick={() => onClickScene(scene.id)}>
              <section
                className={classNames(
                  'list-item scene',
                  selectedSceneId === scene.id && 'active'
                )}
              >
                <SceneName scene={scene} />
              </section>
            </li>
          ))}
        </ul>
        <section className="list-item-detail scene">
          {lineList.map((line, index) => {
            return <LineItem line={line} key={line.id} num={index + 1} />;
          })}
        </section>
      </section>
    </StyledSceneTab>
  );
};
export default ScenesTab;
