import { Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

export const StyledTabArea = styled.section`
  border-bottom: 1px solid ${Grey[500]};
  ul {
    display: grid;
    grid-template-columns: 33.3% 33.3% 33.3%;
    li {
      text-align: center;
      height: 3.25rem;
      display: inline-flex;
      text-align: center;
      width: 100%;
      flex-direction: column;
      justify-content: center;
      cursor: pointer;
      font-weight: ${FontWeight['SemiBold']};
      font-size: 1.125rem;
    }
    li.on {
      cursor: default;
      background-color: ${Grey[500]};
      border-bottom: 2px solid ${Grey[50]};
    }
  }
`;
