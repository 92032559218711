import { WebSocketContext } from '@/providers/WebSocketProvider';
import { useCallback, useContext } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { cvc, tts } from '../api';
import { SPEECH_CONTROL_LIST } from '../ControlPanel/SpeechTab';
import { audioFileMapModel } from '../stores/audios';
import {
  Line,
  Voice,
  lineListModel,
  selectedLineIdModel,
  takeListModel,
  usedVoiceListSelector,
} from '../stores/project';
import useSessionStorage from './useSessionStorage/useSessionStorage';

const useUpdateLine = () => {
  const [lineList, setLineList] = useRecoilState(lineListModel);
  const setSelectedLineId = useSetRecoilState(selectedLineIdModel);
  const { addEmptyLine } = useSessionStorage();
  const takeList = useRecoilValue(takeListModel);
  const usedVoiceList = useRecoilValue(usedVoiceListSelector);
  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapModel);
  const { sessionId } = useContext(WebSocketContext);
  const onUpdateLine = useCallback(
    (
      line: Line,
      newText: string,
      addNew: boolean = true,
      changedVoice?: Voice
    ) => {
      const takes = takeList.filter((take) => take.lineId === line.id);
      const voice =
        changedVoice ||
        usedVoiceList.find((voice) => voice.id === line.voiceId);
      // Line을 신규 생성하는지, 기존 Line을 수정하는지 여부를 확인하는 flag
      let isOverwrite = false;
      // overwrite call
      setLineList((prev) =>
        prev.map((prevLine) => {
          if (prevLine.id === line.id) {
            isOverwrite = !!prevLine.isGenerated;
            return {
              ...prevLine,
              text: newText,
              isGenerated: addNew ? true : prevLine.isGenerated,
              isRegenerating: true,
            };
          }
          return prevLine;
        })
      );
      Promise.all(
        takes.map(async (take) => {
          const parameter = take.parameter || {
            pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
            pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
            speed: SPEECH_CONTROL_LIST[2].defaultValue,
          };
          if (take.type === 'cvc') {
            return await cvc(
              { ...line, text: newText },
              take.file as File | string,
              voice as Voice,
              parameter,
              sessionId
            );
          }
          return await tts(
            { ...line, text: newText },
            voice as Voice,
            parameter
          );
        })
      ).then((res) => {
        setAudioFileMap((prev) => {
          const newMap = { ...prev };
          takes.forEach((take, index) => {
            newMap[take.id] = {
              ...newMap[take.id],
              audioBuffer: res[index].audioBuffer as AudioBuffer,
            };
          });
          return newMap;
        });

        // 현재 라인의 index를 찾아서 이후 라인들의 position을 재설정
        const currentLineIndex = lineList.findIndex(
          (lineItem) => lineItem.id === line.id
        );
        // 현재 선택된 take의 index를 찾아서 duration 계산
        const selectedTakeIndex =
          takes.findIndex((take) => take.id === line.selectedTakeId) || 0;
        // 새로 추가될 라인의 position을 설정하기 위한 offset
        const OFFSET = 0.01;
        setLineList((prev) =>
          prev.map((prevLine, idx) => {
            // 이전 라인의 position + duration을 현재 라인의 position으로 설정
            if (prevLine.id === line.id) {
              const beforeLine = idx > 0 ? prev[idx - 1] : null;
              const beforeAudioFile = beforeLine?.selectedTakeId
                ? audioFileMap[beforeLine.selectedTakeId]
                : null;
              const newStart =
                beforeLine && beforeAudioFile
                  ? (beforeLine?.position || 0) +
                    beforeAudioFile.audioBuffer.duration +
                    OFFSET
                  : 0;
              return {
                ...prevLine,
                text: newText,
                isGenerated: true,
                isRegenerating: false,
                position: isOverwrite ? prevLine.position : newStart,
              };
            }
            // 만약 중간에 위치한 라인이라면 이후 라인들의 position을 재설정
            else if (idx > currentLineIndex) {
              // const isOverwrite = prev[currentLineIndex].isGenerated;
              const duration = res[selectedTakeIndex]?.audioBuffer?.duration;
              return {
                ...prevLine,
                position:
                  // 생성된 라인에만 position을 재설정
                  prevLine.isGenerated && duration && !isOverwrite
                    ? (prevLine.position || 0) + duration + OFFSET
                    : prevLine.position,
              };
            }
            return prevLine;
          })
        );
        if (addNew) {
          let newLine = addEmptyLine(line);
          setSelectedLineId(newLine.id);
        }
      });
    },
    [
      setSelectedLineId,
      setLineList,
      addEmptyLine,
      takeList,
      usedVoiceList,
      setAudioFileMap,
      audioFileMap,
      lineList,
      sessionId,
    ]
  );
  return onUpdateLine;
};
export default useUpdateLine;
