export const formatSToMMSSMS = (time: number) => {
  if (typeof time !== 'number') return '';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 1000);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(3, '0');

  return `${formattedM}:${formattedS}:${formattedMS}`;
};

export const formatSToMMSS = (time: number) => {
  if (typeof time !== 'number') return '';
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');

  return `${formattedM}:${formattedS}`;
};

export const formatSToSSMSMS = (time: number) => {
  if (typeof time !== 'number') return '';
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor((time % 1) * 1000);

  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(3, '0');

  return `${formattedS}:${formattedMS}`;
};

// xAxis축의 시간을 표시하기 위해 사용
// MM:SS:MS 형식으로 표시
// TODO: 피드백 이후 형식 변경의 여지가 있음
export const formatXAxisTime = (time: number) => {
  const minutes = Math.floor(time / 60);
  const seconds = Math.floor(time % 60);
  const milliseconds = Math.floor(((time % 1) * 1000) / 100);

  const formattedM = minutes.toString().padStart(2, '0');
  const formattedS = seconds.toString().padStart(2, '0');
  const formattedMS = milliseconds.toString().padStart(1, '0');

  return `${formattedM}:${formattedS}:${formattedMS}`;
};
