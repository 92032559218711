import Profile_Akael_EN from './images/needsgames/Akael_EN.png';
import Profile_Akael from './images/needsgames/Akael.png';
import Profile_Salvis_EN from './images/needsgames/Salvis_EN.png';
import Profile_Salvis from './images/needsgames/Salvis.png';

export const needsgamesAgeGenderMap = {
  UD_Salvis_EN: { age: 39.243, gender: 10.065 },
  UD_Salvis_KR: { age: 49.897, gender: 12.041 },
  UD_Akael_EN: { age: 9.295, gender: 0.441 },
  UD_Akael_KR: { age: 35.04, gender: -5.132 },
};

export const needsgamesVoiceInfo = [
  {
    id: 'UD_Salvis_EN',
    name: 'Salvis EN',
    language: 'en',
    character: 'Male',
    thumbnail: Profile_Salvis_EN,
    description: {
      ko: '살비스 영어 성우',
      en: 'Salvis English Voice Actor',
    },
    script: 'Welcome to Supertone’s Screenplay. I am Salvis.',
  },
  {
    id: 'UD_Salvis_KR',
    name: 'Salvis KR',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_Salvis,
    description: {
      ko: '살비스 한국어 성우',
      en: 'Salvis Korean Voice Actor',
    },
    script: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 살비스입니다.',
  },
  {
    id: 'UD_Akael_EN',
    name: 'Akael EN',
    language: 'en',
    character: 'Female',
    thumbnail: Profile_Akael_EN,
    description: {
      ko: '아케르 영어 성우',
      en: 'Akael English Voice Actor',
    },
    script: 'Welcome to Supertone’s Screenplay. I am Akael.',
  },
  {
    id: 'UD_Akael_KR',
    name: 'Akael KR',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_Akael,
    description: {
      ko: '아케르 한국어 성우',
      en: 'Akael Korean Voice Actor',
    },
    script: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 아케르입니다.',
  },
];
