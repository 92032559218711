import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledScreenPlay = styled.section`
  display: flex;
  height: calc(100vh - 4.5rem);
  flex: auto;
  flex-direction: column;
  .sp-middle-area {
    height: calc(100% - 3.25rem);
    display: flex;
    flex-direction: column;
    .sp-middle-top {
      display: flex;
      flex-direction: row;
      width: 100%;
      overflow: hidden;
      height: 100%;
      &.narrow {
        /* height: calc(100% - 24%); */
        /* height: calc(100% - 16.25rem); */
      }
    }
  }
  .sp-top-panel {
    height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
    .tab-content {
      height: calc(100% - 3.25rem);
      overflow-y: auto;
    }
  }
  .sp-project {
    background-color: ${Grey[600]};
    width: 19.875rem;
    border-right: 1px solid ${Grey[500]};
  }
  .sp-scene-writer {
    flex: 1;
  }
  .sp-media {
    width: 28rem;
    background-color: ${Grey[600]};
  }
  .sp-control {
    border-left: 1px solid ${Grey[500]};
    background-color: ${Grey[600]};
    width: 19.875rem;
  }
`;
export default StyledScreenPlay;
