import { atom, selector } from 'recoil';

import { AudioType, audioFileMapModel } from './audios';
import { defaultBgmList } from './data/bgm';
import {
  Voice,
  lineListWithTakeIdSelector,
  takeListModel,
  voiceListSelector,
} from './project';

export interface TimelineItem {
  id: string;
  type: AudioType;
  lineId?: string;
  content?: string;
  position: number;
  duration?: number;
  takeLabel?: string;
  voice?: Voice;
}

export interface VoiceInfo {
  id: string;
  type: 'voice' | 'audio';
  name: string;
  thumbnail?: string;
  disabled?: boolean;
}

export interface TimelineListItem {
  voice: VoiceInfo;
  items: TimelineItem[];
}

export const bgmListModel = atom<TimelineListItem[]>({
  key: 'bgmListModel',
  default: defaultBgmList,
});

export const timelineListSelector = selector<TimelineListItem[]>({
  key: 'timelineListSelector',
  get: ({ get }) => {
    const lineList = get(lineListWithTakeIdSelector);
    const takeList = get(takeListModel);
    const bgmList = get(bgmListModel);
    const audioMap = get(audioFileMapModel);
    const voiceList = get(voiceListSelector);

    if (!Object.keys(audioMap).length) return [];

    const selectedTakesWithIndex = lineList.reduce<
      { id: string; index: number }[]
    >((acc, line, idx) => {
      if (line.selectedTakeId) {
        acc.push({ id: line.selectedTakeId, index: idx });
      }
      return acc;
    }, []);

    const timelineList = selectedTakesWithIndex.reduce<TimelineListItem[]>(
      (acc, { id, index }) => {
        const take = takeList.find((take) => take.id === id);
        const line = lineList[index];
        const voice = voiceList.find((voice) => voice.id === line.voiceId);
        const audioFile = audioMap[id];

        if (!audioFile || !take) return acc;

        const timelineItem = {
          id: id,
          type: take.type,
          lineId: line.id,
          takeLabel: `${index + 1}-${line.selectedTakeNumber}`,
          content: line.text,
          position: line.position || 0,
          duration: audioFile.audioBuffer?.duration || 0,
        };

        const timeline = acc.find((item) => item.voice.id === voice?.id);
        if (timeline) {
          timeline.items.push(timelineItem);
        } else {
          acc.push({
            voice: {
              id: voice?.id || '',
              name: voice?.name || '',
              type: 'voice',
              thumbnail: voice?.thumbnail,
            },
            items: [timelineItem],
          });
        }
        return acc;
      },
      []
    );
    return [...timelineList, ...bgmList];
  },
});

export const timelinePlaybackModel = atom<number>({
  key: 'timelinePlaybackModel',
  default: 0,
  // atom에 저장된 객체에 대한 불변성을 보장하지 않는다.
  // pointer 이벤트 동작 중 recoil 함수를 호출했음에도 re render가 일어나지 않는 케이스가 있어서 사용
  dangerouslyAllowMutability: true,
});
