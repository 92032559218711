export type Language = 'ko' | 'en' | 'jp';
export const languageList = ['ko', 'en', 'jp'] as Language[];
export const SPEECH_CONTROL_LIST = [
  {
    id: 'pitch_shift',
    title: 'Pitch Shift',
    min: -24,
    max: 24,
    step: 1,
    defaultValue: 0,
  },
  {
    id: 'pitch_variance',
    title: 'Pitch Variance',
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
  {
    id: 'tempo',
    title: 'Speed',
    value: 1,
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
];
