import { atom, selector, selectorFamily } from 'recoil';

import { Language } from '../api';
import { getTakes } from '../api/project';
import { SPEECH_CONTROL_LIST } from './data/config';
import { defaultVoiceList } from './data/voices';

export interface Voice {
  id: string;
  name: string;
  age: number;
  gender: number;
  character?: string;
  language?: string;
  description?: {
    ko: string;
    en: string;
  };
  script?: string;
  thumbnail?: string;
}
export interface Scene {
  id: string;
  name: string;
  createdAt: string;
  updatedAt?: string;
}

// line type for scene writer & AudioPlayer
export interface Line {
  id: string;
  text: string;
  selectedTakeId?: string; // selected take
  voiceId: string;
  language: Language;
  position?: number;
  isGenerated?: boolean;
  // 전체 line 종속 take overwrite fetching 상태 체크
  isRegenerating?: boolean;
}

// Default parameter 0
export interface Parameter {
  pitch_shift: number;
  pitch_variance: number;
  speed: number;
}

export type TakeType = 'tts' | 'cvc'; // cvc is generated by cvc
export interface Take {
  id: string;
  type: TakeType;
  lineId: string;
  isFetching?: boolean;
  file?: File | string; // for cvc
  resource_id?: string; // for resource download
  parameter?: Parameter; // for tts
  takeNumber?: number; // 초기에 부여 후, take삭제 시 업데이트
}
export interface Project {
  id: string;
  name: string;
  description: string;
  createdAt: string;
  updatedAt?: string;
  initialized: boolean;
  teamMember?: string[];
}
export const projectListModel = atom<Project[]>({
  key: 'projectListModel',
  default: [],
});
export const projectModel = atom<Project | null>({
  key: 'projectModel',
  default: null,
});
export const selectedProjectIdModel = atom<string>({
  key: 'selectedProjectIdModel',
  default: '',
});
export const editNameProjectIdModel = atom<string | null>({
  key: 'editNameProjectIdModel',
  default: null,
});
export const sceneListModel = atom<Scene[]>({
  key: 'sceneList',
  default: [],
});
export const editingSceneIdModel = atom<string | null>({
  key: 'editingSceneIdModel',
  default: null,
});
export const selectedSceneIdModel = atom<string | null>({
  key: 'selectedSceneIdModel',
  default: null,
});
export const selectedSceneIdSelector = selector<string | null>({
  key: 'selectedSceneSelector',
  get: ({ get }) => {
    const scenes = get(sceneListModel);
    const selectedSceneId = get(selectedSceneIdModel);
    return (
      scenes.find((scene) => scene.id === selectedSceneId)?.id || scenes[0]?.id
    );
  },
});
export const lineListModel = atom<Line[]>({
  key: 'lineList',
  default: [],
});
export interface LineWithTakeIndex extends Line {
  selectedTakeNumber: number;
}
export const lineListWithTakeIdSelector = selector<LineWithTakeIndex[]>({
  key: 'lineListWithTakeIdSelector',
  get: ({ get }) => {
    const lineList = get(lineListModel);
    const takeList = get(takeListModel);
    return lineList.map((line) => {
      const takes = takeList.filter((take) => take.lineId === line.id);
      return {
        ...line,
        selectedTakeNumber:
          takes.findIndex((take) => take.id === line.selectedTakeId) + 1,
      };
    });
  },
});
export const selectedLineIdModel = atom<string | null>({
  key: 'selectedLineIdModel',
  default: null,
});

export const editingLineIdModel = atom<string | null>({
  key: 'editingLineIdModel',
  default: null,
});
export const takeListModel = atom<Take[]>({
  key: 'takeList',
  default: [],
});
export const selectedTakeIdModel = atom<string | null>({
  key: 'selectedTakeIdModel',
  default: null,
});
export const selectedLineIdSelector = selector<string | null>({
  key: 'selectedLineIdSelector',
  get: ({ get }) => {
    const selectedListLineId = get(selectedLineIdModel);
    const selectedTakeId = get(selectedTakeIdModel);
    const takeList = get(takeListModel);
    if (selectedListLineId) {
      return selectedListLineId;
    }
    return takeList.find((take) => take.id === selectedTakeId)?.lineId || null;
  },
});
export const takeListSelector = selectorFamily<Take[], string>({
  key: 'lineListSelector',
  get:
    (lineId) =>
    ({ get }) => {
      const projectId = get(selectedProjectIdModel);
      const takeList = get(takeListModel);
      const takes =
        takeList.length === 0 ? getTakes(projectId, lineId) : takeList;
      return takes.filter((take) => take.lineId === lineId);
    },
});

export const permittedVoiceListModel = atom<string[]>({
  key: 'permittedVoiceListModel',
  default: [],
});

export const enabledVoiceListSelector = selector<Voice[]>({
  key: 'enabledVoiceListSelector',
  get: ({ get }) => {
    const voices = get(permittedVoiceListModel);
    return defaultVoiceList.filter((voice) => voices.includes(voice.id));
  },
});

export const voiceListModel = atom<Voice[]>({
  key: 'voiceList',
  default: [],
});
export const usedVoiceListSelector = selector<Voice[]>({
  key: 'usedVoiceList',
  get: ({ get }) => {
    const voices = get(voiceListModel);
    const voiceList = get(enabledVoiceListSelector);
    return voices.map((voice) => ({
      ...(voiceList.find((v) => v.id === voice.id) ?? {}),
      ...voice,
    }));
  },
});
export const voiceSelector = selectorFamily<Voice, string>({
  key: 'voiceSelector',
  get:
    (voiceId) =>
    ({ get }) => {
      const voices = get(usedVoiceListSelector);

      return voices.find((voice) => voice.id === voiceId) as Voice;
    },
});
export const editingVoiceIdModel = atom<string | null>({
  key: 'editingVoiceIdModel',
  default: null,
});
export interface ExtendVoice extends Voice {
  isEditing: boolean;
  used: boolean;
}
export const voiceListSelector = selector<ExtendVoice[]>({
  key: 'voiceListSelector',
  get: ({ get }) => {
    const voices = get(usedVoiceListSelector);
    const voiceId = get(editingVoiceIdModel);
    const voiceList = get(enabledVoiceListSelector);
    return voiceList.map((voice) => {
      const item = voices.find((v) => v.id === voice.id);
      const isEditing = voice.id === voiceId;
      return {
        ...(item ?? voice),
        used: !!item,
        isEditing,
      };
    });
  },
});
// take checked
export const checkedTakeModel = atom<{ [key: string]: boolean }>({
  key: 'checkedTakeModel',
  default: {},
});
// line checked
export const checkedLineSelectorById = selectorFamily<boolean, string>({
  key: 'checkedLineSelector',
  get:
    (lineId) =>
    ({ get }) => {
      const checkedTake = get(checkedTakeModel);
      const takeList = get(takeListSelector(lineId));
      return (
        !!takeList.length && takeList.every((take) => checkedTake[take.id])
      );
    },
});
// checked lines
export interface ExtendLine extends Line {
  number: number;
}
export const checkedLineSelector = selector<ExtendLine[]>({
  key: 'checkedLineSelector',
  get: ({ get }) => {
    const checkedTake = get(checkedTakeModel);
    const lineList = get(lineListModel);
    return lineList.reduce((acc, line, index) => {
      const takeList = get(takeListSelector(line.id));
      if (takeList.every((take) => checkedTake[take.id])) {
        acc.push({ ...line, number: index });
      }
      return acc;
    }, [] as ExtendLine[]);
  },
});
// all Line checked
export const checkedAllLineSelector = selector<boolean>({
  key: 'checkedAllLineSelector',
  get: ({ get }) => {
    const lineList = get(lineListModel);
    return lineList.every((line) => get(checkedLineSelectorById(line.id)));
  },
});
export const checkedAnyTakeSelector = selector<boolean>({
  key: 'checkedAnyTakeSelector',
  get: ({ get }) => {
    const checkedTake = get(checkedTakeModel);
    return Object.values(checkedTake).some((checked) => checked);
  },
});
// project loading state
export const projectLoadingModel = atom<boolean>({
  key: 'projectLoadingModel',
  default: false,
});
export const lineParameterMapModel = atom<{ [key: string]: Parameter }>({
  key: 'lineParameterMapModel',
  default: {},
});
export const lineParameterSelector = selectorFamily<Parameter, string | null>({
  key: 'lineParameterSelector',
  get:
    (lineId) =>
    ({ get }) => {
      const lineParameterMap = get(lineParameterMapModel);
      const defaultParameter = {
        pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
        pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
        speed: SPEECH_CONTROL_LIST[2].defaultValue,
      };
      if (lineId) {
        return lineParameterMap[lineId] || defaultParameter;
      }
      return defaultParameter;
    },
});
