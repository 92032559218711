export const resampleAudioData = (
  audioData: Float32Array,
  width: number,
  strokeWidth = 3,
  margin = 1
) => {
  const adjustedWidth = width - 1;
  const samplesPerLine = Math.floor(
    audioData.length / (adjustedWidth / (strokeWidth + margin))
  );

  let resampled: number[] = [];
  for (let i = 0; i < audioData.length; i += samplesPerLine) {
    const chunk = audioData.slice(i, i + samplesPerLine);
    const max = Math.max(...chunk);
    // const min = Math.min(...chunk);
    // resampled = resampled.concat((max - min) / 2);
    resampled = resampled.concat(max);

    if (resampled.length >= adjustedWidth) {
      break;
    }
  }

  return new Float32Array(resampled);
};
