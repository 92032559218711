import { ReactComponent as ArrowUpRightIcon } from '@/assets/icons/ArrowUpRightIcon.svg';
import Button from '@/components/Button/Button';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';

import { Grey } from '../../styles/Colors';
import Landing from '../Landing/Landing';
import StyledLogin from './StyledLogin';

const Login = () => {
  const { loginWithRedirect } = useSUPAuth();
  return (
    <StyledLogin>
      <Landing>
        <div className="btn-group">
          <Button
            size="lg"
            variant="outlined"
            color={Grey['50']}
            className="btn-login"
            endIcon={<ArrowUpRightIcon />}
            onClick={() => loginWithRedirect()}
          >
            Sign In
          </Button>
          <Button
            size="lg"
            className="btn-login"
            endIcon={<ArrowUpRightIcon />}
            onClick={() => loginWithRedirect()}
          >
            Sign up
          </Button>
        </div>
      </Landing>
    </StyledLogin>
  );
};

export default Login;
