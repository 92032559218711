import classNames from 'classnames';
import { useCallback } from 'react';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as NoteIcon } from '../../assets/icons/NoteIcon.svg';
import VoiceDefaultImage from '../../assets/images/VoiceDefaultImage.svg';
import { TimelineListItem, bgmListModel } from '../../stores/timeline';
import { COLUMN_HEIGHT, X_AXIS_HEIGHT } from './const';

interface AudioAxisProps {
  data: TimelineListItem[];
}

const AudioAxis = ({ data }: AudioAxisProps) => {
  const setBgmList = useSetRecoilState(bgmListModel);

  const handleVoiceStateChange = useCallback(
    (id: string) => {
      setBgmList((prev) =>
        prev.map((item) => {
          // TODO: tmp bgm은 1개만 선택 가능하도록 함
          const bgm = { ...item, voice: { ...item.voice, disabled: true } };
          if (item.voice.id === id) {
            bgm.voice.disabled = !item.voice.disabled;
          }
          return bgm;
        })
      );
    },
    [setBgmList]
  );

  return (
    <ul className="timeline-axis-y">
      {data?.length &&
        data.map(({ voice }, index) => {
          return (
            <li
              key={`voice-${index}`}
              className="axis-y-item"
              style={{
                transform: `translate(0, ${
                  index * COLUMN_HEIGHT + X_AXIS_HEIGHT
                })`,
              }}
            >
              {voice.type === 'voice' ? (
                <img
                  src={voice.thumbnail || VoiceDefaultImage}
                  alt={voice.name}
                />
              ) : (
                <div className="checkbox">
                  <input
                    type="checkbox"
                    id={`${voice.id}`}
                    name={`${voice.name}`}
                    checked={!voice.disabled}
                    onChange={() => handleVoiceStateChange(voice.id)}
                  />
                  <label
                    htmlFor={`${voice.id}`}
                    className={classNames({
                      disabled: voice.disabled,
                    })}
                  >
                    <NoteIcon />
                  </label>
                </div>
              )}
            </li>
          );
        })}
    </ul>
  );
};

export default AudioAxis;
