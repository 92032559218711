import { useCallback, useEffect, useRef } from 'react';
import { useRecoilState, useResetRecoilState, useSetRecoilState } from 'recoil';

import {
  Project,
  editNameProjectIdModel,
  projectListModel,
} from '../stores/project';

const ProjectName: React.FC<{ project: Project }> = ({ project }) => {
  const setProjectList = useSetRecoilState(projectListModel);
  const [editNameProjectId, setEditNameProjectId] = useRecoilState(
    editNameProjectIdModel
  );
  const resetEditNameProjectId = useResetRecoilState(editNameProjectIdModel);

  const onDoubleClick = useCallback(() => {
    setEditNameProjectId(project.id);
  }, [setEditNameProjectId, project.id]);

  const changeName = useCallback(
    (value: string) => {
      if (!value.trim()) return;
      setProjectList((oldProjectList) => {
        const newProjectList = [...oldProjectList];
        return newProjectList.map((pj) => {
          if (pj.id === project.id) {
            return {
              ...pj,
              name: value,
              initialized: true,
            };
          }
          return pj;
        });
      });
    },
    [setProjectList, project.id]
  );

  const onBlur = useCallback(() => {
    resetEditNameProjectId();
    changeName(project.name);
  }, [resetEditNameProjectId, project.name, changeName]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        changeName(e.currentTarget.value);
        resetEditNameProjectId();
      }
      if (e.code === 'Escape') {
        resetEditNameProjectId();
      }
    },
    [resetEditNameProjectId, changeName]
  );

  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (editNameProjectId === project.id) {
      ref.current?.focus();
    }
  }, [editNameProjectId, project.id]);

  return editNameProjectId !== project.id ? (
    <strong className="list-item-title" onDoubleClick={onDoubleClick}>
      {project.name}
    </strong>
  ) : (
    <strong className="list-item-title editing">
      <textarea
        ref={ref}
        rows={1}
        className="list-item-title-edit"
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={project.name}
      />
    </strong>
  );
};
export default ProjectName;
