import AlertModal from '@/components/Modal/AlertModal/AlertModal';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import classNames from 'classnames';
import { createContext, useCallback, useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { timelinePanelHeightModel } from '../../stores/panels';
import { getScenes, getVoices } from './api/project';
import ControlPanel from './ControlPanel/ControlPanel';
import GlobalAudioPlayer from './GlobalAudioPlayer/GlobalAudioPlayer';
import useProject from './hooks/useProject';
import useSessionStorage from './hooks/useSessionStorage/useSessionStorage';
import ScreenPlayHeader from './layout/ScreenPlayHeader';
import MediaPanel from './MediaPanel/MediaPanel';
import ProjectPanel from './ProjectsPanel/ProjectsPanel';
import SceneWriterPanel from './SceneWriterPanel/SceneWriterPanel';
import { isShowMediaModel, isShowTimelineModel } from './stores/panels';
import {
  Project,
  enabledVoiceListSelector,
  permittedVoiceListModel,
  projectListModel,
  projectModel,
  sceneListModel,
  selectedProjectIdModel,
  voiceListModel,
} from './stores/project';
import StyledScreenPlay from './styles/StyledScreenPlay';
import TimelinePanel from './TimelinePanel/TimelinePanel';

export const ModalContext = createContext({
  isModalOpen: false,
  setIsModalOpen: (value: boolean) => {},
});

const ScreenPlayPage = () => {
  const { createProject } = useProject();
  const enabledVoiceList = useRecoilValue(enabledVoiceListSelector);
  useEffect(() => {
    const projectList = JSON.parse(
      sessionStorage.getItem('projectList') || '[]'
    );
    // Project가 없고 enabledVoiceList 가 만들어 졌다면,
    if (projectList.length === 0 && enabledVoiceList.length > 0) {
      createProject();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enabledVoiceList]);

  const navigate = useNavigate();
  const { id } = useParams();
  const setSelectedProjectId = useSetRecoilState(selectedProjectIdModel);
  const setProject = useSetRecoilState(projectModel);
  const setSceneList = useSetRecoilState(sceneListModel);
  const setVoiceList = useSetRecoilState(voiceListModel);
  const projectList = useRecoilValue(projectListModel);
  const timelinePanelHeight = useRecoilValue(timelinePanelHeightModel);
  // Route Id 에 따른 기본 데이터 셋팅
  const loadProjectData = useCallback(
    (id: string) => {
      const selectedProject = projectList.find(
        (project) => project.id === id
      ) as Project;
      setProject(selectedProject);
      const scenes = getScenes(selectedProject.id);
      setSceneList(scenes);
      const voices = getVoices(selectedProject.id);
      setVoiceList(voices);
    },
    [setProject, projectList, setSceneList, setVoiceList]
  );

  const { loadProject } = useSessionStorage();

  useEffect(() => {
    if (id && !!projectList.find((project) => project.id === id)) {
      setSelectedProjectId(id);
      loadProjectData(id);
      loadProject(id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, projectList, navigate, setSelectedProjectId, loadProjectData]);

  // tmp: Voice 권한 설정
  const { user } = useSUPAuth();
  const setPermittedVoiceList = useSetRecoilState(permittedVoiceListModel);

  useEffect(() => {
    if (!user?.permissions) return;
    const permittedList: string[] = user.permissions
      .filter((p) => p.includes('/screenplay/voice/'))
      .map((p) => p.split('/').pop() as string);
    setPermittedVoiceList(permittedList);
  }, [user, setPermittedVoiceList]);

  const isShowTimeline = useRecoilValue(isShowTimelineModel);
  const isShowMedia = useRecoilValue(isShowMediaModel);
  const [isModalOpen, setIsModalOpen] = useState(false);
  return (
    <ModalContext.Provider value={{ isModalOpen, setIsModalOpen }}>
      <StyledScreenPlay className={'screen-play-main'}>
        <ScreenPlayHeader />
        <section className="sp-middle-area">
          <section
            className={classNames('sp-middle-top', isShowTimeline && 'narrow')}
            style={{
              height: isShowTimeline
                ? `calc(100% - ${timelinePanelHeight}px)`
                : '100%',
            }}
          >
            <ProjectPanel />
            <SceneWriterPanel />
            {isShowMedia && <MediaPanel />}
            <ControlPanel />
          </section>
          {isShowTimeline && <TimelinePanel />}
        </section>
        <GlobalAudioPlayer />
        {isModalOpen && (
          <AlertModal
            isOpen={isModalOpen}
            onConfirm={() => setIsModalOpen(false)}
            message="현재 버전에서는 사용할 수 없는 기능입니다."
            confirmText="OK"
          />
        )}
      </StyledScreenPlay>
    </ModalContext.Provider>
  );
};
export default ScreenPlayPage;
