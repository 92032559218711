import { useCallback, useEffect, useMemo, useRef, useState } from 'react';

import { getAudioContext } from '../../../../util/audio';
import { AudioItemInfo, AudioType } from '../../stores/audios';
import { createAudioNodes } from './utils';

export interface AudioSource {
  id: string;
  lineId?: string;
  type: AudioType;
  position: number;
  duration: number;
  audioBuffer: AudioBuffer;
  fileName?: string;
}

interface UseMultiAudioControllerProps {
  audioSources: AudioSource[];
}
interface UseMultiAudioControllerReturn {
  currentPosition: number;
  totalDuration: number;
  gainValue: number;
  isPlaying: boolean;
  isNext: boolean;
  isPrev: boolean;
  currentAudioInfo: AudioItemInfo[];
  play: (position?: number) => void;
  pause: () => void;
  stop: () => void;
  forward: () => void;
  backward: () => void;
  updateVolume: (newGain: number) => void;
  seek: (time: number) => void;
}

export function useMultiAudioController({
  audioSources,
}: UseMultiAudioControllerProps): UseMultiAudioControllerReturn {
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const [startPosition, setStartPosition] = useState<number>(0);
  const [pausedPosition, setPausedPosition] = useState<number>(0);

  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentAudioIndex, setCurrentAudioIndex] = useState<number | null>(0);
  const [currentAudioInfo, setCurrentAudioInfo] = useState<AudioItemInfo[]>([]);
  const [gainValue, setGainValue] = useState<number>(1); // 0 ~ 1
  const [isFadeOut, setIsFadeOut] = useState<boolean>(false);

  const audioNodesRef = useRef<AudioScheduledSourceNode[]>([]);
  const rafIdRef = useRef<number | null>(null);
  const takeGainValueNodeRef = useRef<GainNode>();
  const bgmGainValueNodeRef = useRef<GainNode>();

  const totalDuration = useMemo(() => {
    const total =
      Math.max(
        ...audioSources.map((audio) => {
          if (audio.type === 'audio') return 0;
          return audio.position + audio.duration;
        })
      ) || 0;
    return total === -Infinity ? 0 : total;
  }, [audioSources]);

  const prevIdx = useMemo(() => {
    if (audioSources.length < 2) return -1;
    if (
      currentAudioIndex !== null &&
      currentAudioIndex > 0 &&
      // 만약 이전 오디오의 시작점이 현재 오디오의 시작점과 같은지 체크
      audioSources[currentAudioIndex]?.position !==
        audioSources[currentAudioIndex - 1]?.position
    ) {
      return currentAudioIndex - 1;
    }
    const filteredAudioSources = audioSources.filter(
      // currentPosition보다 앞에 위치한 오디오를 찾으려면 position + duration이 currentPosition보다 작아야 함
      (audio) =>
        audio.type !== 'audio' &&
        audio.position + audio.duration < currentPosition
    );
    return audioSources.findIndex(
      // 이미 sorting된 오디오이고, 직전값을 찾으려면 마지막 값을 가져오면 됨
      (audio) =>
        audio.id === filteredAudioSources[filteredAudioSources.length - 1]?.id
    );
  }, [currentAudioIndex, audioSources, currentPosition]);

  const nextIdx = useMemo(() => {
    if (audioSources.length < 2) return -1;
    if (
      currentAudioIndex !== null &&
      currentAudioIndex < audioSources.length - 1 &&
      currentAudioIndex !== -1 &&
      // 만약 다음 오디오의 시작점이 현재 오디오의 시작점과 같은지 체크
      audioSources[currentAudioIndex]?.position !==
        audioSources[currentAudioIndex + 1]?.position
    ) {
      return currentAudioIndex + 1;
    }
    const filteredAudioSources = audioSources.filter(
      // currentPosition보다 뒤에 위치한 오디오를 찾으려면 position이 currentPosition보다 커야 함
      (audio) => audio.type !== 'audio' && audio.position > currentPosition
    );
    return audioSources.findIndex(
      // 이미 sorting된 오디오이고, 다음값을 찾으려면 첫번째 값을 가져오면 됨
      (audio) => audio.id === filteredAudioSources[0]?.id
    );
  }, [currentAudioIndex, audioSources, currentPosition]);

  const getCurrentPosition = useCallback(() => {
    const audioContext = getAudioContext();
    return pausedPosition - startPosition + (audioContext.currentTime || 0);
  }, [startPosition, pausedPosition]);

  const pause = useCallback(() => {
    audioNodesRef.current.forEach((node) => node.stop());
    setIsPlaying(false);
    setPausedPosition(currentPosition);
  }, [currentPosition]);

  const stop = useCallback(() => {
    audioNodesRef.current.forEach((node) => {
      node.stop();
      node.disconnect();
    });
    setIsPlaying(false);
    setPausedPosition(0);
    setCurrentPosition(0);
  }, []);

  const calculateCurrentAudio = useCallback(
    (position: number) => {
      // 만약 여러개의 오디오가 동시에 재생되는 경우 duration이 짧은 오디오를 우선으로 재생
      const currentAudioList = audioSources
        .filter((audio) => {
          return (
            audio.position <= position &&
            position <= audio.position + audio.duration
          );
        })
        // type이 audio가 아니면서, position보다 뒤에 있는 것을 우선
        // 그리고 duration이 짧은 것을 우선
        .sort((a, b) => {
          if (a.type === 'audio' && b.type !== 'audio') return 1;
          if (a.type !== 'audio' && b.type === 'audio') return -1;
          if (a.position < position && b.position >= position) return 1;
          if (a.position >= position && b.position < position) return -1;
          return a.duration - b.duration;
        })
        .map((audio) => ({
          id: audio.id,
          type: audio.type,
          lineId: audio.lineId,
        }));
      return currentAudioList;
    },
    [audioSources]
  );

  useEffect(() => {
    if (isPlaying) return;
    setPausedPosition(currentPosition);
  }, [isPlaying, currentPosition]);

  useEffect(() => {
    if (!audioSources.length) return;
    const currentAudios = calculateCurrentAudio(currentPosition);
    if (!currentAudios.length) {
      setCurrentAudioInfo([]);
      setCurrentAudioIndex(null);
      return;
    }
    const currentAudioIdx = audioSources.findIndex(
      (audio) => audio.id === currentAudios[0].id
    );
    setCurrentAudioInfo(currentAudios);
    currentAudioIdx !== currentAudioIndex &&
      setCurrentAudioIndex(currentAudioIdx);
  }, [currentPosition, audioSources, calculateCurrentAudio, currentAudioIndex]);

  useEffect(() => {
    const updatePosition = () => {
      if (!isPlaying) return;
      const position = getCurrentPosition();

      if (position >= totalDuration) {
        setIsPlaying(false);
        setCurrentPosition(totalDuration);
      }
      setCurrentPosition(position);
      rafIdRef.current = requestAnimationFrame(updatePosition);
    };

    if (isPlaying) {
      rafIdRef.current = requestAnimationFrame(updatePosition);
    } else {
      rafIdRef.current && cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
    }
    return () => {
      rafIdRef.current && cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
    };
  }, [isPlaying, getCurrentPosition, totalDuration, currentAudioIndex]);

  // TODO: tmp: bgm fade out
  useEffect(() => {
    if (!isPlaying || isFadeOut || !bgmGainValueNodeRef.current) return;
    const FADE_OUT_DURATION = 3;
    if (currentPosition >= totalDuration - FADE_OUT_DURATION) {
      setIsFadeOut(true);
      const audioContext = getAudioContext();
      bgmGainValueNodeRef.current.gain.setValueAtTime(
        gainValue * 0.8,
        audioContext.currentTime
      );
      bgmGainValueNodeRef.current.gain.linearRampToValueAtTime(
        0.01,
        audioContext.currentTime + FADE_OUT_DURATION
      );
    }
  }, [currentPosition, totalDuration, isPlaying, isFadeOut, gainValue]);

  const updateVolume = useCallback((newGain: number) => {
    if (!takeGainValueNodeRef.current || !bgmGainValueNodeRef.current) return;
    const audioContext = getAudioContext();
    // set take volume
    takeGainValueNodeRef.current.gain.setValueAtTime(
      newGain,
      audioContext.currentTime
    );
    // set bgm volume
    bgmGainValueNodeRef.current.gain.setValueAtTime(
      newGain * 0.8,
      audioContext.currentTime
    );
    setGainValue(newGain);
  }, []);

  const play = useCallback(
    (position?: number) => {
      setIsFadeOut(false);
      setIsPlaying(true);
      if (currentPosition >= totalDuration) {
        position = 0;
      }

      // audiocontext 생성 및 source 리셋
      const audioContext = getAudioContext();
      const takeGainValueNode = audioContext.createGain();
      takeGainValueNode.connect(audioContext.destination);
      const bgmGainValueNode = audioContext.createGain();
      bgmGainValueNode.connect(audioContext.destination);

      audioNodesRef.current.forEach((node) => {
        node.stop();
        node.disconnect();
      });
      const newStartPosition =
        typeof position === 'number' ? position : currentPosition;
      const takeNodes = createAudioNodes(
        audioContext,
        audioSources.filter((item) => item.type !== 'audio'),
        takeGainValueNode,
        newStartPosition,
        totalDuration
      );

      const bgmNodes = createAudioNodes(
        audioContext,
        audioSources.filter((item) => item.type === 'audio'),
        bgmGainValueNode,
        newStartPosition,
        totalDuration
      );

      setStartPosition(audioContext.currentTime);
      setPausedPosition(newStartPosition);
      audioNodesRef.current = [...takeNodes, ...bgmNodes];
      takeGainValueNodeRef.current = takeGainValueNode;
      bgmGainValueNodeRef.current = bgmGainValueNode;
      updateVolume(gainValue);
    },
    [audioSources, currentPosition, totalDuration, gainValue, updateVolume]
  );

  const forward = useCallback(() => {
    if (nextIdx > -1) {
      pause();
      setCurrentPosition(audioSources[nextIdx].position);
    }
  }, [audioSources, pause, nextIdx]);

  const backward = useCallback(() => {
    if (prevIdx > -1) {
      pause();
      setCurrentPosition(audioSources[prevIdx].position);
    }
  }, [audioSources, pause, prevIdx]);

  const seek = useCallback(
    (position: number) => {
      if (typeof position !== 'number') return;
      stop();
      setCurrentPosition(position);
    },
    [stop]
  );

  return {
    currentPosition,
    totalDuration,
    gainValue,
    isPlaying,
    isNext: nextIdx > -1,
    isPrev: prevIdx > -1,
    currentAudioInfo,
    play,
    pause,
    stop,
    forward,
    backward,
    updateVolume,
    seek,
  };
}
