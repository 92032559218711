import ExpandIcon from '@/components/assets/icons/ExpandIcon.svg';
import ShrinkIcon from '@/components/assets/icons/ShrinkIcon.svg';
import { Input } from '@/components/FileInput';
import DropZone from '@/components/FileInput/DropZone';
import Title from '@/components/Title/Title';
import { downloadAudio } from '@/pages/screenplay/utils/files';
import { Primary, White } from '@/styles/Colors';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { v4 as uuid } from 'uuid';

import DeleteIcon from '../components/assets/icons/DeleteIcon.svg';
import DownloadIcon from '../components/assets/icons/DownloadIcon.svg';
import Checkbox from '../components/Checkbox';
import IconButton from '../components/IconButton';
import { useZeroShotContext } from '../ZeroShotContextProvider';
import { StyledSubTable } from './StyledSubTable';
import StyledZeroShotSourcePanel from './StyledZeroShotPanel';
import ZeroShotShotSourceItem from './ZeroShotSourceItem';

export const AUDIO_FILE_ACCEPT = '.mp3,audio/wav';

// todo Source 컨트롤 용 내부 Provider 별도 생성시 이동
export interface ZeroShotSource {
  id: string;
  name: string;
  file: File;
  isChecked: boolean;
  isExpanded: boolean;
  audioBuffer?: AudioBuffer;
}
const ZeroShotSourcePanel = () => {
  const { selectedSource, setSelectedSource } = useZeroShotContext();
  const [sourceList, setSourceList] = useState<ZeroShotSource[]>([]);
  const [showToast, setShowToast] = useState(false);
  const checkedList = useMemo(
    () => sourceList.filter((source) => source.isChecked),
    [sourceList]
  );
  const select = useCallback(
    (id: string) => {
      const selected = sourceList.find((source) => source.id === id);
      if (selected) {
        setSelectedSource(selected);
      }
    },
    [sourceList, setSelectedSource]
  );
  const checkItem = useCallback((id: string) => {
    setSourceList((prev) =>
      prev.map((source) => {
        if (source.id === id) {
          return {
            ...source,
            isChecked: !source.isChecked,
          };
        }
        return source;
      })
    );
  }, []);
  const checkedAll = useMemo(() => {
    return (
      !!sourceList.length &&
      sourceList.findIndex((source) => !source.isChecked) === -1
    );
  }, [sourceList]);
  const checkAll = useCallback(() => {
    setSourceList((prev) =>
      prev.map((source) => {
        return {
          ...source,
          isChecked: !checkedAll,
        };
      })
    );
  }, [checkedAll]);
  const uploadFiles = useCallback(
    async (files: File[]) => {
      const buffers = await Promise.all(
        files.map(async (file) => {
          const { arrayBuffer } = await fetchAudio(file);
          return getAudioBuffer(arrayBuffer);
        })
      );
      const newSources = files.map((file, index) => {
        return {
          id: uuid(),
          name: file.name,
          file,
          isChecked: false,
          isExpanded: false,
          audioBuffer: buffers[index],
        };
      });
      setSourceList((prev) => [...prev, ...newSources]);
      // 선택된 파일이 없을 경우, 첨부된 첫번째 파일을 선택
      if (!selectedSource) {
        setSelectedSource(newSources[0]);
      }
      // 만약 첨부된 파일 중 길이가 20초 이상인 파일이 있다면, Toast 메시지를 노출
      if (
        newSources.some(
          (source) => source.audioBuffer && source.audioBuffer.duration > 20
        )
      ) {
        setShowToast(true);
        setTimeout(() => {
          setShowToast(false);
        }, 2000);
      }
    },
    [setSourceList, selectedSource, setSelectedSource]
  );
  const deleteFiles = useCallback(
    (id: string) => {
      if (checkedList.findIndex((source) => source.id === id) !== -1) {
        setSelectedSource(null);
      }
      setSourceList((prev) => prev.filter((source) => !source.isChecked));
    },
    [checkedList, setSelectedSource]
  );
  const onDownload = useCallback(() => {
    downloadAudio(
      checkedList.map((source) => ({
        audioBuffer: source.audioBuffer as AudioBuffer,
        fileName: source.name,
      })),
      'source.zip',
      true
    );
  }, [checkedList]);
  const totalCounts = useMemo(() => {
    return checkedList.length
      ? `${checkedList.length} / ${sourceList.length}`
      : sourceList.length;
  }, [checkedList, sourceList]);
  const expandedAll = useMemo(() => {
    return sourceList.findIndex((source) => !source.isExpanded) === -1;
  }, [sourceList]);
  const expandAll = useCallback(() => {
    setSourceList((prev) =>
      prev.map((source) => {
        return {
          ...source,
          isExpanded: !expandedAll,
        };
      })
    );
  }, [expandedAll, setSourceList]);
  const toggle = useCallback(
    (id: string) => {
      setSourceList((prev) =>
        prev.map((source) => {
          if (source.id === id) {
            return {
              ...source,
              isExpanded: !source.isExpanded,
            };
          }
          return source;
        })
      );
    },
    [setSourceList]
  );

  return (
    <StyledZeroShotSourcePanel className="admin-grid-item zero-shot-source">
      <section className="zero-shot-source-header">
        <Title size="lg">SOURCE AUDIO</Title>
        <Input
          className="attach-button-primary"
          caption={'Add Files'}
          accept={AUDIO_FILE_ACCEPT}
          addFiles={uploadFiles}
        />
      </section>
      <StyledSubTable className="sup-files">
        <DropZone accept={AUDIO_FILE_ACCEPT} addFiles={uploadFiles}>
          {!!sourceList.length && (
            <>
              <section className="sup-files-control">
                <section className="sup-files-count">
                  {totalCounts} {sourceList.length > 1 ? 'Files' : 'File'}
                </section>
                {!!checkedList.length && (
                  <section className="sup-files-buttons">
                    <IconButton
                      iconColor={White}
                      width="2.5rem"
                      height="2.5rem"
                      iconSize="1rem"
                      onClick={() => deleteFiles(selectedSource?.id as string)}
                      icon={DeleteIcon}
                    />
                    <IconButton
                      iconColor={White}
                      width="2.5rem"
                      height="2.5rem"
                      iconSize="1.125rem"
                      onClick={() => onDownload()}
                      icon={DownloadIcon}
                    />
                  </section>
                )}
              </section>
              <section className="sup-files-header">
                <Checkbox
                  selectColorSet={{
                    icon: White,
                    bg: Primary[400],
                    border: Primary[400],
                  }}
                  checked={checkedAll}
                  onChange={checkAll}
                />
                <section className="sup-files-name">FILE NAME</section>
                <section className="sup-files-length">LENGTH</section>
                <section className="sup-files-expand">
                  <IconButton
                    iconColor={White}
                    onClick={expandAll}
                    iconSize="1rem"
                    icon={expandedAll ? ShrinkIcon : ExpandIcon}
                  />
                </section>
              </section>
            </>
          )}
          <ul>
            {sourceList.map((source) => {
              return (
                <ZeroShotShotSourceItem
                  key={source.id}
                  toggle={toggle}
                  source={source}
                  isSelected={selectedSource?.id === source.id}
                  checkItem={checkItem}
                  select={select}
                />
              );
            })}
          </ul>
        </DropZone>
        <section
          className={classNames('zero-shot-source-toast', {
            hide: !showToast,
          })}
        >
          <p>
            첨부된 오디오의 길이가 20 초 이상입니다.
            <br />
            20 초 이후의 데이터는 생성에 반영되지 않습니다.
          </p>
        </section>
      </StyledSubTable>
    </StyledZeroShotSourcePanel>
  );
};
export default ZeroShotSourcePanel;
