import IconButton from '@/components/Button/IconButton';
import classNames from 'classnames';
import React, { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import {
  Line,
  selectedLineIdModel,
  selectedTakeIdModel,
  takeListSelector,
} from '../stores/project';

const LineItem: React.FC<{ line: Line; num: number }> = ({ line, num }) => {
  const { t } = useTranslation('screenPlay');
  const takes = useRecoilValue(takeListSelector(line.id));
  const [isOpen, setIsOpen] = React.useState(false);
  const toggleItem = useCallback(() => {
    setIsOpen(!isOpen);
  }, [isOpen, setIsOpen]);
  const isMultiTakes = useMemo(() => takes.length > 1, [takes]);
  const setSelectedLineId = useSetRecoilState(selectedLineIdModel);
  const setSelectedTakeId = useSetRecoilState(selectedTakeIdModel);
  const onSelectLine = useCallback(() => {
    setSelectedLineId(line.id);
  }, [setSelectedLineId, line.id]);
  const onSelectTake = useCallback(
    (takeId: string) => {
      onSelectLine();
      setSelectedTakeId(takeId);
    },
    [setSelectedTakeId, onSelectLine]
  );

  return (
    <ul key={`line-${line.id}`}>
      <li className={classNames('line-item', isMultiTakes && 'multi-takes')}>
        <section
          className="line-item-title"
          onClick={(e) => {
            e.stopPropagation();
            setSelectedTakeId(null);
            onSelectLine();
          }}
        >
          <strong className="line-icon">
            {t('Line')} {num}
          </strong>
          <span className="line-text">{line.text}</span>
          <IconButton
            color="transparent"
            className="btn-line-toggle"
            onClick={toggleItem}
          >
            {isOpen ? <BottomArrow /> : <RightArrow />}
          </IconButton>
        </section>
        {isOpen && (
          <ul className="take-list">
            {takes.map((take, index) => {
              const isSelected =
                takes.length > 1 && line.selectedTakeId === take.id;
              return (
                <li
                  key={`take-${take.id}`}
                  className={classNames('take-item', isSelected && 'selected')}
                  onClick={(e) => {
                    e.stopPropagation();
                    onSelectTake(take.id);
                  }}
                >
                  <span className="take-icon">
                    {t('Take')} {index + 1}
                  </span>
                  {isSelected && (
                    <span className="selected-icon">{t('Selected')}</span>
                  )}
                </li>
              );
            })}
          </ul>
        )}
      </li>
    </ul>
  );
};

export default LineItem;
