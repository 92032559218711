import styled from '@emotion/styled';

import { Grey, PureBlack } from '../../styles/Colors';

export const StyledWaveform = styled.div`
  box-sizing: border-box;
  width: 100%;
  height: 100%;
  background-color: ${PureBlack};
  border: 1px solid ${Grey[500]};
  position: relative;
  canvas {
    width: 100%;
    height: 100%;
  }
  .wave-progress {
    position: absolute;
    top: 0;
    left: 0;
  }
`;
