import IconButton from '@/components/Button/IconButton';
import Title from '@/components/Title/Title';
import { useContext } from 'react';

import { ReactComponent as PencelIcon } from '../assets/icons/PencelIcon.svg';
import { ModalContext } from '../ScreenPlayPage';

const CommentTab = () => {
  const { setIsModalOpen } = useContext(ModalContext);
  return (
    <section className="comment-tab">
      <section className="comment-section">
        <section className="comment-section-header">
          <Title size="md" className="comment-section-title">
            <span>Project</span>
          </Title>
          <IconButton
            color="transparent"
            className="btn-write"
            onClick={() => setIsModalOpen(true)}
          >
            <PencelIcon />
          </IconButton>
        </section>
        <ul className="comment-list">
          <li className="comment-item">
            <section className="comment-item-header">
              <span className="user-name">프로젝트 관리자</span>
              <span className="comment-date">23-11-07</span>
            </section>
            <span className="comment-content">
              코멘트 기능으로 편리하게 협업을 구축할 수 있습니다.
            </span>
          </li>
          <li className="comment-item">
            <section className="comment-item-header">
              <span className="user-name">프로젝트 관리자</span>
              <span className="comment-date">23-11-07</span>
            </section>
            <span className="comment-content">
              전체 프로젝트에 필요한 코멘트를 남길 수 있고
            </span>
          </li>
        </ul>
      </section>
      <section className="comment-section">
        <section className="comment-section-header">
          <Title size="md" className="comment-section-title">
            <span>Line # 1</span>
          </Title>
          <IconButton
            color="transparent"
            className="btn-write"
            onClick={() => setIsModalOpen(true)}
          >
            <PencelIcon />
          </IconButton>
        </section>
        <ul className="comment-list">
          <li className="comment-item">
            <section className="comment-item-header">
              <span className="user-name">팀 멤버</span>
              <span className="comment-date">23-11-07</span>
            </section>
            <span className="comment-content">
              이렇게 라인 마다 별도로 코멘트를 남길 수도 있어요.
            </span>
          </li>
        </ul>
      </section>
    </section>
  );
};
export default CommentTab;
