import { Line, Scene, Take, Voice } from '../stores/project';

// 라우팅 변경시 데이터 유지를 위한 session storage 사용
export const getProjects = () => {
  return JSON.parse(sessionStorage.getItem('projectList') ?? '[]');
};
export const getScenes = (projectId: string) => {
  return JSON.parse(sessionStorage.getItem(`${projectId}/scenes`) ?? '[]');
};
export const updateScenes = (projectId: string, scenes: Scene[]) => {
  sessionStorage.setItem(`${projectId}/scenes`, JSON.stringify(scenes));
};
export const getLines = (projectId: string, sceneId: string) => {
  return JSON.parse(
    sessionStorage.getItem(`${projectId}/scenes/${sceneId}`) ?? '[]'
  );
};
export const updateLine = (
  projectId: string,
  sceneId: string,
  lines: Line[]
) => {
  sessionStorage.setItem(
    `${projectId}/scenes/${sceneId}`,
    JSON.stringify(lines)
  );
};
export const getVoices = (projectId: string) => {
  return JSON.parse(sessionStorage.getItem(`${projectId}/voices`) ?? '[]');
};
export const updateVoice = (projectId: string, voices: Voice[]) => {
  sessionStorage.setItem(`${projectId}/voices`, JSON.stringify(voices));
};
export const updateTake = (
  projectId: string,
  lineId: string,
  takes: Take[]
) => {
  const takeMap = {} as Record<string, number>;
  sessionStorage.setItem(
    `${projectId}/${lineId}/takes`,
    JSON.stringify(
      takes.reduce((acc, cur) => {
        // todo upload and save resource_id
        if (takeMap[cur.id]) {
          return acc;
        } else {
          takeMap[cur.id] = 1;
        }
        acc.push(cur);
        return acc;
      }, [] as Take[])
    )
  );
};
export const getTakes = (projectId: string, lineId: string) => {
  const takes = JSON.parse(
    sessionStorage.getItem(`${projectId}/${lineId}/takes`) ?? '[]'
  ) as Take[];

  const takeMap = {} as Record<string, number>;
  return takes.reduce((acc, cur) => {
    if (takeMap[cur.id]) {
      return acc;
    } else {
      takeMap[cur.id] = 1;
    }
    acc.push(cur);
    return acc;
  }, [] as Take[]);
};
