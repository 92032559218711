import { Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import LineIconHover from '../assets/icons/LineIcon_hover.svg';
import TakeIcon from '../assets/icons/TakeIcon.svg';
import TakeIconHover from '../assets/icons/TakeIconHover.svg';
import SceneIconActive from '../assets/images/SceneIcon_active.svg';
import SelectedIcon from '../assets/images/SelectedIcon.svg';

const StyledSceneTab = styled.section`
  .scene-list {
    ul.scenes {
      padding-top: 0;
      padding-bottom: 0;
      border-bottom: 1px solid ${Grey[500]};
      overflow-y: auto;
      max-height: ${2.52 * 4}rem;
    }
    .list-item {
      &.scene {
        height: 2.5rem;
        padding-top: 0.5rem;
        padding-bottom: 0.5rem;
        .list-item-title {
          color: ${Grey[50]};
          &::before {
            width: 1.3125rem;
            height: 1.3125rem;
            background-image: url(${SceneIconActive});
          }
        }
        .editing {
          width: 100%;
        }
      }
    }
    .list-item-detail {
      border-bottom: 0;
      .line-item {
        grid-template-columns: 1fr;
        font-size: 0.875rem;
        cursor: pointer;
        strong {
          font-weight: ${FontWeight['Regular']};
        }
        .line-item-title {
          height: 2.5rem;
          padding: 0.315rem 1.25rem;
          display: flex;
          justify-content: space-between;
          align-items: center;
          &:hover {
            background-color: ${Grey[500]};
          }
          .line-text {
            flex: 1;
            color: ${Grey[100]};
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 1;
            overflow: hidden;
            word-break: break-all;
            margin-left: 1rem;
            margin-right: 1rem;
            font-size: 0.875rem;
            color: ${Grey[400]};
          }
        }
        .line-icon {
          color: ${Grey[50]};
          ::before {
            content: '';
            vertical-align: middle;
            display: inline-block;
            width: 1rem;
            height: 2.5rem;
            margin-right: 0.44rem;
            background-image: url(${LineIconHover});
            background-size: cover;
          }
        }
      }
    }
  }
  .list-item-detail {
    border-bottom: 1px solid ${Grey[500]};
    .take-item {
      &:hover {
        background-color: ${Grey[500]};
      }
      font-size: 0.875rem;
      padding: 0 2.5rem;
      cursor: pointer;
      .take-icon {
        color: ${Grey[300]};
        ::before {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 0.75rem;
          height: 2.5rem;
          margin-right: 0.44rem;
          background-image: url(${TakeIcon});
          background-size: cover;
        }
        height: 2.5rem;
      }
      &.selected {
        color: ${Grey[50]};
        display: flex;
        justify-content: space-between;
        align-items: center;
        .take-icon {
          color: ${Grey[50]};
          ::before {
            background-image: url(${TakeIconHover});
          }
        }
        .selected-icon {
          ::before {
            content: '';
            vertical-align: middle;
            display: inline-block;
            width: 1.1875rem;
            height: 0.625rem;
            margin-right: 0.44rem;
            background-image: url(${SelectedIcon});
            background-size: cover;
            font-weight: ${FontWeight['Bold']};
          }
        }
      }
    }
  }
`;
export default StyledSceneTab;
