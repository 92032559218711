import { atom } from 'recoil';

export const isShowTimelineModel = atom<boolean>({
  key: 'screenplay/isShowTimelineModel',
  default: false,
});
export const projectsTabStateModel = atom<number>({
  key: 'screenplay/projectsTabModel',
  default: 0,
});
export const isShowMediaModel = atom<boolean>({
  key: 'screenplay/isShowMediaModel',
  default: false,
});
