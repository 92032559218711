import { WebSocketContext } from '@/providers/WebSocketProvider';
import { useCallback, useContext } from 'react';
import { useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { cvc, tts } from '../api';
import { SPEECH_CONTROL_LIST } from '../ControlPanel/SpeechTab';
import { audioFileMapModel } from '../stores/audios';
import {
  Line,
  Take,
  Voice,
  lineListModel,
  takeListModel,
  voiceListModel,
} from '../stores/project';

const useAddTake = () => {
  const lineList = useRecoilValue(lineListModel);
  const voiceList = useRecoilValue(voiceListModel);
  const setTakeList = useSetRecoilState(takeListModel);
  const setAudioFileMap = useSetRecoilState(audioFileMapModel);
  const { sessionId } = useContext(WebSocketContext);
  const addTake = useCallback(
    async (take: Take, isOverwrite?: boolean) => {
      const line = lineList.find((l) => l.id === take.lineId);
      const voice = voiceList.find((v) => v.id === line?.voiceId);
      const parameter = take.parameter || {
        pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
        pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
        speed: SPEECH_CONTROL_LIST[2].defaultValue,
      };
      let targetTakeId = take.id;
      if (isOverwrite) {
        setTakeList((prev) => {
          return prev.map((t) => {
            if (t.id === take.id) {
              return {
                ...take,
                isFetching: true,
              };
            }
            return t;
          });
        });
      } else {
        targetTakeId = uuid();
        setTakeList((prev) => {
          const newTake = {
            ...take,
            isFetching: true,
            id: targetTakeId,
          };
          return prev.concat(newTake);
        });
      }
      const { audioBuffer, resource_id } =
        take.type === 'cvc'
          ? await cvc(
              line as Line,
              take.file as File,
              voice as Voice,
              parameter,
              sessionId
            )
          : await tts(line as Line, voice as Voice, parameter);
      setAudioFileMap((prev) => {
        return {
          ...prev,
          [targetTakeId]: {
            audioBuffer: audioBuffer as AudioBuffer,
          },
        };
      });
      setTakeList((prev) => {
        return prev.map((t) => {
          if (t.id === targetTakeId) {
            const take = {
              ...t,
              isFetching: false,
              parameter,
            };
            return resource_id ? { ...take, file: resource_id } : take;
          }
          return t;
        });
      });
    },
    [setTakeList, lineList, sessionId, voiceList, setAudioFileMap]
  );
  return addTake;
};
export default useAddTake;
