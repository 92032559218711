import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { useResetRecoilState, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import {
  Project,
  editNameProjectIdModel,
  projectListModel,
  selectedProjectIdModel,
} from '../stores/project';
import useSessionStorage from './useSessionStorage/useSessionStorage';

const useProject = () => {
  const setProjectList = useSetRecoilState(projectListModel);
  const setEditNameProjectId = useSetRecoilState(editNameProjectIdModel);
  const navigate = useNavigate();
  const { saveProject, addNewSet } = useSessionStorage();
  const resetSelectedSceneId = useResetRecoilState(selectedProjectIdModel);
  const createProject = useCallback(() => {
    // input new project name
    const id = uuid();
    const newProject = addNewSet(id) as Project;
    setProjectList((oldProjectList) => {
      const newProjectList = [...oldProjectList];
      newProjectList.unshift(newProject);
      return newProjectList;
    });
    // save data before load other project
    saveProject();
    setEditNameProjectId(id);
    resetSelectedSceneId();
    navigate(`/editor/${id}`);
  }, [
    setProjectList,
    navigate,
    saveProject,
    setEditNameProjectId,
    addNewSet,
    resetSelectedSceneId,
  ]);
  return { createProject };
};

export default useProject;
