import { ScaleLinear } from 'd3-scale';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { normalizeWheel } from '../../../../components/AudioEditor/utils';
import {
  audioPlayerStateModel,
  currentPlayingAudioInfoListModel,
} from '../../stores/audios';
import {
  lineListModel,
  selectedLineIdModel,
  selectedTakeIdModel,
} from '../../stores/project';
import {
  TimelineListItem,
  bgmListModel,
  timelinePlaybackModel,
} from '../../stores/timeline';
import { BLOCK_HEIGHT, COLUMN_HEIGHT, LEFT_OFFSET } from './const';
import TimelineBlock, { Block } from './TimelineBlock';

interface AudioContainerProps {
  xRange?: [number, number];
  timeRange?: [number, number];
  updateXPosition: (p: number) => void;
  data: TimelineListItem[];
  xScale: ScaleLinear<number, number>;
}
interface DragInfo extends Block {
  isDragging: boolean;
}

const AudioContainer = ({
  data,
  xScale,
  xRange,
  timeRange,
  updateXPosition,
}: AudioContainerProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const [draggingItem, setDraggingItem] = useState<DragInfo | null>();

  const setLineList = useSetRecoilState(lineListModel);
  const setBgmList = useSetRecoilState(bgmListModel);
  const resetSelectedLineId = useResetRecoilState(selectedLineIdModel);
  const resetSelectedTakeId = useResetRecoilState(selectedTakeIdModel);
  const currentPlayingAudioList = useRecoilValue(
    currentPlayingAudioInfoListModel
  );
  const audioPlayerState = useRecoilValue(audioPlayerStateModel);
  const setTimelinePlayback = useSetRecoilState(timelinePlaybackModel);

  const isAudioPlaying = useMemo(() => {
    if (audioPlayerState?.type === 'timeline' && audioPlayerState?.isPlaying) {
      return true;
    }
    return false;
  }, [audioPlayerState]);

  const lastLinePosition = useMemo(() => {
    const position = data.reduce((acc, cur) => {
      return Math.max(
        acc,
        cur.items.reduce((prev, item) => {
          if (item.type === 'audio') return prev;
          return Math.max(prev, item.position + (item.duration || 0));
        }, 0)
      );
    }, 0);
    return position;
  }, [data]);

  useEffect(() => {
    if (!lastLinePosition) return;
    setBgmList((prev) =>
      prev.map((bgm) => ({
        ...bgm,
        items: bgm.items.map((item) => ({
          ...item,
          duration: lastLinePosition - item.position,
        })),
      }))
    );
  }, [setBgmList, lastLinePosition]);

  const items = useMemo(() => {
    const dataList = data.reduce((acc, cur, index) => {
      return acc.concat(
        cur.items.map((item) => ({
          id: item.id,
          content: item.content,
          type: item.type,
          startX: xScale(item.position),
          endX: xScale(item.position + (item.duration || 0)),
          startY: index * COLUMN_HEIGHT + (COLUMN_HEIGHT - BLOCK_HEIGHT) / 2,
          endY: index * COLUMN_HEIGHT + (COLUMN_HEIGHT + BLOCK_HEIGHT) / 2,
          disabled: cur.voice.disabled,
          isPlaying: currentPlayingAudioList.some(
            (audio) => audio.id === item.id
          ),
        }))
      );
    }, [] as Block[]);
    return dataList;
  }, [data, xScale, currentPlayingAudioList]);

  const handlePointerDown = useCallback(
    (e: React.PointerEvent<HTMLDivElement>) => {
      if (isAudioPlaying) return;
      const target = (e.target as HTMLElement).closest('.block');
      if (!target) return;

      const id = target.id;
      const idx = items.findIndex((item) => item.id === id);
      if (idx === -1 || items[idx]?.type === 'audio') return;

      const startClientX = e.clientX;

      const handlePointerMove = (e: PointerEvent) => {
        const dx = e.clientX - startClientX;
        setDraggingItem({
          ...items[idx],
          isDragging: true,
          startX: items[idx].startX + dx,
          endX: items[idx].endX + dx,
        });
      };

      const handlePointerUp = (e: PointerEvent) => {
        if (isAudioPlaying) return;

        const dx = e.clientX - startClientX;
        const dragItem = items[idx];
        const newStartX = Math.max(dragItem.startX + dx, 0);
        const newEndX = dragItem.endX + dx;

        let newStartP = xScale.invert(newStartX);
        // 만약 최대 시간보다 newStartX+duration이 크다면, newStartX를 최대 시간으로 설정
        const maxTimeP = timeRange ? xScale(timeRange[1]) - LEFT_OFFSET : 0;

        if (timeRange && newEndX > maxTimeP) {
          newStartP = xScale.invert(newStartX - (newEndX - maxTimeP));
        }

        if (dragItem.type === 'audio') {
          setBgmList((prev) => {
            const bgmIndex = prev.findIndex((bgm) =>
              bgm.items.some((item) => item.id === dragItem.id)
            );
            if (bgmIndex === -1) return prev;

            const newBgmList = [...prev];
            const newBgm = { ...newBgmList[bgmIndex] };

            newBgm.items = newBgm.items.map((item) =>
              item.id === dragItem.id ? { ...item, position: newStartP } : item
            );

            newBgmList.splice(bgmIndex, 1, newBgm);
            return newBgmList;
          });
        } else {
          setLineList((prev) => {
            const lineIdx = prev.findIndex(
              (line) => line.selectedTakeId === dragItem.id
            );
            if (lineIdx === -1) return prev;

            const newLineList = [...prev];
            const newLine = { ...newLineList[lineIdx] };

            newLine.position = newStartP;

            newLineList.splice(lineIdx, 1, newLine);
            newLineList.sort((a, b) => {
              if (a.position && b.position) {
                return a.position - b.position;
              }
              // position이 없는 경우는 뒤로 보냄
              return 1;
            });
            return newLineList;
          });
          // reset selected line id
          resetSelectedLineId();
          resetSelectedTakeId();
          // timeline playback position update
          setTimelinePlayback(newStartP);
        }
        setDraggingItem(null);

        window.removeEventListener('pointermove', handlePointerMove);
        window.removeEventListener('pointerup', handlePointerUp);
      };

      window.addEventListener('pointermove', handlePointerMove);
      window.addEventListener('pointerup', handlePointerUp);
    },
    [
      items,
      isAudioPlaying,
      setLineList,
      xScale,
      setBgmList,
      timeRange,
      setTimelinePlayback,
      resetSelectedLineId,
      resetSelectedTakeId,
    ]
  );

  const handleWheel = useCallback(
    (e: WheelEvent) => {
      // passive: false 처리를 해야 preventDefault가 동작함
      e.preventDefault();
      if (!xRange || !timeRange) return;
      const [dx, dy] = normalizeWheel(e);
      // TODO: tmp 코드. 추후 zoom 대응 추가 시 수정 필요
      if (dy) {
        const container = containerRef.current?.parentElement;
        if (!container) return;
        // 위아래 드래그
        container.scrollTop += dy;
        return;
      }

      const [start, end] = xRange;
      const newCenter = xScale.invert(xScale((start + end) / 2) + dx);
      const newPosition = (newCenter / timeRange[1]) * 100;
      // 좌우 드래그
      updateXPosition?.(newPosition);
    },
    [xScale, xRange, timeRange, updateXPosition]
  );

  useEffect(() => {
    const container = containerRef.current;
    if (!container) return;

    const rafHandleWheel = (e: WheelEvent) => {
      e.preventDefault();
      requestAnimationFrame(() => handleWheel(e));
    };

    container.addEventListener('wheel', rafHandleWheel, { passive: false });
    return () => {
      container.removeEventListener('wheel', rafHandleWheel);
    };
  }, [handleWheel]);

  return (
    <div
      className="timeline-block-container"
      ref={containerRef}
      onPointerDown={handlePointerDown}
    >
      <div className="grids">
        {data.length &&
          data.map((_, i) => <div key={i} className="grid"></div>)}
      </div>
      <div className="blocks">
        {items.length &&
          items.map((item) => (
            <TimelineBlock
              key={item.id}
              data={item}
              isDraggable={!isAudioPlaying && item.type !== 'audio'}
              dimmed={draggingItem?.id === item.id}
            />
          ))}
        {draggingItem && (
          <TimelineBlock
            data={{
              ...draggingItem,
            }}
            dragging={draggingItem?.isDragging}
            isDraggable={!isAudioPlaying && draggingItem.type !== 'audio'}
            hide={!draggingItem?.isDragging}
          />
        )}
      </div>
    </div>
  );
};

export default AudioContainer;
