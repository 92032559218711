import { scaleLinear } from 'd3-scale';
import {
  PointerEvent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';

import { Grey, Primary, Secondary } from '../../styles/Colors';
import { StyledWaveform } from './StyledWaveform';
import { resampleAudioData } from './utils';

interface WaveformProps {
  audioBuffer: AudioBuffer;
  playback?: number;
  onPlaybackChange?: (time: number) => void;
}

const RECT_WIDTH = 3;
const RECT_MARGIN = 1;
const RECT_RADIUS = 2;

const Waveform = ({
  audioBuffer,
  playback,
  onPlaybackChange,
}: WaveformProps) => {
  const wrapperRef = useRef<HTMLDivElement>(null);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const progressCanvasRef = useRef<HTMLCanvasElement>(null);
  const [size, setSize] = useState({ width: 0, height: 0 });

  const xScale = useMemo(() => {
    return scaleLinear()
      .domain([0, audioBuffer?.duration || 0])
      .range([0, size.width]);
  }, [audioBuffer, size]);

  const drawWave = useCallback(
    (canvas: HTMLCanvasElement, color: string, progress?: number) => {
      const ctx = canvas?.getContext('2d');
      if (!ctx) return;
      let { width, height } = size;
      const ratio = window.devicePixelRatio || 1;
      width *= ratio;
      height *= ratio;

      const rectWidth = RECT_WIDTH * ratio;
      const rectMargin = RECT_MARGIN * ratio;
      const rectRadius = RECT_RADIUS * ratio;

      canvas.width = width;
      canvas.height = height;

      ctx.fillStyle = color;
      ctx.clearRect(0, 0, width, height);

      // TODO: 현재는 Mono만 지원, 추후 Stereo 등 다른 채널 지원
      const data = audioBuffer.getChannelData(0);
      const resampledData = resampleAudioData(
        data,
        width,
        rectWidth,
        rectMargin
      );

      const length =
        typeof progress !== 'number'
          ? resampledData.length
          : (progress * ratio) / (rectWidth + rectMargin);

      for (let i = 0; i < length; i++) {
        const x = i * (rectWidth + rectMargin);
        const y = Math.abs(resampledData[i] * height) / 2;

        ctx.roundRect(x, height / 2 - y, rectWidth, y, [
          rectRadius,
          rectRadius,
          0,
          0,
        ]);
        ctx.roundRect(x, height / 2, rectWidth, y, [
          0,
          0,
          rectRadius,
          rectRadius,
        ]);
      }
      ctx.fill();

      // draw line
      if (typeof progress === 'number') {
        ctx.beginPath();
        ctx.moveTo(progress * ratio, 0);
        ctx.lineTo(progress * ratio, height);
        ctx.strokeStyle = Secondary[200];
        ctx.lineWidth = 1 * ratio;
        ctx.stroke();
      }
      ctx.scale(ratio, ratio);
    },
    [audioBuffer, size]
  );

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;
    drawWave(canvas, Grey[400]);
  }, [drawWave]);

  useEffect(() => {
    const progressCanvas = progressCanvasRef.current;
    if (!progressCanvas) return;
    drawWave(progressCanvas, Primary[450], xScale(playback || 0));
  }, [playback, drawWave, xScale]);

  useEffect(() => {
    const wrapper = wrapperRef.current;
    if (!wrapper) return;

    const resizeObserver = new ResizeObserver((entries) => {
      const { width, height } = entries[0].contentRect;
      if (width === 0 || height === 0) return;
      if (width === size.width && height === size.height) return;
      setSize({ width, height });
    });

    resizeObserver.observe(wrapper);
    return () => {
      resizeObserver.unobserve(wrapper);
    };
  }, [size]);

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      const wrapper = wrapperRef.current;
      if (!wrapper) return;
      const { left } = wrapper.getBoundingClientRect();
      const x = e.clientX - left;
      const time = xScale.invert(x);
      onPlaybackChange?.(time);
    },
    [onPlaybackChange, xScale]
  );

  return (
    <StyledWaveform
      className="sup-waveform"
      ref={wrapperRef}
      onPointerDown={handlePointerDown}
    >
      <canvas className="wave" ref={canvasRef} />
      <canvas className="wave-progress" ref={progressCanvasRef} />
    </StyledWaveform>
  );
};

export default Waveform;
