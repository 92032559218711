import {
  PropsWithChildren,
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { ZeroShotParameter } from '../../api';
import {
  LanguageParam,
  languageList,
  languageParamMap,
} from './TTSPanel/const';
import { MODEL_TYPES, SliderParameter, modelParamMap } from './TTSPanel/models';
import { Language } from './ZeroShotResultPanel/ZeroShotResultPanel';
import { ZeroShotSource } from './ZeroShotSourcePanel/ZeroShotSourcePanel';

interface AudioPlayerState {
  audioId: string;
  isPlaying: boolean;
}

interface ZeroShot {
  parameters: SliderParameter[];
  selectedSource: ZeroShotSource | null;
  setSelectedSource: (source: ZeroShotSource | null) => void;
  textMap: { [key: string]: string };
  setTextMap: (textMap: { [key: string]: string }) => void;
  parameterMap: { [key: string]: ZeroShotParameter };
  setParameterMap: (parameterMap: { [key: string]: ZeroShotParameter }) => void;
  languageMap: { [key: string]: Language };
  setLanguage: (lang: LanguageParam) => void;
  language: Language;
  audioPlayerState: AudioPlayerState | null;
  setAudioPlayerState: (state: AudioPlayerState | null) => void;
}

export const ZeroShotContext = createContext({} as ZeroShot);

export const useZeroShotContext = () => useContext(ZeroShotContext);

const ZeroShotContextProvider = ({ children }: PropsWithChildren<{}>) => {
  const [selectedSource, setSelectedSource] = useState<ZeroShotSource | null>(
    null
  );
  // tts panel
  const [textMap, setTextMap] = useState<{ [key: string]: string }>({});
  const [parameterMap, setParameterMap] = useState<{
    [key: string]: ZeroShotParameter;
  }>({});
  // audio player
  const [audioPlayerState, setAudioPlayerState] =
    useState<AudioPlayerState | null>(null);

  // tmp: 추후 tts의 경우도 모델 선택이 필요할 수 있음
  const [selectedModel] = useState(MODEL_TYPES[0]);
  const parameters = useMemo(() => {
    return modelParamMap[selectedModel];
  }, [selectedModel]);

  const [languageMap, setLanguageMap] = useState<{ [key: string]: Language }>(
    {}
  );
  const setLanguage = useCallback(
    (lang: LanguageParam) => {
      setLanguageMap({
        ...languageMap,
        [selectedSource?.id || '']: languageParamMap[lang],
      });
    },
    [languageMap, selectedSource]
  );
  const language = useMemo(() => {
    return (
      languageMap[selectedSource?.id || ''] ||
      languageParamMap[languageList[0].value as LanguageParam]
    );
  }, [languageMap, selectedSource]);

  return (
    <ZeroShotContext.Provider
      value={{
        parameters,
        selectedSource,
        setSelectedSource,
        textMap,
        setTextMap,
        parameterMap,
        setParameterMap,
        language,
        languageMap,
        setLanguage,
        audioPlayerState,
        setAudioPlayerState,
      }}
    >
      {children}
    </ZeroShotContext.Provider>
  );
};

export default ZeroShotContextProvider;
