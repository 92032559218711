import { Dropdown } from '@/components/Dropdown';
import { recordAudio } from '@/components/FileInput/Record';
import { audioBufferToWav, getAudioBuffer } from '@/util/audio';
import classNames from 'classnames';
import { useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';

import { ReactComponent as CvcRecordingIcon } from '../assets/icons/CvcRecordingIcon.svg';
import { ReactComponent as CvcRecordingStopIcon } from '../assets/icons/CvcRecordingStopIcon.svg';
import { ReactComponent as CvcUploadIcon } from '../assets/icons/CvcUploadIcon.svg';
import useAddTake from '../hooks/useAddTake';
import { recorderModel } from '../stores/audios';
import {
  Line,
  Take,
  lineParameterSelector,
  takeListModel,
} from '../stores/project';
import { StyledDirectWithAudio } from '../styles/StyledDropDown';

interface CvcDropDownLayerProps {
  line: Line;
  isOpenLayer: boolean;
  onClose: () => void;
  preventRef?: React.RefObject<HTMLElement>;
}
const CvcDropDownLayer: React.FC<CvcDropDownLayerProps> = ({
  line,
  isOpenLayer,
  onClose,
  preventRef,
}) => {
  const { t } = useTranslation('screenPlay');
  // add take by voice or voice file -> cvc -> add take with audio file
  // open record file -> cvc -> add take with audio file
  const takeList = useRecoilValue(takeListModel);
  const lastTake = useMemo(() => {
    const lineTakes = takeList.filter((take) => take.lineId === line.id);
    return lineTakes[lineTakes.length - 1];
  }, [takeList, line.id]);
  const addTake = useAddTake();
  const lineParameters = useRecoilValue(lineParameterSelector(line.id));
  const onChange = useCallback(
    async (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      const newTake = {
        ...lastTake,
        type: 'cvc',
        file,
        parameter: lineParameters,
      } as Take;
      addTake(newTake);
      onClose();
    },
    [onClose, addTake, lastTake, lineParameters]
  );
  const [recorder, setRecorder] = useRecoilState(recorderModel);
  const onRecord = useCallback(async () => {
    if (recorder) {
      const blob = await recorder.stop();
      const audioBuffer = await getAudioBuffer(
        await new Response(blob).arrayBuffer()
      );
      const newTake = {
        ...lastTake,
        type: 'cvc',
        parameter: lineParameters,
        file: new File(
          [audioBufferToWav(audioBuffer as AudioBuffer)],
          `cvc.wav`,
          { type: 'audio/wav' }
        ),
      } as Take;
      addTake(newTake);
      setRecorder(null);
      onClose();
    } else {
      const rec = await recordAudio();
      setRecorder(rec);
      rec.start();
    }
  }, [setRecorder, recorder, onClose, addTake, lastTake, lineParameters]);

  return (
    <Dropdown
      style={{
        padding: 0,
        background: 'none',
        width: '15rem',
        border: 0,
        marginTop: '1.875rem',
        marginLeft: '1.875rem',
      }}
      placement="bottom-right"
      isOpen={isOpenLayer}
      onClose={onClose}
      closeOnClickOutside={true}
      preventRef={preventRef}
    >
      <StyledDirectWithAudio>
        <section className="title">{t('Direct with Voice')}</section>
        <ul className="cvc-direct-list">
          <li
            onClick={onRecord}
            className={classNames('cvc-label', recorder && 'recording')}
          >
            <span className="label-icon">
              {recorder ? <CvcRecordingStopIcon /> : <CvcRecordingIcon />}
            </span>
            <span className="label-text">
              {t(recorder ? 'Recording' : 'Voice Recording')}
            </span>
          </li>
          <li>
            <label
              htmlFor="cvc_file_input"
              className="cvc-label label-cvc-recording"
            >
              <span className="label-icon">
                <CvcUploadIcon />
              </span>{' '}
              <span className="label-text">{t('Upload Audio')}</span>
            </label>
            <input
              type="file"
              id="cvc_file_input"
              hidden
              accept="audio/*"
              onChange={onChange}
            />
          </li>
        </ul>
      </StyledDirectWithAudio>
    </Dropdown>
  );
};
export default CvcDropDownLayer;
