import { useCallback, useEffect, useRef, useState } from 'react';

import { getAudioContext } from '../../../util/audio';

export interface LoopRange {
  startPosition: number;
  endTime: number;
}

interface AudioControllerState {
  load: (audioBuffer: AudioBuffer) => void;
  reset: () => void;
  play: (time?: number, audioBuffer?: AudioBuffer) => void;
  pause: () => void;
  stop: () => void;
  audioBuffer?: AudioBuffer;
  isPlaying: boolean;
  currentPosition: number;
  updatecurrentPosition: (time: number) => void;
}

const useSingleAudioController = (): AudioControllerState => {
  const [audioBuffer, setAudioBuffer] = useState<AudioBuffer>();
  const [isPlaying, setIsPlaying] = useState(false);
  const [startPosition, setStartPosition] = useState(0);
  const [pausedPosition, setPausedPosition] = useState(0);
  const [currentPosition, setCurrentPosition] = useState(0);
  const sourceNodeRef = useRef<AudioBufferSourceNode | null>(null);
  const rafId = useRef<number | null>(null);

  const getCurrentPosition = useCallback(() => {
    const audioCxt = getAudioContext();
    return pausedPosition + audioCxt.currentTime - startPosition;
  }, [pausedPosition, startPosition]);

  // Update currentPosition when audio is playing
  useEffect(() => {
    const updateTime = () => {
      if (!isPlaying || !audioBuffer) return;
      const currentPosition = getCurrentPosition();
      // currentPosition이 duration을 넘어가면 재생을 멈춤
      if (currentPosition >= audioBuffer.duration) {
        setIsPlaying(false);
        setCurrentPosition(audioBuffer.duration);
        return;
      }
      setCurrentPosition(currentPosition);
      rafId.current = requestAnimationFrame(updateTime);
    };

    if (isPlaying) {
      rafId.current = requestAnimationFrame(updateTime);
    } else {
      rafId.current && cancelAnimationFrame(rafId.current);
      rafId.current = null;
    }
    return () => {
      rafId.current && cancelAnimationFrame(rafId.current);
    };
  }, [isPlaying, getCurrentPosition, audioBuffer]);

  // Update currentPosition when audio is paused
  useEffect(() => {
    if (isPlaying) return;
    setPausedPosition(currentPosition);
  }, [isPlaying, currentPosition]);

  const resetState = useCallback(() => {
    setIsPlaying(false);
    setStartPosition(0);
    setPausedPosition(0);
    setCurrentPosition(0);
  }, [setIsPlaying, setStartPosition, setPausedPosition, setCurrentPosition]);

  const load = useCallback(
    (newAudioBuffer: AudioBuffer) => {
      resetState();
      setAudioBuffer(newAudioBuffer);
    },
    [resetState]
  );

  const reset = useCallback(() => {
    resetState();
    setAudioBuffer(undefined);
  }, [resetState]);

  const resetSourceNode = useCallback(() => {
    if (!sourceNodeRef.current) return;
    sourceNodeRef.current.stop();
    sourceNodeRef.current.disconnect();
  }, [sourceNodeRef]);

  const play = useCallback(
    (position?: number, buffer?: AudioBuffer) => {
      buffer && load(buffer);
      const newAudioBuffer = buffer || audioBuffer;
      if (!newAudioBuffer) return;
      setIsPlaying(true);
      const audioCxt = getAudioContext();
      resetSourceNode();
      // source노드를 재생성하는 것이 비효율적인 것으로 보이지만 sourcenode는 이 패턴에 최적화되어 있다.
      sourceNodeRef.current = audioCxt.createBufferSource();
      sourceNodeRef.current.buffer = newAudioBuffer;
      sourceNodeRef.current.connect(audioCxt.destination);
      let startOffset =
        typeof position === 'number'
          ? position
          : currentPosition >= newAudioBuffer.duration
          ? 0
          : currentPosition;

      if (startOffset < 0) {
        sourceNodeRef.current.start(
          -startOffset + audioCxt.currentTime,
          0,
          newAudioBuffer.duration
        );
      } else {
        const duration = newAudioBuffer.duration - startOffset;
        sourceNodeRef.current.start(0, startOffset, duration);
      }
      setStartPosition(audioCxt.currentTime);
      setPausedPosition(startOffset);
    },
    [resetSourceNode, currentPosition, load, audioBuffer]
  );

  const pause = useCallback(() => {
    if (!sourceNodeRef.current) return;
    sourceNodeRef.current.stop();
    setIsPlaying(false);
    setPausedPosition(currentPosition);
  }, [currentPosition]);

  const stop = useCallback(() => {
    resetSourceNode();
    setIsPlaying(false);
    setPausedPosition(0);
    requestAnimationFrame(() => {
      setCurrentPosition(0);
    });
  }, [resetSourceNode]);

  const updatecurrentPosition = useCallback(
    (time: number) => {
      // 재생중인 경우 해당 시간부터 재생
      if (isPlaying) {
        play(time);
      }
      // 재생중이 아닌 경우 currentPosition만 업데이트
      else {
        setCurrentPosition(time);
      }
    },
    [isPlaying, play]
  );

  // 페이지 전환이 일어나면 audio를 정지
  useEffect(() => {
    return () => {
      stop();
    };
  }, [stop]);

  return {
    load,
    reset,
    play,
    pause,
    stop,
    audioBuffer,
    isPlaying,
    currentPosition,
    updatecurrentPosition,
  };
};

export default useSingleAudioController;
