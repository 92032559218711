import { Grey, Primary } from '@/styles/Colors';
import styled from '@emotion/styled';

export const StyledUserInfo = styled.section`
  position: absolute;
  right: 1.75rem;
  top: 0.65rem;
  display: flex;
  align-items: center;
  .initial {
    border-radius: 50%;
    width: 2rem;
    height: 2rem;
    background-color: ${Primary[400]};
    text-align: center;
    line-height: 150%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
  }
  .btn-dropdown {
    margin-left: 0.5rem;
    svg {
      width: 0.75rem;
      height: 0.375rem;
    }
  }
`;

export const StyledUserLayer = styled.div`
  padding: 1rem 0.75rem 0.5rem;
  color: ${Grey[50]};
  z-index: 100;
  .info {
    text-align: center;
    white-space: nowrap;
    display: block;
    margin-bottom: 1rem;
    line-height: 100%;
  }
  .logout {
    width: 100%;
    min-width: 9.25rem;
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
    font-weight: bold;
  }
`;
