import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { Dropdown, DropdownItem, DropdownList } from '@/components/Dropdown';
import Record from '@/components/FileInput/Record';
import Slider from '@/components/Slider/Slider';
import Title from '@/components/Title/Title';
import classNames from 'classnames';
import { useCallback, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { tts } from '../api';
import { ReactComponent as GenerateIcon } from '../assets/icons/GenerateIcon.svg';
import { ReactComponent as SelectDropDownIcon } from '../assets/icons/SelectDropDownIcon.svg';
import { audioFileMapModel } from '../stores/audios';
import {
  Line,
  Take,
  Voice,
  lineListModel,
  lineParameterMapModel,
  lineParameterSelector,
  selectedLineIdSelector,
  selectedTakeIdModel,
  takeListModel,
  usedVoiceListSelector,
} from '../stores/project';
import { StyledMultiTakeDropDown } from '../styles/StyledDropDown';

export const SPEECH_CONTROL_LIST = [
  {
    id: 'pitch_shift',
    title: 'Pitch Shift',
    min: -24,
    max: 24,
    step: 1,
    defaultValue: 0,
  },
  {
    id: 'pitch_variance',
    title: 'Pitch Variance',
    min: 0,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
  {
    id: 'tempo',
    title: 'Speed',
    value: 1,
    min: 0.5,
    max: 2,
    step: 0.1,
    defaultValue: 1,
  },
];
const selectList = [1, 2, 3, 4];
const SpeechTab = () => {
  const { t } = useTranslation('screenPlay');
  const selectedLineId = useRecoilValue(selectedLineIdSelector);
  const lineParameters = useRecoilValue(lineParameterSelector(selectedLineId));
  const setLineParameters = useSetRecoilState(lineParameterMapModel);
  const onChangeLineParameters = useCallback(
    (key: string) => (value: number) => {
      if (selectedLineId) {
        setLineParameters((prev) => {
          return {
            ...prev,
            [selectedLineId]: { ...lineParameters, [key]: value },
          };
        });
      }
    },
    [setLineParameters, selectedLineId, lineParameters]
  );

  const [multiGenCount, setMultiGenCount] = useState(1);

  const selectedTakeId = useRecoilValue(selectedTakeIdModel);
  const isLineSelected = useMemo(() => {
    return !!selectedLineId && !selectedTakeId;
  }, [selectedLineId, selectedTakeId]);
  const isControlDisabled = useMemo(() => {
    return !isLineSelected;
  }, [isLineSelected]);
  const lineList = useRecoilValue(lineListModel);
  const hasText = useMemo(() => {
    return !!lineList?.find((line) => line.id === selectedLineId)?.text;
  }, [lineList, selectedLineId]);
  const ref = useRef<HTMLSpanElement>(null);
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const onClose = useCallback(() => {
    setIsDropdownOpen(false);
  }, [setIsDropdownOpen]);
  const [takeList, setTakeList] = useRecoilState(takeListModel);
  const usedVoiceList = useRecoilValue(usedVoiceListSelector);
  const setAudioFileMap = useSetRecoilState(audioFileMapModel);
  // todo separate to another file
  const [isGenerating, setIsGenerating] = useState(false);
  const addMultiTakes = useCallback(() => {
    const count = multiGenCount;
    const line = lineList.find((line) => line.id === selectedLineId);
    if (isGenerating) {
      return;
    }
    setIsGenerating(true);
    const voice = usedVoiceList.find((voice) => voice.id === line?.voiceId);
    const newTakeList = new Array(count).fill(0).map(() => {
      const newTakeId = uuid();
      return {
        id: newTakeId,
        type: 'tts',
        createdAt: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
        lineId: selectedLineId as string,
        isFetching: true,
        parameter: {
          pitch_shift: lineParameters.pitch_shift,
          pitch_variance: lineParameters.pitch_variance,
          speed: lineParameters.speed,
        },
      } as Take;
    });
    setTakeList((oldTakeList) => {
      const newTake = oldTakeList.concat(newTakeList);
      return newTake;
    });
    Promise.all(
      new Array(count).fill(0).map(async () => {
        return await tts(line as Line, voice as Voice, {
          pitch_shift: lineParameters.pitch_shift,
          pitch_variance: lineParameters.pitch_variance,
          speed: lineParameters.speed,
        });
      })
    ).then((res) => {
      setTakeList((prev) => {
        const newList = [...prev].slice(0, -newTakeList.length);
        return newList.concat(
          newTakeList.map((take, index) => ({
            ...take,
            isFetching: false,
            audioBuffer: res[index].audioBuffer as AudioBuffer,
          }))
        );
      });
      setAudioFileMap((prev) => {
        const newMap = { ...prev };
        newTakeList.forEach((take, index) => {
          newMap[take.id] = {
            audioBuffer: res[index].audioBuffer as AudioBuffer,
          };
        });
        return newMap;
      });
      setIsGenerating(false);
    });
  }, [
    isGenerating,
    setAudioFileMap,
    usedVoiceList,
    setTakeList,
    multiGenCount,
    lineList,
    selectedLineId,
    lineParameters.pitch_shift,
    lineParameters.pitch_variance,
    lineParameters.speed,
  ]);
  const { pitch_shift, pitch_variance, speed } = useMemo(() => {
    if (isLineSelected) {
      return lineParameters;
    } else {
      const selectedTake = takeList.find((take) => take.id === selectedTakeId);
      return (
        selectedTake?.parameter || {
          pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
          pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
          speed: SPEECH_CONTROL_LIST[2].defaultValue,
        }
      );
    }
  }, [lineParameters, isLineSelected, takeList, selectedTakeId]);

  const variantsMap: Record<
    string,
    { value: number; change: (value: number) => void }
  > = useMemo(() => {
    return {
      pitch_shift: {
        value: pitch_shift,
        change: onChangeLineParameters('pitch_shift'),
      },
      pitch_variance: {
        value: pitch_variance,
        change: onChangeLineParameters('pitch_variance'),
      },
      tempo: { value: speed, change: onChangeLineParameters('speed') },
    };
  }, [pitch_shift, pitch_variance, speed, onChangeLineParameters]);
  const controls = useMemo(() => {
    return SPEECH_CONTROL_LIST.map((item) => {
      const { value, change } = variantsMap[item.id];
      return {
        ...item,
        value,
        change,
      };
    });
  }, [variantsMap]);
  return (
    <section className="speech-tab">
      <section className="speech-section speech-control">
        <Title size="md" className="speech-section-title">
          {selectedTakeId ? t('Applied Values') : t('Speech Control')}
        </Title>
        <section className="speech-control-content">
          <ul>
            {controls.map((item, index) => (
              <li
                key={index}
                className={classNames(
                  'slider-control',
                  isControlDisabled && 'disabled'
                )}
              >
                <section className="slider-control-header">
                  <span className="slider-control-title">{item.title}</span>
                  <span
                    className={classNames(
                      'slider-value',
                      isControlDisabled && 'disabled'
                    )}
                  >
                    {item.value}
                  </span>
                </section>
                <span className="slider-control-content">
                  <span className="control-min">{item.min}</span>
                  <Slider
                    disabled={isControlDisabled}
                    min={item.min}
                    max={item.max}
                    value={item.value}
                    showMarks={false}
                    step={item.step}
                    defaultValue={item.defaultValue}
                    onChange={(value) => item.change(value)}
                  />
                  <span className="control-max">{item.max}</span>
                </span>
              </li>
            ))}
          </ul>
        </section>
      </section>
      {!selectedTakeId && (
        <>
          <section
            className={classNames(
              'speech-section number-of-takes',
              isControlDisabled && 'disabled'
            )}
          >
            <Title size="md" className="speech-section-title">
              {t('Number of Takes')}
            </Title>
            <span className="count-selector" ref={ref}>
              <span
                className="count-selector-value"
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                {multiGenCount}
              </span>
              <IconButton
                size="sm"
                color="transparent"
                className="btn-open-selector"
                disabled={isControlDisabled}
                onClick={() => setIsDropdownOpen(!isDropdownOpen)}
              >
                <SelectDropDownIcon />
              </IconButton>
            </span>
            <Dropdown
              isOpen={isDropdownOpen}
              onClose={onClose}
              preventRef={ref}
              closeOnClickOutside={true}
              placement="bottom-left"
              style={{
                padding: 0,
                border: 0,
                marginLeft: '-2.5rem',
                background: 'none',
              }}
            >
              <StyledMultiTakeDropDown>
                <DropdownList className="sp-drawer">
                  {selectList.map((value) => (
                    <DropdownItem
                      key={`dropdown_list_${value}`}
                      style={{ padding: '0.5rem 0.5rem', cursor: 'pointer' }}
                      label={value.toString()}
                      isSelected={value === multiGenCount}
                      onClick={() => {
                        setMultiGenCount(value);
                        onClose();
                      }}
                    />
                  ))}
                </DropdownList>
              </StyledMultiTakeDropDown>
            </Dropdown>
          </section>
          <section className="speech-section create-tasks">
            {isLineSelected ? (
              <Button
                disabled={!hasText}
                size="lg"
                className="btn-create-task"
                startIcon={<GenerateIcon />}
                onClick={addMultiTakes}
              >
                {t('Add New Takes')}
              </Button>
            ) : (
              !selectedTakeId && t('No Line or Take selected.')
            )}
          </section>
        </>
      )}
    </section>
  );
};
export default SpeechTab;
