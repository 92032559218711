import { audioBufferToWav } from '@/util/audio';
import JSZip from 'jszip';
interface ZipInfo {
  buffer: ArrayBuffer | Blob;
  name: string;
}
export const downloadZip = (list: ZipInfo[], name: string) => {
  if (!list.length) {
    return;
  }
  const zip = new JSZip();
  const nameSet = new Map<string, number>();
  list.forEach((file) => {
    const { name } = file;
    const count = nameSet.get(name) || 0;
    nameSet.set(name, count + 1);
    if (count) {
      const [, fileName, extension] = name.match(/(.*)\.(.*)/) || [
        undefined,
        name,
      ];
      let newName = !extension
        ? `${fileName}_(${count})`
        : `${fileName}_(${count}).${extension}`;
      zip.file(newName, file.buffer);
      return;
    }
    zip.file(file.name, file.buffer);
  });
  zip.generateAsync({ type: 'blob' }).then((content) => {
    const url = window.URL.createObjectURL(content);
    const a = document.createElement('a');
    a.href = url;
    a.download = `${name}.zip`;
    a.click();
  });
};
export const convertAudioToDownloadList = (
  list: AudioZipInfo[],
  noExtension: boolean = false
) => {
  return list.map((item) => {
    const wavBuffer = audioBufferToWav(item.audioBuffer);
    return {
      buffer: wavBuffer,
      name: noExtension ? item.fileName : `${item.fileName}.wav`,
    };
  });
};
const ZIP_NAME = 'AudioDownload.zip';
export interface AudioZipInfo {
  audioBuffer: AudioBuffer;
  fileName: string;
}
export const downloadAudio = async (
  list: AudioZipInfo[],
  zipName: string = ZIP_NAME,
  noExtension: boolean = false
) => {
  if (!list.length) {
    return;
  }
  const files = convertAudioToDownloadList(list, noExtension);
  downloadZip(files, zipName);
};
export const exportText = (text: string, name: string) => {
  const blob = new Blob([text], {
    type: 'text/plain;charset=utf-8',
  });
  const url = window.URL.createObjectURL(blob);
  const link = document.createElement('a');
  link.href = url;
  link.setAttribute('download', `${name}.txt`);
  link.click();
  return '';
};
interface YamlType {
  [key: string]: string | number | boolean | YamlType;
}
const objectToYaml = (obj: YamlType, indentation = 0) => {
  let yaml = '';
  for (let key in obj) {
    yaml += `${' '.repeat(indentation * 2)}${key}:`;

    if (typeof obj[key] === 'object') {
      yaml += `\n${objectToYaml(obj[key] as YamlType, indentation + 1)}`;
    } else {
      yaml += ` ${obj[key]}\n`;
    }
  }
  return yaml;
};
export const convertToYaml = (data: YamlType, name: string) => {
  const yamlStr = objectToYaml(data);
  const blob = new Blob([yamlStr], { type: 'application/x-yaml' });
  return { buffer: blob, name: `${name}.yaml` };
};
/**
 * @example
 *  downloadAudioWithYaml(audioFile, {
 *   "ko-nn": {
 *     "pitch_shift": -3,
 *     "pitch_variance": 1.1,
 *     "speed": 1.2,
 *     "similarity": 2.0
 *   }
 *  }, 'flint');
 */
export const downloadAudioWithYaml = async (
  audio: AudioZipInfo[],
  yamlData: YamlType,
  zipName: string = ZIP_NAME
) => {
  const files = convertAudioToDownloadList(audio);
  const yaml = convertToYaml(yamlData, zipName);
  downloadZip([...files, yaml], zipName);
};
