import { ModelType } from '@/pages/cvc/ModelControl/config';
import { MatrixModel } from '@/stores/cvc';
import axios, { AxiosResponse } from 'axios';

axios.defaults.baseURL = process.env.REACT_APP_API_HOST as string;
axios.defaults.headers.post['Content-Type'] = 'application/json';
export interface AudioMetadataProps {
  props: {
    duration: number;
    sampleRate: number;
    channels: number;
    bitDepth?: number;
    bitRate?: number;
    codec?: string;
  };
}
export interface ResourceResponseData {
  resource_id: string;
  purpose: string;
  upload_url: string;
  message: string;
}
export interface ResourceResponse {
  status: string;
  message: string;
  data: ResourceResponseData;
}
export interface ResourceFileResponse {
  status: string;
  message: string;
  data: {
    resource_id: string;
    name: string;
    original: {
      url: string;
      metadata: AudioMetadataProps;
    };
    transcoded: {
      url: string;
      metadata: AudioMetadataProps;
    }[];
  };
}
export interface JobIdResponse {
  status: string;
  message: string;
  data: {
    data: { job_ids: string[] };
  };
}
export const getUploadInfo = async (
  sessionId: string,
  name: string,
  type: string
) => {
  return (await axios.post(
    'resources',
    {
      name,
      content_type: type,
      purposes: ['playback'],
    },
    { headers: { 'x-session-id': sessionId } }
  )) as AxiosResponse<ResourceResponse>;
};
export const getResourceInfo = async (resource_id: string) => {
  return (await axios.get(
    `/resources/${resource_id}`
  )) as AxiosResponse<ResourceFileResponse>;
};

interface DataList {
  model_type: string;
  source_resource_id: string;
  target_resource_ids: string[];
  secondary_target_resource_ids: string[];
  parameters: { [key: string]: number | boolean };
}

export const postCvcSpeakerControl = async (
  modelType: ModelType,
  sessionId: string,
  sourceList: string[],
  parameters: { [key: string]: number | boolean }
) => {
  const { data } = (await axios.post(
    '/cvc',
    {
      data: sourceList.map((item) => {
        return {
          model_type: modelType,
          source_resource_id: item,
          target_resource_ids: [],
          secondary_target_resource_ids: [],
          parameters,
        };
      }),
    },
    {
      headers: {
        'X-Session-Id': sessionId,
      },
    }
  )) as JobIdResponse;
  return data.data.job_ids;
};
export const postCvcGenerate = async (
  modelType: ModelType,
  sessionId: string,
  sourceList: string[],
  targetList: (string | string[])[],
  parameters: { [key: string]: number | boolean }
) => {
  const reqData = sourceList.reduce((map, item) => {
    return map.concat(
      targetList.map((target) => {
        return {
          model_type: modelType,
          source_resource_id: item,
          target_resource_ids: typeof target === 'string' ? [target] : target,
          secondary_target_resource_ids: [],
          parameters,
        };
      })
    );
  }, [] as DataList[]);
  const { data } = (await axios.post(
    '/cvc',
    {
      data: reqData,
    },
    {
      headers: {
        'X-Session-Id': sessionId,
      },
    }
  )) as JobIdResponse;
  return data.data.job_ids;
};
export const postCvcInterpolationGenerate = async (
  modelType: string,
  sessionId: string,
  sourceList: string[],
  groupMap: { [key: string]: (string | string[])[] },
  parameters: { [key: string]: number | boolean }
) => {
  const { data } = (await axios.post(
    '/cvc',
    {
      data: sourceList.reduce((map, item) => {
        const { GroupA = [], GroupB = [] } = groupMap;
        let newMap = map;
        GroupA.forEach((targetA) => {
          newMap = newMap.concat(
            GroupB.map((targetB) => {
              return {
                model_type: modelType,
                source_resource_id: item,
                target_resource_ids:
                  typeof targetA === 'string' ? [targetA] : targetA,
                secondary_target_resource_ids:
                  typeof targetB === 'string' ? [targetB] : targetB,
                parameters,
              };
            })
          );
        });
        return newMap;
      }, [] as DataList[]),
    },
    {
      headers: {
        'X-Session-Id': sessionId,
      },
    }
  )) as JobIdResponse;
  return data.data.job_ids;
};

export const postCvcMatrixGenerate = async (
  modelType: string,
  sessionId: string,
  matrix: MatrixModel,
  parameters: { [key: string]: number | boolean }
) => {
  const { data } = (await axios.post(
    '/cvc',
    {
      data: Object.keys(matrix)
        .filter((m) => matrix[m])
        .map((key) => {
          const [source, target] = key.split(':');
          return {
            model_type: modelType,
            source_resource_id: source,
            target_resource_ids: [target],
            secondary_target_resource_ids: [],
            parameters,
          };
        }),
    },
    {
      headers: {
        'X-Session-Id': sessionId,
      },
    }
  )) as JobIdResponse;
  return data.data.job_ids;
};

export const postMssGenerate = async (
  sessionId: string,
  parameters: { [key: string]: number }[]
) => {
  const {
    data: { data },
  } = (await axios.post(
    '/mss',
    {
      data: parameters,
    },
    {
      headers: {
        'X-Session-Id': sessionId,
      },
    }
  )) as JobIdResponse;
  return data.job_ids;
};

export const upload = async (upload_url: string, file: File) => {
  await axios.put(upload_url as string, file, {
    headers: {
      'Content-Type': file.type,
      withCredentials: true,
    },
  });
};
