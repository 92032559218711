import ExpandIcon from '@/components/assets/icons/ExpandIcon.svg';
import ShrinkIcon from '@/components/assets/icons/ShrinkIcon.svg';
import Button from '@/components/Button/Button';
import { Dropdown } from '@/components/Dropdown';
import Title from '@/components/Title/Title';
import { languageMap } from '@/pages/screenplay/api';
import {
  AudioZipInfo,
  downloadAudioWithYaml,
} from '@/pages/screenplay/utils/files';
import { Black, Secondary, White } from '@/styles/Colors';
import { useCallback, useMemo, useState } from 'react';

import { ReactComponent as ToggleDownIcon } from '../components/assets/icons/ToggleDownIcon.svg';
import IconButton from '../components/IconButton';
import { useSourceResultContext } from '../SourceResultProvider';
import { defaultTTSParams } from '../TTSPanel/models';
import { useZeroShotContext } from '../ZeroShotContextProvider';
import { StyledSubTable } from '../ZeroShotSourcePanel/StyledSubTable';
import ZeroShotDownloadFileLayer from './DownloadFileLayer';
import StyledZeroShotResultDropDown from './StyledZeroShotResultDropDown';
import StyledZeroShotResultPanel from './StyledZeroShotResultPanel';
import ZeroShotResultGroupItem from './ZeroShotResultGroupItem';
import ZeroShotResultItem, { ResultItem } from './ZeroShotResultItem';

export type Language = 'ko' | 'en' | 'jp';
export const langLabelMap = {
  en: 'ENG',
  ko: 'KOR',
  jp: 'JPN',
};
const ZeroShotResultPanel = () => {
  const { selectedSource } = useZeroShotContext();
  const {
    resultMap,
    toggleItem,
    expandItems,
    setSelectedItemId,
    selectedItemMap,
    removeResult,
    clearSelectedMap,
  } = useSourceResultContext();
  const resultList = useMemo(
    () => resultMap[selectedSource?.id || ''] || [],
    [resultMap, selectedSource]
  );
  const [folderState, setFolderState] = useState<
    Record<Language, { isOpened: boolean }>
  >({
    ko: { isOpened: true },
    en: { isOpened: true },
    jp: { isOpened: true },
  });
  const expandedAll = useMemo(() => {
    return (
      Object.values(folderState).every((state) => state.isOpened) &&
      resultList.every((result) => result.isExpanded)
    );
  }, [folderState, resultList]);
  const expandAll = useCallback(() => {
    setFolderState((prev) => {
      const newState = {
        ...prev,
      };
      Object.keys(newState).forEach((key) => {
        newState[key as Language] = {
          ...newState[key as Language],
          isOpened: !expandedAll,
        };
      });
      return newState;
    });
    expandItems(
      resultList.map((result) => result.id),
      !expandedAll
    );
  }, [expandedAll, resultList, expandItems]);
  const toggleGroup = useCallback((id: string) => {
    setFolderState((prev) => {
      const lang = id.split('_')[1] as Language;
      return {
        ...prev,
        [lang]: { ...prev[lang], isOpened: !prev[lang].isOpened },
      };
    });
  }, []);
  const resultGroup = useMemo(() => {
    const group: Record<Language, ResultItem[]> = {
      en: [],
      ko: [],
      jp: [],
    };
    resultList.forEach((result) => {
      group[result.lang as Language].push(result);
    });
    return group;
  }, [resultList]);
  const select = useCallback(
    (id: string, lang: Language) => {
      setSelectedItemId(selectedSource?.id as string, id, lang);
    },
    [selectedSource, setSelectedItemId]
  );
  const [isOpened, setIsOpened] = useState(false);
  const selectedTakes = useCallback(
    (languages: Language[]) => {
      return languages.map((lang) => {
        const id = selectedItemMap[lang];
        const result = resultList.find((result) => result.id === id);
        return result as ResultItem;
      });
    },
    [resultList, selectedItemMap]
  );
  const download = useCallback(
    (fileName: string, languages: Language[]) => {
      // zip 파일 다운로드,
      // - source.wav
      // - fileName.yaml
      // - result_fileName_[locale].wav
      if (!selectedSource) return;
      const sourceInfo = {
        audioBuffer: selectedSource.audioBuffer,
        fileName: `source_${fileName}`,
      };
      const takes = selectedTakes(languages);
      const resultInfo = takes.map((result) => {
        return {
          audioBuffer: result.audioBuffer,
          fileName: `result_${fileName}_${result.lang}`,
        };
      });
      // parameters 는 takes 별로 반영되어 있는걸 가지고 와야함
      const paramMap = Object.keys(languageMap).reduce((acc, lang) => {
        return {
          ...acc,
          [languageMap[lang as Language]]:
            takes.find((take) => take.lang === lang)?.parameters ||
            defaultTTSParams,
        };
      }, {});
      downloadAudioWithYaml(
        [sourceInfo, ...resultInfo] as AudioZipInfo[],
        paramMap,
        fileName
      );
    },
    [selectedTakes, selectedSource]
  );
  const enabledLanguages = useMemo(() => {
    return Object.keys(languageMap).filter(
      (lang) => !!selectedItemMap?.[lang as Language]
    );
  }, [selectedItemMap]);

  const deleteFile = useCallback(
    (id: string) => {
      const result = resultList.find((result) => result.id === id);
      if (!result) return;
      const lang = result.lang;
      if (selectedItemMap[lang] === id) {
        setSelectedItemId(selectedSource?.id as string, '', lang);
      }
      removeResult(selectedSource?.id as string, id);
    },
    [
      resultList,
      selectedItemMap,
      selectedSource,
      setSelectedItemId,
      removeResult,
    ]
  );
  const clearAll = useCallback(() => {
    if (selectedSource) {
      clearSelectedMap(selectedSource.id);
      removeResult(selectedSource.id);
    }
  }, [selectedSource, clearSelectedMap, removeResult]);

  return (
    <StyledZeroShotResultPanel className="admin-grid-item zero-shot-result">
      <section className="zero-shot-result-header">
        <Title size="lg">RESULTS</Title>
        <section className="zero-shot-control">
          {!!resultList.length && (
            <Button color={Secondary[300]} onClick={clearAll}>
              Clear All
            </Button>
          )}
          <Dropdown
            isOpen={isOpened}
            width={'19.5rem'}
            anchorElement={
              <Button
                onClick={() => setIsOpened(!isOpened)}
                className="attach-button-primary"
                size="lg"
                disabled={!selectedSource || !resultList.length}
                endIcon={<ToggleDownIcon />}
              >
                Export
              </Button>
            }
            onClose={() => setIsOpened(false)}
            closeOnClickOutside={true}
            placement="bottom-right"
            style={{
              marginLeft: '0.5rem',
              marginTop: '-0.5rem',
              backgroundColor: Black,
            }}
          >
            <StyledZeroShotResultDropDown className="zero-shot-result-download">
              <ZeroShotDownloadFileLayer
                download={download}
                fileName={selectedSource?.name as string}
                languages={enabledLanguages as Language[]}
              />
            </StyledZeroShotResultDropDown>
          </Dropdown>
        </section>
      </section>
      <StyledSubTable className="sup-files">
        {!!resultList.length && (
          <section className="sup-files-header">
            <section />
            <section className="sup-files-name">TEXT</section>
            <section className="sup-files-length">LENGTH</section>
            <section className="sup-files-expand">
              <IconButton
                iconColor={White}
                onClick={expandAll}
                iconSize="1rem"
                icon={expandedAll ? ShrinkIcon : ExpandIcon}
              />
            </section>
          </section>
        )}
        <ul>
          {Object.keys(resultGroup).map((lang) => {
            if (!resultGroup[lang as Language].length) {
              return [];
            }
            const item = {
              id: `group_${lang}`,
              lang: lang as Language,
              name: lang,
              isOpened: folderState[lang as Language].isOpened,
            };
            const items = [
              <ZeroShotResultGroupItem
                key={`group_${lang}`}
                item={item}
                toggleGroup={toggleGroup}
              />,
            ];
            if (!item.isOpened) {
              return items;
            }
            resultGroup[lang as Language].forEach((result, index) => {
              items.push(
                <ZeroShotResultItem
                  index={index + 1}
                  key={result.id}
                  isSelected={selectedItemMap?.[result.lang] === result.id}
                  item={result}
                  select={select}
                  toggle={toggleItem}
                  deleteFile={deleteFile}
                />
              );
            });
            return items;
          })}
        </ul>
      </StyledSubTable>
    </StyledZeroShotResultPanel>
  );
};
export default ZeroShotResultPanel;
