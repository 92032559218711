import { atom, selector, selectorFamily } from 'recoil';

import { TakeType } from './project';
import { timelineListSelector } from './timeline';

export type AudioType = TakeType | 'audio';

export interface AudioPlayerItem {
  id: string;
  lineId?: string;
  takeLabel?: string;
  type: AudioType;
  content?: string;
  thumbnail?: string;
}

export interface AudioItemInfo {
  id: string;
  type: AudioType;
  lineId?: string;
}

export const currentPlaybackModel = atom<number>({
  key: 'currentPlaybackModel',
  default: 0,
});

export const currentPlayingAudioInfoListModel = atom<AudioItemInfo[]>({
  key: 'currentPlayingAudioInfoListModel',
  default: [],
});

export const currentPlayingAudioItemSelector = selector<AudioPlayerItem | null>(
  {
    key: 'currentPlayingAudioItemSelector',
    get: ({ get }) => {
      const [info] = get(currentPlayingAudioInfoListModel);
      const timelineList = get(timelineListSelector);
      if (!info || !timelineList.length) return null;

      for (const timeline of timelineList) {
        const item = timeline.items.find((item) => item.id === info.id);
        if (item) {
          return {
            id: item.id,
            type: item.type,
            lineId: item.lineId,
            content: item.content,
            takeLabel: item.takeLabel,
            thumbnail: timeline.voice.thumbnail,
          };
        }
      }

      return null;
    },
  }
);

export interface AudioFile {
  src?: string;
  audioBuffer: AudioBuffer;
  fileName?: string;
}

export interface AudioFileMap {
  [key: string]: AudioFile;
}

export const audioFileMapModel = atom<AudioFileMap>({
  key: 'audioFileMapModel',
  default: {},
});

interface AudioPlayerState {
  type: 'standalone' | 'timeline';
  takeId?: string;
  voiceId?: string;
  isPlaying: boolean;
}

export const audioPlayerStateModel = atom<AudioPlayerState | null>({
  key: 'audioPlayerStateModel',
  default: null,
});

export const isPlayingSelector = selectorFamily<boolean, string>({
  key: 'isPlayingSelector',
  get:
    (lineId) =>
    ({ get }) => {
      const state = get(audioPlayerStateModel);
      const currentPlayingAudioInfo = get(currentPlayingAudioInfoListModel);
      const playingLineIds = currentPlayingAudioInfo.map((info) => info.lineId);
      return (
        playingLineIds.indexOf(lineId) !== -1 &&
        !!state?.isPlaying &&
        state.type === 'timeline'
      );
    },
});

export interface Recorder {
  start: () => Promise<void>;
  stop: () => Promise<Blob>;
}
export const recorderModel = atom<Recorder | null>({
  key: 'recorderModel',
  default: null,
});

export const voiceFileMapModel = atom<Omit<AudioFileMap, 'fileName'>>({
  key: 'voiceFileMapModel',
  default: {},
});
