export const clamp = (value: number, min: number, max: number) =>
  Math.min(Math.max(value, min), max);

export const minMaxFromArray = (data: number[] | [number, number][]) => {
  let min = Infinity;
  let max = -Infinity;

  if (Array.isArray(data)) {
    for (const item of data) {
      if (Array.isArray(item)) {
        const [num1, num2] = item;
        min = Math.min(min, num1, num2);
        max = Math.max(max, num1, num2);
      } else {
        min = Math.min(min, item);
        max = Math.max(max, item);
      }
    }
  }

  return [min, max];
};
