import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import { Dropdown, DropdownItem, DropdownList } from '@/components/Dropdown';
import { Grey } from '@/styles/Colors';
import React, { useCallback, useContext, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import { ModalContext } from '../ScreenPlayPage';
// import { Line, lineListModel } from '../stores/project';
import { StyledScriptDropDown } from '../styles/StyledDropDown';
// import { useRecoilValue } from 'recoil';

const selectDocList = ['PDF', 'TXT', 'DOC'];
const selectAudioList = ['WAV', 'MP3', 'AIFF'];
const selectVideoList = ['MP4', 'MOV', 'AVI'];
const ScriptTab = () => {
  const { t } = useTranslation('screenPlay');
  const { setIsModalOpen } = useContext(ModalContext);
  const [isDocDropDownOpen, setIsDocDropDownOpen] = useState(false);
  const [isAudioDropdownOpen, setIsAudioDropdownOpen] = useState(false);
  const [isVideoDropdownOpen, setIsVideoDropdownOpen] = useState(false);
  const ref = useRef<HTMLSpanElement>(null);
  const refAudio = useRef<HTMLSpanElement>(null);
  const refVideo = useRef<HTMLSpanElement>(null);
  const onCloseDocDropDown = useCallback(() => {
    setIsDocDropDownOpen(false);
  }, [setIsDocDropDownOpen]);
  const onCloseAudioDropDown = useCallback(() => {
    setIsAudioDropdownOpen(false);
  }, [setIsAudioDropdownOpen]);
  const onCloseVideoDropDown = useCallback(() => {
    setIsVideoDropdownOpen(false);
  }, [setIsVideoDropdownOpen]);
  const [selectedDocType, setSelectedDocType] = useState(selectDocList[0]);
  const [selectedAudioType, setSelectedAudioType] = useState(
    selectAudioList[0]
  );
  const [selectedVideoType, setSelectedVideoType] = useState(
    selectVideoList[0]
  );
  const { addScriptLine } = useSessionStorage();
  const exportDummy = useCallback(() => {
    setIsModalOpen(true);
  }, [setIsModalOpen]);
  const importFile = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      let reader = new FileReader();
      reader.onloadend = function (evt: ProgressEvent<FileReader>) {
        if (evt.target?.readyState === FileReader.DONE) {
          let content = JSON.parse(evt.target.result as string);
          if (content?.length && Array.isArray(content)) {
            content?.length && addScriptLine(content);
          } else {
            exportDummy();
          }
        }
      };
      if (e.target.files) {
        const file = e.target.files[0];
        file && reader.readAsText(file);
      }
    },
    [addScriptLine, exportDummy]
  );
  // const lineList = useRecoilValue(lineListModel);
  // const exportFile = useCallback(() => {
  //   const exportLines = lineList.map((line: Line) => {
  //     return {
  //       ...line,
  //       text: line.text,
  //       voiceId: line.voiceId,
  //       position: line.position,
  //     };
  //   });
  //   const blob = new Blob([JSON.stringify(exportLines)], {
  //     type: 'text/plain;charset=utf-8',
  //   });
  //   const url = window.URL.createObjectURL(blob);
  //   const link = document.createElement('a');
  //   link.href = url;
  //   // scene name
  //   link.setAttribute('download', 'script.txt');
  //   document.body.appendChild(link);
  //   link.click();
  //   document.body.removeChild(link);
  // }, [lineList]);

  return (
    <section className="script-tab">
      <section className="file-convert">
        <label htmlFor="btn-import-file" className="btn-import-file">
          {t('Import File')}
          <input
            type="file"
            color="transparent"
            id="btn-import-file"
            accept=".txt"
            multiple={false}
            onChange={importFile}
          />
        </label>
      </section>
      <section className="file-convert">
        <span className="export-title">{t('Export Scene')}</span>
        <section className="export-format">
          <span>{t('File Format')}</span>
          <span className="format-selector" ref={ref}>
            <span
              className="format-selector-value"
              onClick={() => setIsDocDropDownOpen(!isDocDropDownOpen)}
            >
              {selectedDocType}
            </span>
            <IconButton
              size="sm"
              color="transparent"
              className="btn-open-selector"
              onClick={() => setIsDocDropDownOpen(!isDocDropDownOpen)}
            >
              <BottomArrow />
            </IconButton>
          </span>
          <Dropdown
            isOpen={isDocDropDownOpen}
            onClose={onCloseDocDropDown}
            closeOnClickOutside={true}
            placement="bottom-left"
            preventRef={ref}
            style={{
              padding: 0,
              border: 0,
              marginLeft: '-5rem',
              background: 'none',
            }}
          >
            <StyledScriptDropDown>
              <DropdownList className="sp-drawer">
                {selectDocList.map((label) => (
                  <DropdownItem
                    key={`dropdown_list_${label}`}
                    style={{ padding: '0.5rem 0.5rem', cursor: 'pointer' }}
                    label={label}
                    isSelected={label === selectedDocType}
                    onClick={() => {
                      setSelectedDocType(label);
                      onCloseDocDropDown();
                    }}
                  />
                ))}
              </DropdownList>
            </StyledScriptDropDown>
          </Dropdown>
        </section>
        <section className="export-btn">
          <Button
            size="lg"
            variant="outlined"
            className="btn-export"
            color={Grey[400]}
            onClick={() => exportDummy()}
          >
            {t('Export as Document')}
          </Button>
        </section>
        <section className="export-format">
          <span>{t('File Format')}</span>
          <span className="format-selector" ref={refAudio}>
            <span
              className="format-selector-value"
              onClick={() => setIsAudioDropdownOpen(!isAudioDropdownOpen)}
            >
              {selectedAudioType}
            </span>
            <IconButton
              size="sm"
              color="transparent"
              className="btn-open-selector"
              onClick={() => setIsAudioDropdownOpen(!isAudioDropdownOpen)}
            >
              <BottomArrow />
            </IconButton>
          </span>
          <Dropdown
            isOpen={isAudioDropdownOpen}
            onClose={onCloseAudioDropDown}
            closeOnClickOutside={true}
            preventRef={refAudio}
            placement="bottom-left"
            style={{
              padding: 0,
              border: 0,
              marginLeft: '-5rem',
              background: 'none',
            }}
          >
            <StyledScriptDropDown>
              <DropdownList className="sp-drawer">
                {selectAudioList.map((label) => (
                  <DropdownItem
                    key={`dropdown_list_${label}`}
                    style={{ padding: '0.5rem 0.5rem', cursor: 'pointer' }}
                    label={label}
                    isSelected={label === selectedAudioType}
                    onClick={() => {
                      setSelectedAudioType(label);
                      onCloseAudioDropDown();
                    }}
                  />
                ))}
              </DropdownList>
            </StyledScriptDropDown>
          </Dropdown>
        </section>
        <section className="export-btn">
          <Button
            size="lg"
            variant="outlined"
            className="btn-export"
            color={Grey[400]}
            onClick={exportDummy}
          >
            {t('Export as Audio')}
          </Button>
        </section>
        <section className="export-format">
          <span>{t('File Format')}</span>
          <span className="format-selector" ref={refVideo}>
            <span
              className="format-selector-value"
              onClick={() => setIsVideoDropdownOpen(!isVideoDropdownOpen)}
            >
              {selectedVideoType}
            </span>
            <IconButton
              size="sm"
              color="transparent"
              className="btn-open-selector"
              onClick={() => setIsVideoDropdownOpen(!isVideoDropdownOpen)}
            >
              <BottomArrow />
            </IconButton>
          </span>
          <Dropdown
            isOpen={isVideoDropdownOpen}
            onClose={onCloseVideoDropDown}
            closeOnClickOutside={true}
            placement="bottom-left"
            preventRef={refVideo}
            style={{
              padding: 0,
              border: 0,
              marginLeft: '-5rem',
              background: 'none',
            }}
          >
            <StyledScriptDropDown>
              <DropdownList className="sp-drawer">
                {selectVideoList.map((label) => (
                  <DropdownItem
                    key={`dropdown_list_${label}`}
                    style={{ padding: '0.5rem 0.5rem', cursor: 'pointer' }}
                    label={label}
                    isSelected={label === selectedVideoType}
                    onClick={() => {
                      setSelectedVideoType(label);
                      onCloseVideoDropDown();
                    }}
                  />
                ))}
              </DropdownList>
            </StyledScriptDropDown>
          </Dropdown>
        </section>
        <section className="export-btn">
          <Button
            size="lg"
            variant="outlined"
            className="btn-export"
            color={Grey[400]}
            onClick={exportDummy}
          >
            {t('Export as Video')}
          </Button>
        </section>
      </section>
    </section>
  );
};
export default ScriptTab;
