import { getResourceInfo } from '@/api';
import { WebSocketContext } from '@/providers/WebSocketProvider';
import { fetchAudio, getAudioBuffer } from '@/util/audio';
import { useCallback, useContext } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';
import { v4 as uuid } from 'uuid';

import { ttsForVoice } from '../../api';
import {
  getLines,
  getScenes,
  getTakes,
  getVoices,
  updateLine,
  updateScenes,
  updateTake,
  updateVoice,
} from '../../api/project';
import { audioFileMapModel, voiceFileMapModel } from '../../stores/audios';
import { VOICE_STORAGE_KEY } from '../../stores/data/voices';
import {
  Line,
  Scene,
  Take,
  Voice,
  enabledVoiceListSelector,
  lineListModel,
  projectLoadingModel,
  sceneListModel,
  selectedProjectIdModel,
  selectedSceneIdSelector,
  takeListModel,
  usedVoiceListSelector,
  voiceListModel,
} from '../../stores/project';

export interface Script {
  text: string;
  voiceId?: string;
  language?: string;
}
export const getNewLine = (line: Line) => {
  const newTakeId = uuid();
  const newLine = {
    id: uuid(),
    voiceId: line.voiceId,
    language: line.language,
    text: line.text || '',
    isGenerated: false,
    selectedTakeId: newTakeId,
  } as Line;
  const newTake = {
    id: newTakeId,
    type: 'tts',
    lineId: newLine.id,
    takeNumber: 1,
  } as Take;
  return [newLine, newTake];
};
const DEFAULT_SCRIPT =
  'Hello, this is project screenplay. Production by Supertone.';
const useSessionStorage = () => {
  const { sessionId } = useContext(WebSocketContext);
  const selectedProjectId = useRecoilValue(selectedProjectIdModel);
  const selectedSceneId = useRecoilValue(selectedSceneIdSelector);
  const [lineList, setLineList] = useRecoilState(lineListModel);
  const [takeList, setTakeList] = useRecoilState(takeListModel);
  const [voiceList, setVoiceList] = useRecoilState(voiceListModel);
  const enabledVoiceList = useRecoilValue(enabledVoiceListSelector);
  const setProjectLoading = useSetRecoilState(projectLoadingModel);
  const { t } = useTranslation('screenplay');
  // save scene before change scene, if project changed include this action
  const saveScene = useCallback(() => {
    updateLine(selectedProjectId, selectedSceneId as string, lineList);
    updateVoice(selectedProjectId, voiceList);
    lineList.forEach((line) => {
      updateTake(
        selectedProjectId,
        line.id,
        takeList.filter((take) => take.lineId === line.id)
      );
    });
  }, [lineList, selectedProjectId, selectedSceneId, takeList, voiceList]);
  const sceneList = useRecoilValue(sceneListModel);
  // save project for new or change project
  const saveProject = useCallback(() => {
    updateScenes(selectedProjectId, sceneList);
    saveScene();
  }, [saveScene, sceneList, selectedProjectId]);
  // load project for change project
  const [audioFileMap, setAudioFileMap] = useRecoilState(audioFileMapModel);
  const loadProject = useCallback(
    (projectId: string) => {
      setProjectLoading(true);
      const savedVoices = getVoices(projectId);
      setVoiceList(savedVoices);
      const sceneList = getScenes(projectId) as Scene[];
      // fixme 첫번째 씬을 기본 선택으로 가져간다. 이것도 Scene으로 이동시켜주는게 좋을지도
      const lineList = getLines(projectId, sceneList[0].id as string) as Line[];
      setLineList(lineList);
      let takes: Take[] = [];
      let count = 0;
      lineList.forEach((line) => {
        const lineTakes = getTakes(projectId, line.id);
        takes = takes.concat(lineTakes);
        lineTakes.forEach(async (take: Take) => {
          // take resource id가 있고 audioFileMap이 존재하지 않을 경우, generated 된 파일이 upload 되어 있는 것으로 판단하고 audioFileMap에 추가한다.
          if (take.resource_id && !audioFileMap[take.id]) {
            const res = await getResourceInfo(take.resource_id);
            const { arrayBuffer } = await fetchAudio(
              res.data.data.transcoded[0].url
            );
            const audioBuffer = await getAudioBuffer(arrayBuffer);
            setAudioFileMap((prev) => {
              return {
                ...prev,
                [take.id]: {
                  src: res.data.data.transcoded[0].url,
                  audioBuffer: audioBuffer as AudioBuffer,
                },
              };
            });
            count++;
          } else {
            count++;
          }
          if (count === takes.length) {
            setProjectLoading(false);
          }
        });
      });
      setTakeList(takes);
    },
    [
      setVoiceList,
      setLineList,
      setTakeList,
      audioFileMap,
      setAudioFileMap,
      setProjectLoading,
    ]
  );

  // add new scene for create scene
  const addNewSceneObject = useCallback(
    (projectId: string) => {
      const newScene = {
        id: uuid(),
        name: t('New Scene') as string,
        createdAt: new Date().toISOString(),
        updatedAt: undefined,
      };
      const newLineId = uuid();
      const newTakeId = uuid();
      const newLine = {
        id: newLineId,
        name: t('New Line') as string,
        voiceId: enabledVoiceList[0]?.id,
        language: enabledVoiceList[0]?.language,
        isGenerated: false,
        text: '',
        selectedTakeId: newTakeId,
      } as Line;
      const newTake = {
        id: newTakeId,
        type: 'tts',
        name: t('New Take') as string,
        lineId: newLine.id,
        takeNumber: 1,
      } as Take;
      // setup scene lines
      updateLine(projectId, newScene.id, [newLine]);
      updateTake(projectId, newLine.id, [newTake]);
      return newScene;
    },
    [t, enabledVoiceList]
  );
  // add new project for create project
  const addNewSet = useCallback(
    (projectId: string) => {
      const newProject = {
        id: projectId,
        name: t('New Project') as string,
        description: '',
        initialized: false,
        createdAt: new Date().toISOString(),
        updatedAt: undefined,
      };
      const newScene = addNewSceneObject(projectId);
      updateScenes(projectId, [newScene]);
      updateVoice(projectId, enabledVoiceList.slice(0, 1) as Voice[]);
      return newProject;
    },
    [addNewSceneObject, t, enabledVoiceList]
  );
  const usedVoice = useRecoilValue(usedVoiceListSelector);
  const addEmptyLine = useCallback(
    (
      line: Line = {
        voiceId: usedVoice[0].id,
        language: usedVoice[0].language,
      } as Line
    ) => {
      const [newLine, newTake] = getNewLine({ ...line, text: '' } as Line);
      setTakeList((prev) => prev.concat(newTake as Take));
      setLineList((prev) => {
        let lines = [...prev];
        if (lines.length === 0) {
          lines = lines.concat({
            ...(newLine as Line),
            voiceId: usedVoice[0].id,
          });
          return lines;
        }
        const index = lines.findIndex((l) => l.id === line?.id);
        let newLines;
        if (index === -1) {
          newLines = lines.concat({
            ...(newLine as Line),
            voiceId: lines[lines.length - 1].voiceId,
          });
        } else {
          newLines = lines.slice(0, index + 1).concat({
            ...(newLine as Line),
          });
          if (index < lines.length - 1) {
            newLines = newLines.concat(lines.slice(index + 1));
          }
        }
        return newLines;
      });
      return newLine as Line;
    },
    [setLineList, setTakeList, usedVoice]
  );
  const addScriptLine = useCallback(
    (scripts: Script[]) => {
      let newLines: Line[] = [];
      let newTakes: Take[] = [];
      scripts.forEach((script) => {
        const [newLine, newTake] = getNewLine({
          text: script.text,
          voiceId: script.voiceId || usedVoice[0].id,
          language: script.language || usedVoice[0].language,
        } as Line);
        newLines.push(newLine as Line);
        newTakes.push(newTake as Take);
      });
      setLineList((prev) => prev.concat(newLines));
      setTakeList((prev) => prev.concat(newTakes));
    },
    [setLineList, setTakeList, usedVoice]
  );

  const loadScene = useCallback(
    (projectId: string, sceneId: string) => {
      const lineList = getLines(projectId, sceneId) as Line[];
      setLineList(lineList);
      let takes: Take[] = [];
      lineList.forEach((line) => {
        takes = takes.concat(getTakes(projectId, line.id));
      });
      setTakeList(takes);
    },
    [setLineList, setTakeList]
  );

  const [voiceFileMap, setVoiceFileMap] = useRecoilState(voiceFileMapModel);

  const loadVoiceFile = useCallback(
    async (voice: Voice, params: { age: number; gender: number }) => {
      let voiceResourceMap = JSON.parse(
        sessionStorage.getItem(VOICE_STORAGE_KEY) || '{}'
      );
      const storageId = `V-${voice.id}_A-${params.age}_G-${params.gender}`;
      // 이미 voiceFileMap 에 있는 경우 바로 리턴
      if (voiceFileMap[storageId]) {
        return voiceFileMap[storageId];
      } else {
        // resourceId를 가지고 있는 경우, 서버에서 가져오기 시작
        if (voiceResourceMap[storageId]) {
          const { data } = await getResourceInfo(voiceResourceMap[storageId]);
          const audio = await fetchAudio(data.data.transcoded[0].url);
          const audioBuffer = await getAudioBuffer(audio.arrayBuffer);
          setVoiceFileMap((prev) => {
            return {
              ...prev,
              [storageId]: {
                audioBuffer: audioBuffer as AudioBuffer,
              },
            };
          });
          return { audioBuffer: audioBuffer as AudioBuffer };
        } else {
          // 아예 처음 불러오는 경우 tts를 통해 가져오기 시작
          const voiceTTS = await ttsForVoice(
            { ...voice, age: params.age, gender: params.gender } as Voice,
            voice.script || DEFAULT_SCRIPT,
            sessionId
          );
          // 가져온 데이터는 audioBuffer로 바로 sessionStorage에 저장
          sessionStorage.setItem(
            VOICE_STORAGE_KEY,
            JSON.stringify({
              ...voiceResourceMap,
              [storageId]: voiceTTS.resource_id,
            })
          );
          // voiceFileMap에도 저장
          setVoiceFileMap((prev) => {
            return {
              ...prev,
              [storageId]: {
                audioBuffer: voiceTTS.audioBuffer as AudioBuffer,
              },
            };
          });
          return { audioBuffer: voiceTTS.audioBuffer as AudioBuffer };
        }
      }
    },
    [sessionId, setVoiceFileMap, voiceFileMap]
  );

  return {
    addEmptyLine,
    addScriptLine,
    saveScene,
    saveProject,
    loadProject,
    addNewSet,
    addNewSceneObject,
    loadScene,
    loadVoiceFile,
  };
};
export default useSessionStorage;
