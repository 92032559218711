import {
  createContext,
  useCallback,
  useContext,
  useMemo,
  useState,
} from 'react';

import { Language } from '../../api';
import { useZeroShotContext } from './ZeroShotContextProvider';
import { ResultItem } from './ZeroShotResultPanel/ZeroShotResultItem';

export const SourceResultContext = createContext({
  resultMap: {} as Record<string, ResultItem[]>,
  addResult: (sourceId: string, result: ResultItem[]) => {},
  removeResult: (sourceId: string, id?: string) => {},
  toggleItem: (id: string) => {},
  expandItems: (ids: string[], isExpanded: boolean) => {},
  setSelectedItemId: (sourceId: string, id: string, lang: Language) => {},
  clearSelectedMap: (sourceId: string) => {},
  selectedItemMap: {} as {
    [key in Language]: string;
  },
});

export const useSourceResultContext = () => useContext(SourceResultContext);

const SourceResultContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const { selectedSource } = useZeroShotContext();
  const sourceId = useMemo(
    () => selectedSource?.id as string,
    [selectedSource]
  );
  const [resultMap, setResultMap] = useState<Record<string, ResultItem[]>>({});
  const addResult = useCallback((sourceId: string, result: ResultItem[]) => {
    setResultMap((prev) => {
      const newMap = {
        ...prev,
      };
      if (!newMap[sourceId]) {
        newMap[sourceId] = [];
      }
      newMap[sourceId] = result.concat(newMap[sourceId]);
      return newMap;
    });
  }, []);
  const removeResult = useCallback((sourceId: string, id?: string) => {
    if (!id) {
      setResultMap((prev) => {
        const newMap = {
          ...prev,
        };
        newMap[sourceId] = [];
        return newMap;
      });
      return;
    }
    setResultMap((prev) => {
      const newMap = {
        ...prev,
      };
      newMap[sourceId] = newMap[sourceId]?.filter((item) => item.id !== id);
      return newMap;
    });
  }, []);
  const toggleItem = useCallback(
    (id: string) => {
      setResultMap((prev) => {
        const newMap = {
          ...prev,
        };
        newMap[sourceId] = newMap[sourceId]?.map((item) => {
          if (item.id === id) {
            return {
              ...item,
              isExpanded: !item.isExpanded,
            };
          }
          return item;
        });
        return newMap;
      });
    },
    [sourceId]
  );
  const expandItems = useCallback(
    (ids: string[], isExpanded: boolean) => {
      setResultMap((prev) => {
        const newMap = {
          ...prev,
        };
        newMap[sourceId] = newMap[sourceId]?.map((item) => {
          if (ids.includes(item.id)) {
            return {
              ...item,
              isExpanded,
            };
          }
          return item;
        });
        return newMap;
      });
    },
    [sourceId]
  );

  const [selectionMap, setSelectionMap] = useState<
    Record<
      string,
      {
        [key in Language]: string;
      }
    >
  >({});
  const setSelectedItemId = useCallback(
    (sourceId: string, id: string, lang: Language) => {
      setSelectionMap((prev) => {
        const newMap = {
          ...prev,
        };
        if (!newMap[sourceId]) {
          newMap[sourceId] = {
            en: '',
            ko: '',
            jp: '',
          };
        }
        newMap[sourceId][lang] = id;
        return newMap;
      });
    },
    [setSelectionMap]
  );

  const clearSelectedMap = useCallback((sourceId: string) => {
    setSelectionMap((prev) => {
      const newMap = {
        ...prev,
      };
      newMap[sourceId] = {
        en: '',
        ko: '',
        jp: '',
      };
      return newMap;
    });
  }, []);

  const selectedItemMap = useMemo(() => {
    return { ...selectionMap[sourceId] };
  }, [selectionMap, sourceId]);

  return (
    <SourceResultContext.Provider
      value={{
        resultMap: resultMap,
        addResult,
        toggleItem,
        expandItems,
        setSelectedItemId,
        selectedItemMap,
        removeResult,
        clearSelectedMap,
      }}
    >
      {children}
    </SourceResultContext.Provider>
  );
};

export default SourceResultContextProvider;
