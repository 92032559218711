import { ReactComponent as CompanyLogo } from '@/assets/images/SupertoneLogo.svg';
import { ReactComponent as WorkLogo } from '@/assets/images/Work.svg';
// import { ReactComponent as ProductLogo } from '@/assets/images/VoiceToolkit.svg';
import { ReactComponent as ScreenplayLogo } from '@/pages/screenplay/assets/images/Logo.svg';

import { StyledBrandWrapper } from './styled/StyledLanding';

const BrandWrapper = () => (
  <StyledBrandWrapper>
    <CompanyLogo className="logo-company" />
    {/* <ProductLogo className="logo-product" /> */}
    <ScreenplayLogo className="logo-product-screenplay" />
    <WorkLogo className="logo-work" />
  </StyledBrandWrapper>
);

export default BrandWrapper;
