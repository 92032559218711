import { BasicFontSize } from '../../../../styles/Typography';

export const X_AXIS_HEIGHT = 3 * BasicFontSize;
export const LEFT_OFFSET = 5 * BasicFontSize;
export const COLUMN_HEIGHT = 3 * BasicFontSize;
export const BLOCK_HEIGHT = 2.25 * BasicFontSize;

// seconds
export const DEFAULT_VIEWPORT_RANGE: [number, number] = [0, 60];
// seconds, 15 minutes
export const DEFAULT_TIME_RANGE: [number, number] = [0, 60 * 15];
