import { ScaleLinear } from 'd3-scale';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useSetRecoilState } from 'recoil';

import { ReactComponent as MarkerIcon } from '../../assets/icons/MarkerIcon.svg';
import { timelinePlaybackModel } from '../../stores/timeline';
import { LEFT_OFFSET } from './const';

interface IndicatorProps {
  xScale: ScaleLinear<number, number>;
  currentPlayback: number;
  height: number;
}

const MIN_INDICATOR_OFFSET = 5;

const Indicator = ({ xScale, currentPlayback, height }: IndicatorProps) => {
  const indicatorRef = useRef<HTMLDivElement>(null);
  const [indicatorPlayback, setIndicatorPlayback] = useState<number>(0);
  const setTimelinePlayback = useSetRecoilState(timelinePlaybackModel);

  const updateIndicatorPosition = useCallback(
    (time: number) => {
      if (typeof time !== 'number') return;
      const position = xScale(time) + LEFT_OFFSET;
      const indicator = indicatorRef.current;
      if (!indicator) return;
      if (position < LEFT_OFFSET) {
        indicator.style.display = 'none';
      } else {
        indicator.style.display = 'block';
      }
      indicator.style.transform = `translate(${position}px, 0)`;
    },
    [xScale]
  );

  const getPlayback = useCallback(
    (e: PointerEvent | MouseEvent) => {
      const x = e.clientX;
      const position = x - LEFT_OFFSET;
      const playback = xScale.invert(position);
      if (playback < 0) return 0;
      return playback;
    },
    [xScale]
  );

  const handlePointerDown = useCallback(
    (e: React.PointerEvent) => {
      e.preventDefault();
      document.body.style.cursor = 'pointer';

      const onPointerMove = (e: PointerEvent) => {
        const playback = getPlayback(e);
        setIndicatorPlayback(
          playback < xScale.invert(MIN_INDICATOR_OFFSET) ? 0 : playback
        );
      };

      const onPointerUp = (e: PointerEvent) => {
        e.stopPropagation();
        const playback = getPlayback(e);
        document.body.style.cursor = 'default';
        setTimelinePlayback(playback);
        window.removeEventListener('pointermove', onPointerMove);
        window.removeEventListener('pointerup', onPointerUp);
        window.addEventListener('pointerleave', onPointerUp);
      };

      window.addEventListener('pointermove', onPointerMove);
      window.addEventListener('pointerup', onPointerUp);
      window.addEventListener('pointerleave', onPointerUp);
    },
    [setTimelinePlayback, getPlayback, xScale]
  );

  useEffect(() => {
    if (typeof indicatorPlayback !== 'number') return;
    updateIndicatorPosition(indicatorPlayback);
  }, [indicatorPlayback, updateIndicatorPosition]);

  useEffect(() => {
    setIndicatorPlayback(currentPlayback);
  }, [currentPlayback]);

  return (
    <div
      className="timeline-indicator"
      ref={indicatorRef}
      onPointerDown={handlePointerDown}
    >
      <MarkerIcon />
      <div
        className="line"
        style={{
          height: `calc(${height}px - 3rem)`,
        }}
      />
    </div>
  );
};

export default Indicator;
