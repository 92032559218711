import classNames from 'classnames';

import { BasicFontSize } from '../../../../styles/Typography';
import { ReactComponent as NoteIcon } from '../../assets/icons/NoteIcon.svg';
import { AudioType } from '../../stores/audios';

export interface Block {
  id: string;
  type: AudioType;
  content?: string;
  startX: number;
  endX: number;
  startY: number;
  endY: number;
  disabled?: boolean;
  isPlaying: boolean;
}

interface TimelineBlockProps {
  data: Block;
  isDraggable?: boolean;
  dragging?: boolean;
  dimmed?: boolean;
  hide?: boolean;
}

const TimelineBlock = ({
  data,
  isDraggable,
  dragging,
  dimmed,
  hide,
}: TimelineBlockProps) => {
  return (
    <div
      className={classNames('block', {
        active: data.isPlaying,
        audio: data.type === 'audio',
        disabled: data.disabled,
        dragging,
        'not-draggable': !isDraggable,
        hide,
        dimmed,
      })}
      id={data.id}
      style={{
        width: `${data.endX - data.startX}px`,
        height: `${data.endY - data.startY}px`,
        transform: `translate(${data.startX}px, ${data.startY}px)`,
        padding: `calc(0.64rem - 1px) ${
          data.endX - data.startX < 1.5 * BasicFontSize ? '0' : '0.75rem'
        }`,
      }}
    >
      <span title={data.content} className="block-text">
        {data.type === 'audio' && <NoteIcon />}
        {data.content}
      </span>
    </div>
  );
};

export default TimelineBlock;
