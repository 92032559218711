import classNames from 'classnames';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue } from 'recoil';
import { v4 as uuid } from 'uuid';

import ButtonGroup from '../../../components/Button/ButtonGroup';
import IconButton from '../../../components/Button/IconButton';
import Title from '../../../components/Title/Title';
import { getAudioContext } from '../../../util/audio';
import { ReactComponent as BackwardIcon } from '../assets/icons/media/BackwardIcon.svg';
import { ReactComponent as FolderIcon } from '../assets/icons/media/FolderIcon.svg';
import { ReactComponent as ForwardIcon } from '../assets/icons/media/ForwardIcon.svg';
import { ReactComponent as PauseIcon } from '../assets/icons/media/PauseIcon.svg';
import { ReactComponent as PlayIcon } from '../assets/icons/media/PlayIcon.svg';
import { ReactComponent as SettingIcon } from '../assets/icons/media/SettingIcon.svg';
import { ReactComponent as MuteIcon } from '../assets/icons/MuteIcon.svg';
import { ReactComponent as VolumeIcon } from '../assets/icons/VolumeIcon.svg';
import { currentPlaybackModel } from '../stores/audios';
import { videoPlayerStateModel } from '../stores/video';
import { StyledMediaPanel } from '../styles/StyledMediaPanel';

interface VideoItem {
  id: string;
  name: string;
  format: string;
  src: string;
}

const BACKWARD_TIME = 5;
const FORWARD_TIME = 5;

const MediaPanel = () => {
  const { t } = useTranslation('screenPlay');
  const [selectedId, setSelectedId] = useState<string | null>(null);
  const [isMuted, setIsMuted] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);
  const [currentPosition, setCurrentPosition] = useState<number>(0);
  const rafIdRef = useRef<number | null>(null);

  const audioCxtRef = useRef<AudioContext | null>(null);
  const mediaElementSourceRef = useRef<MediaElementAudioSourceNode | null>(
    null
  );

  const videoRef = useRef<HTMLVideoElement | null>(null);
  const [videoPlayerState, setVideoPlayerState] = useRecoilState(
    videoPlayerStateModel
  );
  const currentAudioPlayback = useRecoilValue(currentPlaybackModel);

  const [videoList, setVideoList] = useState<VideoItem[]>([]);

  const progressWidth = useMemo(() => {
    if (!videoRef.current) return 0;
    return Math.min((currentPosition / videoRef.current.duration) * 100, 100);
  }, [currentPosition]);

  const handleImportVideo = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      const file = e.target.files?.[0];
      if (!file) return;
      const videoURL = URL.createObjectURL(file);
      const videoItem: VideoItem = {
        id: uuid(),
        name: file.name,
        format: file.name.split('.').pop() as string,
        src: videoURL,
      };
      setVideoList((prev) => [...prev, videoItem]);
      setSelectedId(videoItem.id);
    },
    []
  );

  const handleSelectVideo = useCallback((id: string) => {
    setSelectedId(id);
  }, []);

  const play = useCallback((position?: number) => {
    if (!videoRef.current) return;
    if (typeof position === 'number') {
      videoRef.current.currentTime = position;
    }
    videoRef.current.play();
  }, []);

  const pause = useCallback(() => {
    if (!videoRef.current) return;
    videoRef.current.pause();
  }, []);

  const handleVideoPlay = useCallback(
    (position?: number) => {
      if (!videoRef.current) return;
      if (videoRef.current.paused) {
        play(position);
      } else {
        pause();
      }
      setIsPlaying(!videoRef.current.paused);
    },
    [play, pause]
  );

  const handleBackward = useCallback(() => {
    if (!videoRef.current) return;

    const currentTime = videoRef.current.currentTime;
    const position =
      currentTime - BACKWARD_TIME < 0 ? 0 : currentTime - BACKWARD_TIME;
    if (videoRef.current.paused) {
      pause();
    }

    videoRef.current.currentTime = position;
  }, [pause]);

  const handleForward = useCallback(() => {
    if (!videoRef.current) return;

    const currentTime = videoRef.current.currentTime;
    const position =
      currentTime + FORWARD_TIME > videoRef.current.duration
        ? videoRef.current.duration
        : currentTime + FORWARD_TIME;
    if (videoRef.current.paused) {
      pause();
    }
    videoRef.current.currentTime = position;
  }, [pause]);

  const handleMute = useCallback(() => {
    if (!videoRef.current) return;
    videoRef.current.muted = !videoRef.current.muted;
    setIsMuted(videoRef.current.muted);
  }, []);

  const seek = useCallback(
    (position: number) => {
      if (!videoRef.current) return;
      pause();

      if (position > videoRef.current.duration) {
        position = videoRef.current.duration;
      }
      setCurrentPosition(position);
      videoRef.current.currentTime = position;
    },
    [setCurrentPosition, pause]
  );

  const handleVideoEnd = useCallback(() => {
    setIsPlaying(false);
  }, []);

  useEffect(() => {
    return () => {
      videoList.forEach((video) => {
        if (typeof video.src === 'string') {
          URL.revokeObjectURL(video.src);
        }
      });
    };
  }, [videoList]);

  useEffect(() => {
    if (!videoRef.current || !selectedId) return;
    audioCxtRef.current = getAudioContext();
    if (!mediaElementSourceRef.current) {
      mediaElementSourceRef.current =
        audioCxtRef.current.createMediaElementSource(videoRef.current);
    }
    mediaElementSourceRef.current.connect(audioCxtRef.current.destination);

    return () => {
      mediaElementSourceRef.current &&
        mediaElementSourceRef.current.disconnect();
    };
  }, [selectedId]);

  useEffect(() => {
    if (!videoRef.current || !videoPlayerState) return;
    if (
      videoPlayerState.isPlaying &&
      videoPlayerState.startPosition <= videoRef.current.duration
    ) {
      play(videoPlayerState.startPosition || 0);
    } else {
      seek(videoPlayerState.startPosition);
    }
  }, [videoPlayerState, play, seek]);

  useEffect(() => {
    return () => {
      setVideoPlayerState(null);
      pause();
    };
  }, [setVideoPlayerState, pause]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (!isPlaying) {
      rafIdRef.current && cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
      return;
    }
    const updateProgress = () => {
      if (!videoRef.current) return;
      setCurrentPosition(videoRef.current?.currentTime);
      rafIdRef.current = requestAnimationFrame(updateProgress);
    };
    rafIdRef.current = requestAnimationFrame(updateProgress);
    return () => {
      rafIdRef.current && cancelAnimationFrame(rafIdRef.current);
    };
  }, [isPlaying]);

  useEffect(() => {
    if (!videoRef.current) return;
    if (!videoPlayerState?.isPlaying) {
      seek(currentAudioPlayback);
      rafIdRef.current && cancelAnimationFrame(rafIdRef.current);
      rafIdRef.current = null;
      return;
    }
    const updateProgress = () => {
      setCurrentPosition(currentAudioPlayback);
      rafIdRef.current = requestAnimationFrame(updateProgress);
    };
    rafIdRef.current = requestAnimationFrame(updateProgress);
    return () => {
      rafIdRef.current && cancelAnimationFrame(rafIdRef.current);
    };
  }, [videoPlayerState, currentAudioPlayback, seek]);

  return (
    <StyledMediaPanel className="sp-media">
      <section className="sp-media-player">
        <Title size="lg">{t('Media')}</Title>
        <div className="video">
          {!selectedId && <p>{t('No imported video files yet')}.</p>}
          {selectedId && (
            <>
              <video
                src={
                  videoList.find((video) => video.id === selectedId)
                    ?.src as string
                }
                ref={videoRef}
                onEnded={handleVideoEnd}
              />
              <div
                className="video-progress"
                style={{
                  width: `${progressWidth}%`,
                }}
              ></div>
            </>
          )}
        </div>
        <div className="controls">
          <label className="input-file" htmlFor="input-video">
            <input
              id="input-video"
              type="file"
              accept="video/*"
              hidden
              onChange={handleImportVideo}
              disabled={videoPlayerState?.isPlaying}
            />
            <FolderIcon />
          </label>
          <ButtonGroup>
            <IconButton
              variant="none"
              disabled={!selectedId || videoPlayerState?.isPlaying}
              onClick={() => handleVideoPlay()}
            >
              {isPlaying ? <PauseIcon /> : <PlayIcon />}
            </IconButton>
            <IconButton
              variant="none"
              disabled={!selectedId || videoPlayerState?.isPlaying}
              onClick={handleBackward}
              className="btn-backward"
              isFillCurrentColor={false}
            >
              <BackwardIcon />
            </IconButton>
            <IconButton
              variant="none"
              disabled={!selectedId || videoPlayerState?.isPlaying}
              onClick={handleForward}
              className="btn-forward"
              isFillCurrentColor={false}
            >
              <ForwardIcon />
            </IconButton>
          </ButtonGroup>
          <ButtonGroup>
            <IconButton variant="none" disabled>
              <SettingIcon />
            </IconButton>
            <IconButton
              variant="none"
              disabled={!selectedId}
              onClick={handleMute}
            >
              {isMuted ? <MuteIcon /> : <VolumeIcon />}
            </IconButton>
          </ButtonGroup>
        </div>
      </section>
      <section className="sp-media-library">
        <Title size="lg">{t('Media Library')}</Title>
        <section className="file-list">
          <section className="file-list-header">
            <span>{t('File Name')}</span>
            <span>{t('Format')}</span>
          </section>
          <section className="file-list-items">
            <ul>
              {videoList.map((video) => (
                <li
                  key={video.name}
                  className={classNames({
                    active: selectedId === video.id,
                  })}
                >
                  <button
                    type="button"
                    onClick={() => handleSelectVideo(video.id)}
                  >
                    <span>{video.name}</span>
                    <span className="item-format">{video.format}</span>
                  </button>
                </li>
              ))}
            </ul>
          </section>
        </section>
      </section>
    </StyledMediaPanel>
  );
};

export default MediaPanel;
