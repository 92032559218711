import { Black, Grey, Primary } from '@/styles/Colors';
import { FontSize, FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import FileExportIcon from '../assets/icons/FileExportIcon.svg';
import FileImportIconHover from '../assets/icons/FileImportIcon_hover.svg';
import FileImportIcon from '../assets/icons/FileImportIcon.svg';

const StyledControlPanel = styled.section`
  box-shadow: inset 0px -1rem 2.25rem 0px rgba(0, 0, 0, 0.24);
  .script-tab {
    .file-convert {
      padding: 1.25rem;
      border-bottom: 1px solid ${Grey[500]};
      .export-title {
        color: ${Grey[200]};
        ::before {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 1.375rem;
          height: 1.5rem;
          margin-right: 0.85rem;
          background-image: url(${FileExportIcon});
          background-size: cover;
        }
      }
      .btn-import-file {
        color: ${Grey[200]};
        cursor: pointer;
        input {
          visibility: hidden;
          position: absolute;
          width: 0;
          height: 0;
        }
        &:hover {
          color: ${Grey[50]};
          ::before {
            background-image: url(${FileImportIconHover});
          }
        }
        ::before {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 1.375rem;
          height: 1.5rem;
          margin-right: 0.85rem;
          background-image: url(${FileImportIcon});
          background-size: cover;
        }
      }
      .export-format {
        display: flex;
        justify-content: space-between;
        padding: 1.875rem 2.5rem 0 2.25rem;
        .format-selector {
          display: inline-flex;
          align-items: center;
          gap: 0.5rem;
        }
        .format-selector-value {
          display: flex;
          width: 3.5rem;
          height: 1.5rem;
          flex-direction: column;
          justify-content: center;
          flex-shrink: 0;
          color: ${Grey[50]};
          text-align: center;
          border-radius: 0.25rem;
          background: ${Grey[800]};
          font-family: Inter;
          font-size: 1rem;
          font-style: normal;
          font-weight: 400;
          line-height: 150%;
        }
      }
      .export-btn {
        padding: 1.875rem 2.5rem 0 2.25rem;
        .btn-export {
          padding: 0.5rem;
          width: 100%;
        }
      }
    }
  }
  .speech-section {
    padding: 1.35rem 1.25rem 0;
    border-bottom: 1px solid ${Grey[500]};
    &.create-tasks {
      border-bottom: 0;
      padding-top: 2rem;
      text-align: center;
      .btn-create-task {
        width: 100%;
        padding-top: 0.3rem;
        padding-bottom: 0.4rem;
        font-size: 1.125rem;
      }
    }
  }
  .speech-section-title {
    font-size: 1.125rem;
  }
  .speech-control {
    padding-top: 1.35rem;
    .speech-control-content {
      padding: 2rem 0.88rem;
    }
    .slider-control {
      height: 4.5rem;
      display: flex;
      flex-direction: column;
      gap: 1rem;
      &.disabled {
        filter: grayscale(1);
        .slider-control-content {
          .control-min,
          .control-max {
            color: ${Grey[450]};
          }
          .slider-track {
            background-color: ${Grey[500]} !important;
            box-shadow: none !important;
          }
          .slider-range {
            background-color: ${Grey[500]} !important;
          }
          .slider-thumb {
            background-color: ${Grey[500]} !important;
          }
        }
      }
      .slider-control-header {
        display: flex;
        justify-content: space-between;
        .sup-stepper-button {
          display: none;
        }
      }
      .slider-control-title {
        color: ${Grey[300]};
      }
      .slider-value {
        width: 3.5rem;
        height: 1.125rem;
        flex-shrink: 0;
        text-align: center;
        border-radius: 0.0625rem;
        border: 1px solid ${Grey[300]};
        background: ${Grey[500]};
        color: ${Grey[50]};
        font-weight: ${FontWeight['SemiBold']};
        font-size: 0.875rem;
        &.disabled {
          color: ${Grey[400]};
        }
      }
      .slider-control-content {
        display: flex;
        gap: 0.25rem;
        .control-min,
        .control-max {
          width: 2rem;
          font-size: ${FontSize['Md']};
          text-align: center;
        }
        .slider-thumb,
        .slider-track {
          background: ${Grey[100]} !important;
        }
        .slider-thumb {
          &:hover {
            box-shadow: none !important;
          }
          box-shadow: none !important;
        }
        .slider-track {
          height: 2px !important;
        }
        .slider-range::-webkit-slider-thumb {
          box-shadow: none !important;
        }
      }
    }
  }
  .direct-with-audio {
    .cvc-input {
      display: grid;
      margin-top: 2rem;
      margin-bottom: 1.06rem;
      grid-template-columns: 1fr 1fr;
      gap: 1rem;
      .btn-cvc-attach {
        justify-content: center;
        background-color: transparent;
        border: 1px solid ${Grey[50]};
        color: ${Grey[50]};
      }
      .btn-cvc-record {
        border: 1px solid #ff5a00;
        justify-content: center !important;
        svg {
          fill: #ff5a00 !important;
        }
        color: #ff5a00 !important;
        background-color: transparent !important;
        :hover {
          background-color: #ff5a00 !important;
          color: ${Grey[800]} !important;
        }
      }
    }
  }
  .number-of-takes {
    display: flex;
    justify-content: space-between;
    padding: 1.35rem 0 1.35rem 1.35rem;
    gap: 0.5rem;
    &.disabled {
      .count-selector-value {
        color: ${Grey[500]};
      }
      .btn-open-selector {
        cursor: not-allowed;
        svg * {
          fill: ${Grey[500]};
        }
      }
    }
    .sp-drawer {
      background-color: ${Black};
    }
    .count-selector {
      display: flex;
      align-items: center;
      gap: 0.5rem;
    }
    .count-selector-value {
      color: ${Grey[50]};
      text-align: center;
      display: flex;
      width: 3.5rem;
      height: 1.5rem;
      flex-direction: column;
      justify-content: center;
      flex-shrink: 0;
      font-family: Inter;
      font-size: 1rem;
      font-style: normal;
      font-weight: 600;
      line-height: 150%; /* 1.5rem */
      border-radius: 0.25rem;
      background: ${Grey[800]};
    }
    .btn-open-selector {
      /* display: inline-block; */
      width: 0.75rem;
      height: 0.4375rem;
      flex-shrink: 0;
    }
  }
  .comment-section {
    padding: 1.35rem 1.25rem;
    border-bottom: 1px solid ${Grey[500]};
    .comment-section-header {
      display: flex;
      justify-content: space-between;
      .btn-write {
        width: 1.75rem;
        height: 1.75rem;
      }
    }
    .comment-section-title {
      font-size: 1.125rem;
    }
    .comment-list {
      margin-top: 1.5rem;
      .comment-item {
        border: 1px solid ${Grey[50]};
        border-radius: 0.5rem;
        display: flex;
        flex-direction: column;
        margin-bottom: 0.65rem;
        gap: 1rem;
        padding: 0.5rem 0.9rem 0.75rem;
        font-size: 0.875rem;
        line-height: 150%;
        .comment-item-header {
          display: flex;
          justify-content: space-between;
          .user-name {
            color: ${Primary[400]};
          }
          .comment-date {
            color: ${Grey[50]};
          }
        }
        .comment-item-content {
          margin-top: 0.5rem;
          font-size: 0.875rem;
          color: ${Grey[300]};
        }
      }
    }
  }
`;
export default StyledControlPanel;
