export const CVC_PANEL_ORDER_MAP = {
  CONTROL: 1,
  AUDIO_FILE_WRAPPER: 2,
  RESULTS: 3,
  AUDIO_EDITOR: 4,
  VOICE_FILES: 5,
} as const;
export type InvalidPanelTypes = 2;
export type CVCPanelOrderTypes = Exclude<
  (typeof CVC_PANEL_ORDER_MAP)[keyof typeof CVC_PANEL_ORDER_MAP],
  InvalidPanelTypes
>;
export type CVCPanelTypes =
  | 'Results'
  | 'VoiceFiles'
  | 'AudioEditor'
  | 'SelectModel';
export type CVCVoiceFilePanelTypes = 'Source' | 'Target' | 'Generate';

export const PANEL_AUTO_SAVE_ID = 'cvc_panel_condition';
export const GRID_GAP = '0.2rem';
export const RECORD_CAPTIONS = ['Record', 'Stop'];

export const DEFAULT_RATIO = 5;
export const TOTAL_RATIO = 10;
