import IconButton from '@/components/Button/IconButton';
import Loading from '@/components/Loading/Loading';
import classNames from 'classnames';
import React, { useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import Waveform from '../../../components/Waveform/Waveform';
import { ReactComponent as DeleteTake } from '../assets/icons/DeleteTake.svg';
import { ReactComponent as FillStopIcon } from '../assets/icons/FillStopIcon.svg';
import { ReactComponent as LinePlayIcon } from '../assets/icons/LinePlayIcon.svg';
import { ReactComponent as OptionSelected } from '../assets/icons/OptionSelected.svg';
import { ReactComponent as OptionUnSelected } from '../assets/icons/OptionUnSelected.svg';
import { ReactComponent as RegenerateAudioIcon } from '../assets/icons/RegenerateAudioIcon.svg';
import useAddTake from '../hooks/useAddTake';
import useSingleAudioController from '../hooks/useSingleAudioController';
import { audioFileMapModel, audioPlayerStateModel } from '../stores/audios';
import {
  Take,
  checkedTakeModel,
  lineListModel,
  selectedTakeIdModel,
  takeListModel,
} from '../stores/project';

const SceneWriterTakeItem: React.FC<{
  take: Take;
  takeNum: number;
  lineNum: number;
  selected: boolean;
  isRegenerating?: boolean;
}> = ({ take, takeNum, lineNum, selected, isRegenerating }) => {
  const { t } = useTranslation('screenPlay');
  const [selectedTakeId, setSelectedTakeId] =
    useRecoilState(selectedTakeIdModel);
  const setLineList = useSetRecoilState(lineListModel);
  const selectTakeByLine = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setLineList((prev) => {
        return prev?.map((l) => {
          if (l.id === take.lineId) {
            return {
              ...l,
              selectedTakeId: take.id,
            };
          }
          return l;
        });
      });
    },
    [take.id, setLineList, take.lineId]
  );
  const isSelectedTake = selectedTakeId === take.id;
  const onClickTake = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      setSelectedTakeId(take.id);
    },
    [setSelectedTakeId, take.id]
  );
  const setTakeList = useSetRecoilState(takeListModel);
  const setCheckedTake = useSetRecoilState(checkedTakeModel);
  const deleteTake = useCallback(
    (e: React.MouseEvent) => {
      e.stopPropagation();
      if (selectedTakeId === take.id) {
        setSelectedTakeId(null);
      }
      setTakeList((prev) => {
        return prev?.filter((t) => t.id !== take.id);
      });
      // todo remove attendances of this take
      setCheckedTake((prev) => {
        return Object.keys(prev)?.reduce((acc, key) => {
          if (key !== take.id) {
            return {
              ...acc,
              [key]: prev[key],
            };
          }
          return acc;
        }, {});
      });
    },
    [setTakeList, take.id, setCheckedTake, selectedTakeId, setSelectedTakeId]
  );

  const addTake = useAddTake();

  const audioFileMap = useRecoilValue(audioFileMapModel);
  const [audioPlayerState, setAudioPlayerState] = useRecoilState(
    audioPlayerStateModel
  );
  const { audioBuffer, load, play, stop, currentPosition, isPlaying } =
    useSingleAudioController();

  const handlePlayStop = useCallback(
    (time?: number) => {
      if (isPlaying && typeof time !== 'number') {
        stop();
      } else {
        play(time);
      }
      setAudioPlayerState({
        type: 'standalone',
        takeId: take.id,
        isPlaying: !isPlaying,
      });
      return () => {
        stop();
      };
    },
    [isPlaying, stop, play, setAudioPlayerState, take.id]
  );

  const handleRegenerate = useCallback(() => {
    setAudioPlayerState(null);
    addTake(take, true);
  }, [addTake, take, setAudioPlayerState]);

  useEffect(() => {
    if (audioFileMap[take.id]?.audioBuffer) {
      load(audioFileMap[take.id].audioBuffer);
    }
  }, [audioFileMap, take.id, load, audioBuffer]);

  useEffect(() => {
    if (!audioPlayerState) {
      stop();
      return;
    }
    if (
      audioPlayerState.type === 'standalone' &&
      audioPlayerState.takeId === take.id &&
      isPlaying
    )
      return;
    // type이 timeline이면 stop
    // type이 standalone이면서 takeId가 현재 takeId와 같으면 stop
    if (
      (audioPlayerState.type === 'timeline' && audioPlayerState.isPlaying) ||
      (audioPlayerState.type === 'standalone' &&
        audioPlayerState.takeId !== take.id &&
        audioPlayerState.isPlaying)
    ) {
      stop();
    }
  }, [audioPlayerState, stop, take.id, isPlaying]);

  return (
    <li
      key={take.id}
      onClick={onClickTake}
      className={classNames((isRegenerating || take.isFetching) && 'fetching')}
    >
      <section
        className={classNames('line-item take', isSelectedTake && 'selected')}
      >
        <span className="line-content">
          <span className="line-left-content">
            <span className="line-number">
              {t('Take')} {lineNum}-{takeNum}
            </span>
          </span>
          <span className="wave-form">
            {audioFileMap[take.id]?.audioBuffer && (
              <Waveform
                audioBuffer={audioFileMap[take.id].audioBuffer}
                playback={currentPosition}
                onPlaybackChange={handlePlayStop}
              />
            )}
          </span>
        </span>
        <span className="item-right-content">
          <span className="line-control">
            <button
              className={classNames('btn-line-control-icon btn-take-play', {
                'btn-line-play': !isPlaying,
                'btn-line-stop': isPlaying,
              })}
              onClick={(e) => {
                e.stopPropagation();
                handlePlayStop();
              }}
            >
              {isPlaying ? <FillStopIcon /> : <LinePlayIcon />}
            </button>
            <button
              className="btn-line-control-icon btn-regenerate"
              onClick={handleRegenerate}
            >
              <RegenerateAudioIcon />
            </button>
            <IconButton
              onClick={deleteTake}
              disabled={selected}
              className={classNames(
                'btn-line-control-icon btn-delete',
                selected && 'disabled'
              )}
              isFillCurrentColor={false}
            >
              <DeleteTake />
            </IconButton>
          </span>
          {take.type === 'cvc' ? (
            <span className="line-controlled voice-direct">
              <span className="txt-recorded">{t('Voice Directed')}</span>
            </span>
          ) : (
            <span className="line-controlled" />
          )}
          <span className="selected-option">
            <IconButton
              className={classNames(
                'btn-line-control-icon btn-option',
                selected && 'selected'
              )}
              isFillCurrentColor={false}
              onClick={selectTakeByLine}
            >
              {selected ? <OptionSelected /> : <OptionUnSelected />}
            </IconButton>
          </span>
        </span>
      </section>
      <section className="fetching-status">
        <span className="fetch-take take-skull" />
        <span className="fetch-take voice-skull" />
        <span className="fetch-take locale-skull" />
        <span className="fetch-take content-skull">
          <span className="loader">
            <Loading />
          </span>{' '}
          <span className="loader-text">{t('Generating')}</span>
        </span>
        <span className="fetch-take control-skull" />
        <span className="fetch-take control-skull" />
        <span className="fetch-take control-skull" />
        <span className="fetch-take option-skull" />
      </section>
    </li>
  );
};
export default SceneWriterTakeItem;
