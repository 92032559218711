import { Primary, White } from '@/styles/Colors';
import { formatSToMMSS } from '@/util/formatter';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect } from 'react';

import Waveform from '../../../../../components/Waveform/Waveform';
import useSingleAudioController from '../../../hooks/useSingleAudioController';
import PlayIcon from '../assets/icons/PlayIcon.svg';
import StopIcon from '../assets/icons/StopIcon.svg';
import ToggleDownIcon from '../components/assets/icons/ToggleDownIcon.svg';
import Checkbox from '../components/Checkbox';
import IconButton from '../components/IconButton';
import { ZeroShotContext } from '../ZeroShotContextProvider';
import StyledZeroShotSourceItem from './StyledZeroShotSourceItem';
import { ZeroShotSource } from './ZeroShotSourcePanel';

interface ZeroShotShotSourceItemProps {
  currentTime?: string;
  source: ZeroShotSource;
  checkItem: (id: string) => void;
  isSelected: boolean;
  select: (id: string) => void;
  toggle: (id: string) => void;
}
const ZeroShotShotSourceItem: React.FC<ZeroShotShotSourceItemProps> = ({
  currentTime,
  source,
  checkItem,
  isSelected,
  select,
  toggle,
}) => {
  const { audioPlayerState, setAudioPlayerState } = useContext(ZeroShotContext);
  const { load, play, stop, currentPosition, isPlaying } =
    useSingleAudioController();

  const handlePlayStop = useCallback(
    (time?: number) => {
      if (!time && isPlaying) {
        stop();
      } else {
        play(time);
      }
      setAudioPlayerState({
        audioId: source.id,
        isPlaying: !isPlaying,
      });
      return () => {
        stop();
      };
    },
    [isPlaying, stop, play, setAudioPlayerState, source.id]
  );

  useEffect(() => {
    if (!audioPlayerState || !isPlaying) return;
    // 다른 오디오를 재생시킬 경우, 플레이중인 오디오를 정지시킴
    if (audioPlayerState.audioId !== source.id && audioPlayerState.isPlaying) {
      stop();
    }
  }, [audioPlayerState, isPlaying, stop, source.id]);

  useEffect(() => {
    if (!source?.audioBuffer) return;
    load(source.audioBuffer);
  }, [source?.audioBuffer, load]);

  return (
    <StyledZeroShotSourceItem
      onClick={() => select(source.id)}
      className={classNames(
        'zeroshot-source-item',
        source.isExpanded && 'expanded',
        isSelected && 'selected'
      )}
    >
      <section className="inner">
        <section className="item-check" onClick={(e) => e.stopPropagation()}>
          <Checkbox
            selectColorSet={{
              icon: White,
              bg: Primary[400],
              border: Primary[400],
            }}
            checked={source.isChecked}
            onChange={() => {
              checkItem(source.id);
            }}
          />
        </section>
        <section className="item-name">{source.name}</section>
        <section
          className={classNames('item-time', {
            overflow:
              source.audioBuffer &&
              (source.audioBuffer as AudioBuffer).duration > 20,
          })}
        >
          {!!currentTime && <span className="current-time">{currentTime}</span>}{' '}
          {formatSToMMSS((source.audioBuffer as AudioBuffer).duration)}
        </section>
        <section className="item-expand">
          <IconButton
            className="expand-button"
            iconColor={White}
            onClick={() => toggle(source.id)}
            width="2.5rem"
            height="2.5rem"
            iconSize="1.25rem"
            icon={ToggleDownIcon}
          />
        </section>
      </section>
      <section className="item-player">
        {source.audioBuffer && (
          <Waveform
            audioBuffer={source.audioBuffer}
            playback={currentPosition}
            onPlaybackChange={handlePlayStop}
          />
        )}
      </section>
      <section className="player-button">
        <IconButton
          iconColor={White}
          width="1.5rem"
          height="1.5rem"
          iconSize="1.5rem"
          icon={isPlaying ? StopIcon : PlayIcon}
          onClick={(e) => {
            e.stopPropagation();
            handlePlayStop();
          }}
        />
      </section>
    </StyledZeroShotSourceItem>
  );
};
export default ZeroShotShotSourceItem;
