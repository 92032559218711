import { BasicFontSize } from '@/styles/Typography';

// Pixel values for the row Panels
export const TOTAL_WIDTH = 1800;
export const MODEL_PANEL_DEFAULT = 340;
export const MODEL_PANEL_MIN = 280;
export const RESULTS_PANEL_DEFAULT = 380;
export const RESULTS_PANEL_MIN = 280;
export const RESULTS_LENGTH_FIX_SIZE = 360;
export const HIGHLIGHT_DURATION = 1000;
// Pixel values for the middle Panels
const MIDDLE_PANEL_HEIGHT_STD = 370 + 450;
export const AUDIO_EDITOR_PANEL_HEIGHT_DEFAULT =
  (370 / MIDDLE_PANEL_HEIGHT_STD) * 100;
export const VOICE_FILES_PANEL_HEIGHT_DEFAULT =
  100 - AUDIO_EDITOR_PANEL_HEIGHT_DEFAULT;
export const AUDIO_EDITOR_PANEL_HEIGHT_MIN =
  (360 / MIDDLE_PANEL_HEIGHT_STD) * 100;
export const VOICE_FILES_PANEL_HEIGHT_MIN =
  (330 / MIDDLE_PANEL_HEIGHT_STD) * 100;
// Pixel values for List Item Height
export const EXPANDED_ITEM_SIZE = 6 * BasicFontSize;
export const NARROW_ITEM_SIZE = 2.5 * BasicFontSize;
export const DRAG_ITEM_KEY = 'text/plain';
export const ZIP_NAME = 'Results.zip';
export const DRAG_FROM_TARGET = 'drag-from-target';
export const DRAG_FROM_RESULT = 'drag-from-result';
