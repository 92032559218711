import IconButton from '@/components/Button/IconButton';
import useOnClickOutside from '@/hooks/useOnClickOutside';
import { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue, useResetRecoilState, useSetRecoilState } from 'recoil';

import { updateVoice } from '../api/project';
import useSingleAudioController from '../hooks/useSingleAudioController';
import useUpdateLine from '../hooks/useUpdateLine';
import { audioPlayerStateModel } from '../stores/audios';
import {
  Voice,
  editingVoiceIdModel,
  lineListModel,
  selectedProjectIdModel,
  voiceListModel,
  voiceListSelector,
} from '../stores/project';
import StyledVoiceLibrary from '../styles/StyledVoiceLibrary';
import VoiceLibraryItem from './VoiceLibraryItem';

const CLOSE_DEFER_TIME = 600;
const VoiceLibrary: React.FC<{
  onClose: () => void;
}> = ({ onClose }) => {
  const { t } = useTranslation();
  const voiceList = useRecoilValue(voiceListSelector);
  const editingVoiceId = useRecoilValue(editingVoiceIdModel);
  const [introId] = useState<string | null>(editingVoiceId);
  const resetEditingVoiceId = useResetRecoilState(editingVoiceIdModel);
  const setAudioPlayerState = useSetRecoilState(audioPlayerStateModel);

  useEffect(() => {
    if (editingVoiceId) {
      return () => resetEditingVoiceId();
    }
  }, [editingVoiceId, resetEditingVoiceId]);
  const [openVoiceId, setOpenVoiceId] = useState<string | null>(introId);
  const toggleItem = useCallback(
    (voiceId: string) => {
      if (voiceId === openVoiceId) {
        setOpenVoiceId(null);
      } else {
        setOpenVoiceId(voiceId);
      }
    },
    [openVoiceId]
  );

  const ref = useRef<HTMLElement>(null);
  useOnClickOutside(ref, onClose);

  const lineList = useRecoilValue(lineListModel);
  const onUpdateLine = useUpdateLine();
  const updateLineByVoice = useCallback(
    (voice: Voice) => {
      const relatedLineList = lineList.filter(
        (line) => line.voiceId === voice.id && !!line.text
      );
      for (let i = 0; i < relatedLineList.length; i++) {
        onUpdateLine(relatedLineList[i], relatedLineList[i].text, false, voice);
      }
    },
    [lineList, onUpdateLine]
  );

  const setVoiceList = useSetRecoilState(voiceListModel);
  const selectedProjectId = useRecoilValue(selectedProjectIdModel);
  const onComplete = useCallback(
    (voice: Voice, age: number, gender: number) => {
      setTimeout(() => {
        setVoiceList((oldList) => {
          const newList = [...oldList];
          const index = newList.findIndex((item) => item.id === voice.id);
          if (index !== -1) {
            newList[index] = { ...voice, age, gender };
          } else {
            newList.unshift({ ...voice, age, gender });
          }
          updateVoice(selectedProjectId, newList);
          return newList;
        });
        updateLineByVoice({ ...voice, age, gender });
      }, CLOSE_DEFER_TIME);
    },
    [setVoiceList, selectedProjectId, updateLineByVoice]
  );

  const { play, stop, isPlaying } = useSingleAudioController();
  const [playingId, setPlayingId] = useState<string | null>(null);
  const [isLoading, setIsLoading] = useState(false);

  const playAudio = useCallback(
    (audioBuffer: AudioBuffer) => {
      if (isPlaying) {
        stop();
      }
      play(0, audioBuffer);
    },
    [isPlaying, play, stop]
  );

  const stopAudio = useCallback(() => {
    stop();
  }, [stop]);

  // 모달이 열릴 때 재생중인 오디오를 정지시키기 위한 로직
  useEffect(() => {
    setAudioPlayerState(null);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <StyledVoiceLibrary>
      <section className="voice-library" ref={ref}>
        <section className="voice-library-header">
          <h1 className="voice-library-title">{t('Voice Library')}</h1>
          <IconButton
            color="transparent"
            className="btn-close"
            onClick={onClose}
          />
        </section>
        <section className="voice-library-body">
          <section className="voice-library-container">
            <ul>
              {voiceList.map((voice) => (
                <VoiceLibraryItem
                  voice={voice}
                  isOpen={voice.id === openVoiceId}
                  toggleItem={toggleItem}
                  key={voice.id}
                  onComplete={onComplete}
                  playAudio={playAudio}
                  stopAudio={stopAudio}
                  isPlaying={isPlaying && voice.id === playingId}
                  playingId={playingId}
                  updatePlayingId={setPlayingId}
                  isLoading={isLoading}
                  updateIsLoading={setIsLoading}
                />
              ))}
            </ul>
          </section>
        </section>
      </section>
    </StyledVoiceLibrary>
  );
};
export default VoiceLibrary;
