import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useEffect, useMemo } from 'react';
import { Navigate } from 'react-router-dom';

import Landing from '../../layout/Landing/Landing';

const IndexPage = () => {
  const { isLoading, error, isAuthenticated, user } = useSUPAuth();

  useEffect(() => {
    if (!isAuthenticated) return;
  }, [isAuthenticated, user]);

  const indexPath = useMemo(() => {
    if (error) return '/error';
    if (isAuthenticated) {
      // Redirect to CVC page if user has permission
      // TODO: 현재 User가 cvc페이지에 권한이 없을 경우 /message/needs-approval로 이동하도록 설정되어 있음
      return '/editor/demo';
    } else {
      return '/signin';
    }
  }, [isAuthenticated, error]);

  return isLoading ? <Landing /> : <Navigate to={indexPath} replace={true} />;
};

export default IndexPage;
