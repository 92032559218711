import classNames from 'classnames';
import { useCallback } from 'react';
import { useRecoilState } from 'recoil';

import IconButton from '../../../components/Button/IconButton';
import { Grey } from '../../../styles/Colors';
import { ReactComponent as MediaIcon } from '../assets/icons/MediaIcon.svg';
import { ReactComponent as TimelineIcon } from '../assets/icons/TimelineIcon.svg';
import { isShowMediaModel, isShowTimelineModel } from '../stores/panels';

const PanelButtonGroup = () => {
  const [isShowTimeline, setIsShowTimeline] =
    useRecoilState(isShowTimelineModel);
  const [isShowMedia, setIsShowMedia] = useRecoilState(isShowMediaModel);
  const onToggleTimeline = useCallback(() => {
    setIsShowTimeline((prev) => !prev);
  }, [setIsShowTimeline]);
  const onToggleMedia = useCallback(() => {
    setIsShowMedia((prev) => !prev);
  }, [setIsShowMedia]);

  return (
    <section className="panel-buttons">
      <IconButton
        className={classNames('icon-timeline', isShowTimeline && 'active')}
        variant="outlined"
        color={Grey[500]}
        onClick={() => onToggleTimeline()}
      >
        <TimelineIcon />
      </IconButton>
      <IconButton
        className={classNames('icon-media', isShowMedia && 'active')}
        variant="outlined"
        color={Grey[500]}
        onClick={() => onToggleMedia()}
      >
        <MediaIcon />
      </IconButton>
    </section>
  );
};

export default PanelButtonGroup;
