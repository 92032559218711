import { PropsWithChildren, ReactNode } from 'react';

import { Primary } from '../../styles/Colors';
import { FontWeight } from '../../styles/Typography';
import { FONT_SIZE } from './const';
import { StyledButton } from './StyledButton';
import { ButtonBaseProps } from './types';

export interface ButtonProps extends ButtonBaseProps {
  fullWidth?: boolean;
  startIcon?: ReactNode;
  endIcon?: ReactNode;
}

const Button = ({
  variant = 'contained',
  radius = 'md',
  color = Primary['400'],
  fontWeight = FontWeight['SemiBold'],
  fullWidth = false,
  size = 'md',
  startIcon,
  endIcon,
  className = '',
  children,
  ...rest
}: PropsWithChildren<ButtonProps>) => {
  return (
    <StyledButton
      className={`sup-button ${className}`}
      variant={variant}
      radius={radius}
      color={color}
      fontWeight={fontWeight}
      fullWidth={fullWidth}
      startIcon={startIcon}
      endIcon={endIcon}
      fontSize={FONT_SIZE[size]}
      {...rest}
    >
      {startIcon && <span className="sup-button-icon">{startIcon}</span>}
      {children}
      {endIcon && <span className="sup-button-icon">{endIcon}</span>}
    </StyledButton>
  );
};

export default Button;
