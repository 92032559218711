import { flintAgeGenderMap, flintVoiceInfo } from './voices/flint';
import Profile_1_1 from './voices/images/1-1.png';
import Profile_1_2 from './voices/images/1-2.png';
import Profile_11_1 from './voices/images/11-1.png';
import Profile_14_1 from './voices/images/14-1.png';
import Profile_2_1 from './voices/images/2-1.png';
import Profile_3_1 from './voices/images/3-1.png';
import Profile_6_1 from './voices/images/6-1.png';
import Profile_8_1 from './voices/images/8-1.png';
import Profile_8_2 from './voices/images/8-2.png';
import Profile_9_1 from './voices/images/9-1.png';
import {
  needsgamesAgeGenderMap,
  needsgamesVoiceInfo,
} from './voices/needsgames';
import {
  pearlAbyssAgeGenderMap,
  pearlAbyssVoiceInfo,
} from './voices/pearlabyss';

export const script = {
  male: {
    ko: '수퍼톤의 스크린플레이에 오신 것을 환영해요. 당신의 이야기에 숨결을 불어넣어 드리겠습니다.',
    en: 'Welcome to Supertone’s Screenplay. Let me breathe life into your story.',
    jp: 'スーパートーンの脚本へようこそ。私はあなたの物語に命を吹き込みます',
  },
  female: {
    ko: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 당신의 이야기를 제가 들어드리겠습니다. 무엇이든 제게 말씀해주세요.',
    en: "Welcome to Supertone's Screenplay. I will listen to what you have to say. Feel free to tell me anything.",
    jp: 'スーパートーンの脚本へようこそ。一緒に新しい物語を作りましょう。',
  },
  system: {
    ko: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저와 함께 새로운 이야기를 만들어 나가보아요.',
    en: "Welcome to Supertone's Screenplay. Let's create a new story together.",
    jp: 'スーパートーンの脚本へようこそ。私は星と呼ばれる、グラングリベル正義団のレオです。',
  },
  forJP: {
    mio: {
      ko: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 제 여정에 당신을 초대합니다. 함께 새로운 세상으로 떠나요',
      en: "Welcome to Supertone's Screenplay. I invite you to join my journey. Let's embark on a new journey together.",
      jp: 'スーパートーンの脚本へようこそ。私はあなたを私の旅に招待します。一緒に新しい世界に旅立ちましょう。',
    },
    hani: {
      ko: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 당신이 원하는 목소리를 알고싶어요.',
      en: "Welcome to Supertone's Screenplay. I want to know the voice you desire.",
      jp: 'スーパートーンの脚本へようこそ。あなたが望む声を知りたいです。',
    },
  },
  forJP2: {
    ko: '수퍼톤의 스크린플레이에 온 것을 환영해! 나와 함께 재밌는 모험을 떠나보지 않을래?!',
    en: "Welcome to Supertone's Screenplay! How about going on an exciting adventure with me?",
    jp: 'スーパートーンの脚本へようこそ！私と一緒に楽しい冒険に出かけませんか？',
  },
  forWestern: {
    ko: '수퍼톤의 스크린플레이에 온 것을 환영한다. 나를 선택한 것은 당신에게 큰 행운이 될 것이야.',
    en: "Welcome to Supertone's Screenplay. Choosing me will be a great fortune for you.",
    jp: 'スーパートーンの脚本へようこそ。私を選んでくれて幸運になるでしょう。',
  },
  mechanic: {
    ko: '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 당신의 이야기를 입력해주십시오. 성심껏 처리해 드리도록 하겠습니다.',
    en: "Welcome to Supertone's Screenplay. Please input your story. I will process it with care.",
    jp: 'スーパートーンの脚本へようこそ。あなたの物語を入力してください。私は丁寧に処理いたします。',
  },
  alien: {
    enigma: {
      ko: '수퍼톤의 스크린플레이에 오신 것을 환영하오! 새로운 세계를 함께 탐험해보도록 하지.',
      en: "Welcome to Supertone's Screenplay! Let's explore new worlds together.",
      jp: 'スーパートーンの脚本へようこそ！一緒に新しい世界を探様しましょう。',
    },
    xak: {
      ko: '수퍼톤의 스크린플레이에 온 것을 환영한다. 어둠의 세계로 당신을 초대하겠어. 궁금하면 나를 따라오도록.',
      en: "Welcome to Supertone's Screenplay. I invite you to the world of darkness. Follow me if you're curious.",
      jp: 'スーパートーンの脚本へようこそ。暗黒の世界へあなたを招待します。興味があれば、私についてきてください。',
    },
  },
};

const ageGenderMap = {
  Rowan: { age: 44.373, gender: 10.621 },
  Levi: { age: 31.358, gender: 9.078 },
  Una: { age: 55.175, gender: -8.911 },
  Mana: { age: 53.577, gender: -8.515 },
  Mio: { age: 8.746, gender: -1.337 },
  Liam: { age: 68.69, gender: 9.258 },
  Mel: { age: 50.348, gender: -3.445 },
  Helix: { age: 49.014, gender: 8.865 },
  Hana: { age: 20.253, gender: -2.807 },
  Loreen: { age: 38.191, gender: -6.13 },
  Gunho: { age: 35.192, gender: 10.337 },
  Gunho2: { age: 35.192, gender: 10.337 },
  Aileen: { age: 28.193, gender: -8.215 },
  Aileen2: { age: 28.193, gender: -8.215 },
  // flint 용 추가 voice
  ...flintAgeGenderMap,
  // pearlAbyss 용 추가 voice
  ...pearlAbyssAgeGenderMap,
  // needsgames 용 추가 voice
  ...needsgamesAgeGenderMap,
};
type VoiceId = keyof typeof ageGenderMap;

export const defaultVoiceInfo = [
  {
    id: 'Rowan',
    name: 'Rowan',
    language: 'en',
    character: 'Male',
    thumbnail: Profile_1_1,
    description: {
      ko: '차분하고 중후한 매력을 뽐내는 다양한 스토리 장르에 어울리는 목소리',
      en: 'A calm, gravelly voice for a variety of story genres',
    },
    script: script.male.en,
  },
  {
    id: 'Loreen',
    name: 'Loreen',
    language: 'en',
    character: 'Female',
    thumbnail: Profile_14_1,
    description: {
      ko: '온화한 설명톤의 절제된 매력을 가진 중성적인 목소리',
      en: 'A neutral voice with a mild explanatory tone and understated charm',
    },
    script: script.forWestern.en,
  },
  {
    id: 'Liam',
    name: 'Liam',
    language: 'en',
    character: 'Male',
    thumbnail: Profile_8_1,
    description: {
      ko: '믿음직한 조언자의 역할을 담당할 신뢰감 있는 연기력',
      en: 'A credible actor to play the role of a trusted advisor',
    },
    script: script.forWestern.en,
  },
  {
    id: 'Hana',
    name: 'Hana',
    language: 'jp',
    character: 'Female',
    thumbnail: Profile_11_1,
    description: {
      ko: '모두의 귀를 사로잡는 호소력, 다양한 연기를 소화하는 안정감',
      en: 'An appealing, balanced voice that suits diverse roles',
    },
    script: script.female.jp,
  },
  {
    id: 'Helix',
    name: 'Helix',
    language: 'en',
    character: 'Robot',
    thumbnail: Profile_9_1,
    description: {
      ko: '메카닉 캐릭터의 연기를 담당하는 디지털 로봇의 톤',
      en: 'A voice of a digital robot performing in human languages',
    },
    script: script.mechanic.en,
  },
  {
    id: 'Mel',
    name: 'Mel',
    language: 'en',
    character: 'Female',
    thumbnail: Profile_8_2,
    description: {
      ko: '차분하고 감성적인 어조, 중성적인 표현력이 제공하는 신비로운 감성.',
      en: 'A mysterious voice with a calm, emotional, neutral tone',
    },
    script: script.forWestern.en,
  },
  {
    id: 'Levi',
    name: 'Levi',
    language: 'en',
    character: 'Male',
    thumbnail: Profile_1_2,
    description: {
      ko: '편안한 어투와 세련된 표현력으로 안정감 있는 진행의 만능 연기자',
      en: 'A well-rounded actor with a relaxed, polished tone',
    },
    script: script.male.en,
  },
  {
    id: 'Una',
    name: 'Una',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_2_1,
    description: {
      ko: '지적 카리스마, 깊이있는 표현이 능숙한 우아한 연기력',
      en: 'Intellectual charisma, elegant acting with depth of expression',
    },
    script: script.female.ko,
  },
  {
    id: 'Mio',
    name: 'Mio',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_6_1,
    description: {
      ko: '어리지만 명확한 어조로 높은 몰입감과 설득력을 표현하는 목소리',
      en: 'A young, clear voice that is highly engaging and persuasive',
    },
    script: script.forJP.mio.ko,
  },
  {
    id: 'Mana',
    name: 'Mana',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_3_1,
    description: {
      ko: '정중하고 차분한 톤으로 설명, 대화, 낭독 다양한 연기에 적합',
      en: 'Polite, calm tone suitable for various performances such as explanation, dialogue, and readings',
    },
    script: script.system.ko,
  },
  {
    id: 'Gunho',
    name: 'Gunho',
    language: 'ko',
    description: { ko: '위버스매거진', en: '' },
    script: script.male.ko,
  },
  {
    id: 'Gunho2',
    name: 'Gunho2',
    language: 'ko',
    description: { ko: '위버스매거진', en: '' },
    script: script.male.ko,
  },
  {
    id: 'Aileen',
    name: 'Aileen',
    language: 'en',
    description: { ko: '위버스매거진', en: '' },
    script: script.female.en,
  },
  {
    id: 'Aileen2',
    name: 'Aileen2',
    language: 'en',
    description: { ko: '위버스매거진', en: '' },
    script: script.female.en,
  },
  ...flintVoiceInfo,
  ...pearlAbyssVoiceInfo,
  ...needsgamesVoiceInfo,
];

export const defaultVoiceList = defaultVoiceInfo.map((voice) => {
  return { ...voice, ...ageGenderMap[voice.id as VoiceId] };
});

// set storage
export const VOICE_STORAGE_KEY = 'pj-screenplay-voice-resource';
export const getLanguage = (lang?: string) => {
  switch (lang) {
    case 'en':
      return 'English';
    case 'ko':
      return 'Korean';
    case 'jp':
      return 'Japanese';
    default:
      return 'English';
  }
};
