import classNames from 'classnames';
import i18next from 'i18next';
import { useRecoilState } from 'recoil';

import { projectsTabStateModel } from '../stores/panels';
import StyledProjectPanel from '../styles/StyledProjectPanel';
import { StyledTabArea } from '../styles/StyledTabArea';
import ProjectTab from './ProjectTab';
import ScenesTab from './ScenesTab';
import VoicesTab from './VoicesTab';

const tabCaptions = [
  i18next.t('screenPlay:Projects'),
  i18next.t('screenPlay:Voices'),
  i18next.t('screenPlay:Scenes'),
];

const ProjectPanel = () => {
  const [selectedTab, setSelectedTab] = useRecoilState(projectsTabStateModel);
  return (
    <StyledProjectPanel className="sp-top-panel sp-project">
      <StyledTabArea className="tab-area">
        <ul>
          {tabCaptions.map((caption, index) => (
            <li
              key={index}
              onClick={() => setSelectedTab(index)}
              className={classNames(selectedTab === index && 'on')}
            >
              <span>{caption}</span>
            </li>
          ))}
        </ul>
      </StyledTabArea>
      <section className="tab-content">
        {selectedTab === 0 && <ProjectTab />}
        {selectedTab === 1 && <VoicesTab />}
        {selectedTab === 2 && <ScenesTab />}
      </section>
    </StyledProjectPanel>
  );
};

export default ProjectPanel;
