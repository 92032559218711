import { axisTop } from 'd3-axis';
import { ScaleLinear } from 'd3-scale';
import { select } from 'd3-selection';
import { PointerEvent, useCallback, useEffect, useRef } from 'react';
import { useSetRecoilState } from 'recoil';

import { formatSToMMSS } from '../../../../util/formatter';
import { timelinePlaybackModel } from '../../stores/timeline';
import { LEFT_OFFSET, X_AXIS_HEIGHT } from './const';
import { Size } from './Timeline';

interface TimeAxisProps {
  size: Size;
  xScale: ScaleLinear<number, number>;
}

const TimeAxis = ({ size, xScale }: TimeAxisProps) => {
  const svgRef = useRef<SVGSVGElement>(null);
  const axisRef = useRef<SVGGElement>(null);
  const setTimelinePlayback = useSetRecoilState(timelinePlaybackModel);

  const draw = useCallback(() => {
    if (!axisRef.current) return;
    const axis = axisRef.current;

    const axisGenerator = axisTop(xScale).tickFormat((d) => {
      return formatSToMMSS(d as number);
    });

    select(axis)
      .call(axisGenerator)
      .attr('transform', `translate(${LEFT_OFFSET}, ${X_AXIS_HEIGHT})`)
      .select('.domain')
      .remove();

    // remove tick line
    select(axis).selectAll('line').remove();
    // select text
    select(axis)
      .selectAll('text')
      .attr('y', 0)
      .attr('dy', 0)
      .attr('alignment-baseline', 'middle')
      .attr('transform', `translate(0, ${-X_AXIS_HEIGHT / 2})`);
  }, [xScale]);

  const handlePointerDown = useCallback(
    (e: PointerEvent<HTMLDivElement>) => {
      const svg = svgRef.current;
      if (!svg) return;
      const svgRect = svg.getBoundingClientRect();
      const x = e.clientX - svgRect.left;
      if (x < LEFT_OFFSET) return;
      const playback = xScale.invert(x - LEFT_OFFSET);
      setTimelinePlayback(playback);
    },
    [xScale, setTimelinePlayback]
  );

  useEffect(() => {
    draw();
  }, [draw, xScale]);

  useEffect(() => {
    const svg = svgRef.current;
    if (!svg) return;
    svg.setAttribute('width', `${size.width}`);
    svg.setAttribute('height', `${X_AXIS_HEIGHT}`);
    svg.setAttribute('viewBox', `0 0 ${size.width} ${X_AXIS_HEIGHT}`);
  }, [size]);

  return (
    <div
      className="timeline-axis-x"
      style={{ width: size.width, height: X_AXIS_HEIGHT }}
      onPointerDown={handlePointerDown}
    >
      <svg ref={svgRef}>
        <g ref={axisRef}></g>
      </svg>
    </div>
  );
};

export default TimeAxis;
