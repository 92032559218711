import { atom } from 'recoil';

interface VideoPlayerState {
  isPlaying: boolean;
  startPosition: number;
}

export const videoPlayerStateModel = atom<VideoPlayerState | null>({
  key: 'videoPlayerState',
  default: null,
});
