// tmp component for needs approval
import styled from '@emotion/styled';
// import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { FC } from 'react';
// import { useTranslation } from 'react-i18next';

// import Button from '../../../../components/Button/Button';
// import { Primary } from '../../../../styles/Colors';

const StyledWrapper = styled.div`
  width: 50%;
  display: flex;
  flex-flow: column;
  gap: 2rem;
  p {
    font-size: 1.25rem;
    line-height: 1.5;
  }
  /* button {
    align-self: center;
    font-size: 1.2rem;
    width: 8rem;
  } */
`;

const NeedsApproval: FC = () => {
  // const { logout } = useSUPAuth();
  // const { t } = useTranslation();
  return (
    <StyledWrapper>
      <h1>관리자의 승인이 필요합니다.</h1>
      <p>
        죄송합니다. 페이지에 접근할 수 없습니다. 잘못된 경로로 접근하셨거나,
        관리자의 승인이 필요합니다. 추가적인 접근 권한이 필요하신 경우
        수퍼톤으로 문의해주세요.
      </p>
      {/* <Button
        className="logout"
        color={Primary[400]}
        size="lg"
        onClick={() =>
          logout({ logoutParams: { returnTo: window.location.origin } })
        }
      >
        {t('LOG OUT')}
      </Button> */}
    </StyledWrapper>
  );
};

export default NeedsApproval;
