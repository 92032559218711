import { useCallback } from 'react';
import { useRecoilState, useRecoilValue, useSetRecoilState } from 'recoil';

import { ReactComponent as LineDeleteIcon } from '../assets/icons/LineDeleteIcon.svg';
import { ReactComponent as LineDownLoadIcon } from '../assets/icons/LineDownloadIcon.svg';
import { ReactComponent as LineSelectAllIcon } from '../assets/icons/LineSelectAllIcon.svg';
import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import { AudioFile, audioFileMapModel } from '../stores/audios';
import {
  Line,
  checkedAllLineSelector,
  checkedAnyTakeSelector,
  checkedLineSelector,
  checkedTakeModel,
  enabledVoiceListSelector,
  lineListModel,
  takeListModel,
} from '../stores/project';
import { downloadAudio } from '../utils/files';

const LineControl = () => {
  const defaultVoiceList = useRecoilValue(enabledVoiceListSelector);
  const voiceNameMap: Record<string, string> = defaultVoiceList.reduce(
    (acc, cur) => {
      return { ...acc, [cur.id]: cur.name };
    },
    {}
  );

  const selectedAllLine = useRecoilValue(checkedAllLineSelector);
  const checkedAnyTake = useRecoilValue(checkedAnyTakeSelector);
  const [takeList, setTakeList] = useRecoilState(takeListModel);
  const [checkedTake, setCheckedTake] = useRecoilState(checkedTakeModel);
  const setLineList = useSetRecoilState(lineListModel);
  const checkedLine = useRecoilValue(checkedLineSelector);
  const selectAllLine = useCallback(() => {
    setCheckedTake((prev) => {
      const newCheckedTake = { ...prev };
      takeList.forEach((take) => {
        newCheckedTake[take.id] = !selectedAllLine;
      });
      return newCheckedTake;
    });
  }, [selectedAllLine, takeList, setCheckedTake]);
  const { addEmptyLine } = useSessionStorage();
  const onDelete = useCallback(() => {
    // 선택된 라인 제거
    let newLine = [] as Line[];
    setLineList((prev) => {
      newLine = prev.filter(
        (l) => checkedLine.findIndex(({ id }) => id === l.id) === -1
      );
      return newLine;
    });
    // fixme 선택된 테이크 제거 후 인덱스 부여 => 리스트 Radio로 변경되서 바뀔 로직
    setTakeList((prev) => {
      const newTakeList = prev.filter((take) => !checkedTake[take.id]);
      return newTakeList.map((take, index) => ({
        ...take,
        takeNumber: index + 1,
      }));
    });
    // todo 선택 정보 제거 => AudioBuffer 도 추가제거
    setCheckedTake((prev) => {
      const newCheckedTake = { ...prev };
      takeList.forEach((take) => {
        newCheckedTake[take.id] && delete newCheckedTake[take.id];
      });
      return newCheckedTake;
    });
    if (newLine.length === 0) {
      addEmptyLine();
    }
  }, [
    checkedLine,
    checkedTake,
    setCheckedTake,
    setLineList,
    setTakeList,
    takeList,
    addEmptyLine,
  ]);

  const audioFileMap = useRecoilValue(audioFileMapModel);
  const downloadList = checkedLine.map((line) => ({
    id: line.selectedTakeId as string,
    name: `Line-${line.number + 1}_${voiceNameMap[line.voiceId]}_${
      line.language
    }_${
      line.language === 'jp'
        ? line.text.substring(0, 10)
        : line.text.split(' ').slice(0, 2).join(' ')
    }`,
  }));
  const list = downloadList.reduce((acc, next) => {
    if (audioFileMap[next.id]) {
      acc.push({ ...audioFileMap[next.id], fileName: next.name });
    }
    return acc;
  }, [] as AudioFile[]);
  const onClickDownload = useCallback(() => {
    const audioList = list.map((audio) => ({
      ...audio,
      fileName: `${audio.fileName}`,
    }));
    downloadAudio(audioList);
  }, [list]);
  return (
    <section className="sp-scene-control">
      {checkedAnyTake && (
        <>
          <button
            onClick={onDelete}
            className="button-reset btn-control btn-delete"
          >
            <LineDeleteIcon />
          </button>
          <button
            className="button-reset btn-control btn-download"
            onClick={onClickDownload}
          >
            <LineDownLoadIcon />
          </button>
        </>
      )}
      <button
        onClick={selectAllLine}
        className="button-reset btn-control btn-select-all"
      >
        <LineSelectAllIcon />
      </button>
    </section>
  );
};
export default LineControl;
