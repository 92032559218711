import styled from '@emotion/styled';

import { FontWeight } from '../../styles/Typography';

const StyledLogin = styled.section`
  .btn-group {
    position: absolute;
    bottom: 12rem;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    gap: 4.5rem;
    justify-content: space-between;
    .btn-login {
      box-sizing: border-box;
      padding: 1.26rem 1.875rem;
      font-size: 1.625rem;
      font-weight: ${FontWeight['Regular']};
      line-height: 1rem;
      border-radius: 0.46rem;
    }
  }
`;

export default StyledLogin;
