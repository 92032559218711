import { Black, Grey, Primary, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import CloseIcon from '../assets/images/CloseIcon.svg';
import SupertoneIcon from '../assets/images/SupertoneIcon.svg';

const StyledVoiceLibrary = styled.section`
  background: rgba(19, 19, 19, 0.6);
  backdrop-filter: blur(1.5px);
  position: fixed;
  z-index: 100;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  .voice-library {
    border-radius: 0.75rem;
    display: flex;
    flex-direction: column;
    width: 66rem;
    height: 48.7rem;
    background-color: ${Grey[600]};
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    border: 1px solid ${Grey[300]};
    backdrop-filter: blur(8px);
    padding: 2.06rem 3.25rem 3.5rem 3.5rem;
    .voice-library-header {
      width: 100%;
      height: 1.625rem;
      display: flex;
      justify-content: space-between;
      .voice-library-title {
        font-size: 1.5rem;
        ::before {
          content: '';
          vertical-align: middle;
          display: inline-block;
          width: 1.61275rem;
          height: 1.625rem;
          margin-right: 0.85rem;
          background-image: url(${SupertoneIcon});
          background-size: cover;
        }
      }
      .btn-close {
        width: 1.49575rem;
        height: 1.5rem;
        background-image: url(${CloseIcon});
        background-size: cover;
      }
    }
    .voice-library-body {
      border-radius: 0.375rem;
      background: ${Black};
      width: 59.25rem;
      height: 39.875rem;
      flex-shrink: 0;
      margin-top: 1.5rem;
      .voice-library-container {
        margin: 2.19rem 1rem;
        padding: 0 1.06rem;
        height: 35.875rem;
        overflow-y: auto;
        ul {
          display: flex;
          width: 100%;
          flex-direction: column;
          gap: 1rem;
          .voice-library-item {
            border-radius: 0.3125rem;
            background: ${Grey[700]};
            border: 1px solid ${Grey[500]};
            &:hover {
              background-color: ${Grey[500]};
              .item-summary {
                .voice-action {
                  visibility: visible;
                }
              }
            }
          }
          .item-summary {
            height: 3.75rem;
            padding: 0.625rem 1.5rem;
            display: grid;
            grid-template-columns: 3.7rem 6.25rem 10rem 5.475rem 8.75rem 9.8125rem 0.75rem;
            justify-content: space-between;
            align-items: center;
            white-space: nowrap;
            .voice-profile {
              width: 2.5rem;
              height: 2.5rem;
            }
            .cell {
              overflow: hidden;
              text-overflow: ellipsis;
            }
            .voice-action {
              gap: 1rem;
              display: inline-flex;
              visibility: hidden;
            }
            &.open {
              height: 15.3rem;
              padding-bottom: 2.19rem;
            }
            .voice-control {
              display: flex;
              align-items: center;
              gap: 0.625rem;
              justify-content: left;
              .btn-voice-icon {
                background-color: transparent;
                border: none;
                color: transparent;
                width: 2rem;
                height: 2rem;
                &:hover {
                  color: ${Grey[300]};
                }
                &.btn-open-slider {
                  :hover {
                    background-color: ${Grey[450]};
                  }
                }
              }
            }
            .voice-gender,
            .voice-locale {
              color: ${Grey[300]};
            }
            .btn-voice-action {
              border: 1px solid ${White};
              border-radius: 12.5rem;
              width: 4.8125rem;
              :hover {
                background-color: ${Primary[200]};
              }
              &:disabled {
                background-color: ${Grey[500]};
                cursor: default;
              }
              &.complete {
                background-color: transparent;
              }
              &.btn-reset {
                background-color: transparent;
              }
            }
            .voice-profile-btn {
              display: flex;
              align-items: center;
              height: 2rem;
            }
          }
          .intro-voice {
            margin: 0.5rem 1.5rem 0;
            padding: 0.75rem 1rem;
            border-radius: 0.5rem;
            border: 1px solid ${Grey[100]};
            p {
              font-size: 0.875rem;
              line-height: 180%;
            }
            .ko {
              color: ${Grey[50]};
            }
            .en {
              color: ${Grey[300]};
            }
          }
          .item-detail {
            margin: 0 1.5rem;
            padding: 1.5rem 0;
            display: grid;
            gap: 2.75rem;
            grid-template-columns: 24.125rem 24.125rem;
            .slider-value {
              width: 3.5rem;
              border-radius: 0.0625rem;
              border: 1px solid ${Grey[500]};
              height: 1.125rem;
              color: ${Grey[50]};
              font-weight: ${FontWeight['SemiBold']};
              font-size: 0.875rem;
              text-align: center;
            }
            .slider-control {
              height: 7.0625rem;
              border-radius: 0.375rem;
              display: flex;
              justify-content: center;
              background-color: ${Black};
              flex-direction: column;
              gap: 1.15rem;
              padding: 0 1rem;
              &-header {
                display: flex;
                justify-content: space-between;
                .sup-stepper-button {
                  display: none;
                }
              }
              .sup-slider {
                .slider-label {
                  bottom: 2rem;
                  background-color: ${Black};
                  z-index: 10;
                }
              }
            }
          }
        }
      }
    }
  }
`;
export default StyledVoiceLibrary;
