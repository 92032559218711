// Global audio context

import { PathData } from '../components/AudioEditor/types';

// User gesture 이후에 audio context 생성이 필요하다.
let audioContext: AudioContext | null = null;

export const getAudioContext = (): AudioContext => {
  try {
    if (!audioContext) {
      audioContext = new AudioContext();
    }
    return audioContext;
  } catch {
    throw new Error('AudioContext is not supported in this browser.');
  }
};

export const getFileNameFromUrl = (url: string) => {
  const match = url.match(/\/([^/]+)$/);
  if (match) {
    return match[1];
  }
  return 'unknown';
};

export const getFileNameFromHeader = (headers: Headers) => {
  const contentDisposition = headers.get('Content-Disposition');
  if (contentDisposition) {
    const match = contentDisposition.match(/filename="?(.*?)"?$/);
    if (match) {
      return match[1];
    }
  }
  return 'unknown';
};

export const fetchAudio = async (audio: File | string) => {
  try {
    let arrayBuffer: ArrayBuffer;
    let fileName: string;
    if (typeof audio === 'string') {
      const response = await fetch(audio);
      fileName =
        getFileNameFromUrl(audio) || getFileNameFromHeader(response.headers);
      arrayBuffer = await response.arrayBuffer();
    } else {
      fileName = audio.name;
      const fileReader = new FileReader();
      fileReader.readAsArrayBuffer(audio);
      arrayBuffer = await new Promise<ArrayBuffer>((resolve, reject) => {
        fileReader.onload = (event) => {
          event.target && resolve(event.target.result as ArrayBuffer);
        };
        fileReader.onerror = (error) => {
          reject(error);
        };
      });
    }
    return { fileName, arrayBuffer };
  } catch (error) {
    throw new Error(`Failed to fetch audio.`, { cause: error });
  }
};

/* 
  Convert stereo to mono if specified.
  CvC나 tts는 항상 mono로 변환을 하지만(그리고 추후 stereo를 지원할 수도 있음)
  Mss에서는 stereo를 유지할 필요가 있다.
*/
export const getAudioBuffer = async (
  arrayBuffer: ArrayBuffer,
  convertToMono = false
) => {
  try {
    const audioContext = getAudioContext();
    const decodedAudio = await audioContext.decodeAudioData(arrayBuffer);
    if (!decodedAudio) return;
    if (convertToMono) {
      return convertStereoToMono(audioContext, decodedAudio);
    }
    return decodedAudio;
  } catch (error) {
    throw new Error(`Failed to decode audio.`, { cause: error });
  }
};

// Convert stereo AudioBuffer to mono.
export const convertStereoToMono = (
  audioContext: AudioContext,
  audioBuffer: AudioBuffer
) => {
  // If the audio is already mono, return the original buffer.
  if (audioBuffer.numberOfChannels === 1) return audioBuffer;

  const channelData = audioBuffer.getChannelData(0);
  const newAudioBuffer = audioContext.createBuffer(
    1,
    channelData.length,
    audioBuffer.sampleRate
  );

  const leftChannelData = audioBuffer.getChannelData(0);
  const rightChannelData = audioBuffer.getChannelData(1);
  const newChannelData = new Float32Array(channelData.length);

  // Average the two channels into one.
  for (let i = 0; i < channelData.length; i++) {
    newChannelData[i] = (leftChannelData[i] + rightChannelData[i]) / 2;
  }

  newAudioBuffer.copyToChannel(newChannelData, 0);

  return newAudioBuffer;
};

// Resample audio data to fit the width of the canvas.
// 만약 width가 원본 데이터보다 큰 경우, line chart를 그리기 위해 number[] 타입으로 처리합니다.
// 만약 width가 원본 데이터보다 작은 경우, area chart를 그리기 위해 number[][] 타입으로 처리합니다.
export const resampleAudioData = (
  audioData: Float32Array,
  width: number
): PathData => {
  const length = audioData.length;
  const newLength = width;

  // 원하는 너비에 따라 새로운 샘플링 비율을 계산합니다.
  const sampleRatio = length / newLength;

  // sampleRatio가 1보다 작은 경우 number[] 타입으로 처리합니다.
  if (sampleRatio < 1) {
    const resampledData = [...audioData];
    return {
      type: 'line',
      buffer: resampledData,
    };
  }

  // sampleRatio가 1보다 크거나 같은 경우 number[][] 타입으로 처리합니다.
  const resampledData = new Array(newLength);
  let minValue, maxValue;

  for (let i = 0; i < newLength; i++) {
    const chunkStart = Math.floor(i * sampleRatio);
    const chunkEnd = Math.floor((i + 1) * sampleRatio);

    minValue = Number.POSITIVE_INFINITY;
    maxValue = Number.NEGATIVE_INFINITY;

    for (let j = chunkStart; j < chunkEnd; j++) {
      if (j >= 0 && j < length) {
        const sample = audioData[j];
        minValue = Math.min(minValue, sample);
        maxValue = Math.max(maxValue, sample);
      }
    }

    resampledData[i] = [minValue, maxValue];
  }

  return {
    type: 'area',
    buffer: resampledData,
  };
};

// Resample audio data to fit the width of the canvas.
export const simpleResampleAudioData = (
  audioData: Float32Array,
  width: number
): number[] => {
  const length = audioData.length;
  const newLength = width;

  // 원하는 너비에 따라 새로운 샘플링 비율을 계산합니다.
  const sampleRatio = length / newLength;

  const resampledData: number[] = [];

  // sampleRatio가 1보다 작은 경우 기존 데이터를 그대로 사용합니다.
  if (sampleRatio < 1) {
    resampledData.push(...audioData);
    return resampledData;
  }

  // sampleRatio가 1보다 크거나 같은 경우 새로운 샘플을 계산하여 저장합니다.
  let minValue, maxValue;

  for (let i = 0; i < newLength; i++) {
    const chunkStart = Math.floor(i * sampleRatio);
    const chunkEnd = Math.floor((i + 1) * sampleRatio);

    minValue = Number.POSITIVE_INFINITY;
    maxValue = Number.NEGATIVE_INFINITY;

    for (let j = chunkStart; j < chunkEnd; j++) {
      if (j >= 0 && j < length) {
        const sample = audioData[j];
        minValue = Math.min(minValue, sample);
        maxValue = Math.max(maxValue, sample);
      }
    }

    resampledData.push(minValue, maxValue);
  }

  return resampledData;
};

// Convert audio data to wav.
export const audioBufferToWav = (audioBuffer: AudioBuffer) => {
  const numOfChan = audioBuffer.numberOfChannels;
  const length = audioBuffer.length * numOfChan * 2 + 44;
  const bufferArray = new ArrayBuffer(length);
  const view = new DataView(bufferArray);

  let offset = 0;

  function writeString(str: string) {
    for (let i = 0; i < str.length; i++) {
      view.setUint8(offset++, str.charCodeAt(i));
    }
  }

  function writeUint32(value: number) {
    view.setUint32(offset, value, true);
    offset += 4;
  }

  function writeUint16(value: number) {
    view.setUint16(offset, value, true);
    offset += 2;
  }

  function writeInt16(value: number) {
    view.setInt16(offset, value, true);
    offset += 2;
  }

  writeString('RIFF');
  writeUint32(length - 8);
  writeString('WAVE');
  writeString('fmt ');
  writeUint32(16);
  writeUint16(1); // PCM format
  writeUint16(numOfChan);
  writeUint32(audioBuffer.sampleRate);
  writeUint32(audioBuffer.sampleRate * 2 * numOfChan);
  writeUint16(numOfChan * 2);
  writeUint16(16);
  writeString('data');
  writeUint32(length - numOfChan * 2 - 44);

  for (let i = 0; i < audioBuffer.length; i++) {
    for (let channel = 0; channel < numOfChan; channel++) {
      // 오디오의 경우 -1과 1 사이의 부동소수점 숫자로 표현된다.
      // 16비트 Wav 파일로 변환하기 위해서는 32767을 곱한다.
      writeInt16(Math.round(audioBuffer.getChannelData(channel)[i] * 32767));
    }
  }

  return bufferArray;
};

// Export audio data as a blob.
export const exportAudioBufferToFile = (
  audioBuffer: AudioBuffer,
  fileName?: string
) => {
  const wav = audioBufferToWav(audioBuffer);
  const blob = new Blob([new DataView(wav)], {
    type: 'audio/wav',
  });
  return new File([blob], fileName || 'audio.wav', {
    type: 'audio/wav',
  });
};
