import { useCallback, useEffect, useState } from 'react';

import IconButton from '../../../components/Button/IconButton';
import Slider from '../../../components/Slider/Slider';
import { Grey } from '../../../styles/Colors';
import { ReactComponent as MuteIcon } from '../assets/icons/MuteIcon.svg';
import { ReactComponent as VolumeIcon } from '../assets/icons/VolumeIcon.svg';

interface VolumeSliderProps {
  value: number;
  update: (value: number) => void;
}

const VolumeSlider = ({ value, update }: VolumeSliderProps) => {
  const [isMute, setIsMute] = useState(false);
  const [sliderValue, setSliderValue] = useState(value);

  const toggleMute = useCallback(() => {
    if (sliderValue > 0) {
      update(0);
    } else {
      update(sliderValue);
      setSliderValue(sliderValue);
    }
    setIsMute((prev) => !prev);
  }, [sliderValue, update]);

  useEffect(() => {
    if (isMute || value === sliderValue) return;
    update(sliderValue);
  }, [sliderValue, isMute, update, value]);

  return (
    <section className="control-volume">
      <IconButton
        className="icon-volume"
        variant="none"
        color={Grey[200]}
        onClick={() => toggleMute()}
      >
        {isMute ? <MuteIcon /> : <VolumeIcon />}
      </IconButton>
      <Slider
        showMarks={false}
        value={sliderValue}
        min={0}
        max={1}
        step={0.01}
        disabled={isMute}
        onChange={(val) => setSliderValue(val)}
      />
    </section>
  );
};

export default VolumeSlider;
