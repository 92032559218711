import './App.css';
import './assets/locale/i18n';
import './pages/screenplay/stores/data/initial';

import { useEffect } from 'react';
import { Route, Routes } from 'react-router-dom';

import Login from './layout/Auth/Login';
import PrivateOutlet from './layout/Auth/PrivateOutlet';
// import Container from './layout/Container/Container';
import Error from './layout/Error/Error';
// import CvcPage from './pages/cvc/CvcPage';
import IndexPage from './pages/index/IndexPage';
import MessagePage from './pages/message/MessagePage';
import NeedsApproval from './pages/screenplay/pages/message/NeedsApproval';
import ZeroShotPage from './pages/screenplay/pages/zeroshot/ZeroShotPage';
// import MssPage from './pages/mss/MssPage';
// import useSessionStorage from './pages/screenplay/hooks/useSessionStorage/useSessionStorage';
import ScreenPlayPage from './pages/screenplay/ScreenPlayPage';
import { WebSocketContextProvider } from './providers/WebSocketProvider';
import { BasicFontSize } from './styles/Typography';

/**
 * fixme darcy 빌드 에러를 방지하기 위한 수정이 되어 있습니다. 무시하고 덮어 쓰셔요~
 */
function App() {
  // Set basic font size
  useEffect(() => {
    document.documentElement.style.fontSize = `${BasicFontSize}px`;
  }, []);

  return (
    <Routes>
      <Route path="/signin" element={<Login />} />
      <Route path="/">
        {/* <Route element={<Container />}>
          <Route element={<PrivateOutlet requiredPermission="/cvc" />}>
            <Route index path="cvc" element={<CvcPage />} />
          </Route>
          <Route element={<PrivateOutlet requiredPermission="/mss" />}>
            <Route path="mss" element={<MssPage />} />
          </Route>
        </Route> */}
        <Route element={<PrivateOutlet requiredPermission="/screenplay" />}>
          <Route path="editor">
            <Route
              path=":id"
              element={
                <WebSocketContextProvider>
                  <ScreenPlayPage />
                </WebSocketContextProvider>
              }
            />
          </Route>
        </Route>
        <Route
          element={<PrivateOutlet requiredPermission="/screenplay/zero-shot" />}
        >
          <Route path="admin/zero-shot" element={<ZeroShotPage />}></Route>
        </Route>
      </Route>

      <Route
        path="/error"
        element={<MessagePage message={<Error />} />}
      ></Route>
      <Route path="/message">
        <Route
          path="needs-approval"
          element={<MessagePage message={<NeedsApproval />} />}
        />
      </Route>
      <Route index element={<IndexPage />} />
    </Routes>
  );
}

export default App;
