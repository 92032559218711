import { ZeroShotParameter } from '@/pages/screenplay/api';
import { Grey, White } from '@/styles/Colors';
import { formatSToMMSS } from '@/util/formatter';
import classNames from 'classnames';
import React, { useCallback, useContext, useEffect, useState } from 'react';

import { Dropdown } from '../../../../../components/Dropdown';
import Waveform from '../../../../../components/Waveform/Waveform';
import useSingleAudioController from '../../../hooks/useSingleAudioController';
import DeleteIcon from '../assets/icons/DeleteIcon.svg';
import InfoIcon from '../assets/icons/InfoIcon.svg';
import PlayIcon from '../assets/icons/PlayIcon.svg';
import StopIcon from '../assets/icons/StopIcon.svg';
import RadioButtonUnSelectIcon from '../components/assets/icons/RadioButtonIcon_UnSelect.svg';
import RadioButtonIcon from '../components/assets/icons/RadioButtonIcon.svg';
import ToggleDownIcon from '../components/assets/icons/ToggleDownIcon.svg';
import IconButton from '../components/IconButton';
import { ZeroShotContext } from '../ZeroShotContextProvider';
import ParameterInfoLayer from './ParameterInfoLayer';
import StyledZeroShotResultItem from './StyledZeroShotResultItem';
import { Language } from './ZeroShotResultPanel';

export interface ResultItem {
  id: string;
  name: string;
  length: number;
  isExpanded: boolean;
  lang: Language;
  text: string;
  sourceId: string;
  audioBuffer: AudioBuffer;
  parameters: ZeroShotParameter;
}
export interface ZeroShotResultItemProps {
  index: number;
  item: ResultItem;
  isSelected: boolean;
  toggle: (id: string) => void;
  select: (id: string, lang: Language) => void;
  deleteFile: (id: string) => void;
}
const ZeroShotResultItem: React.FC<ZeroShotResultItemProps> = ({
  index,
  item,
  isSelected,
  toggle,
  select,
  deleteFile,
}) => {
  const { audioPlayerState, setAudioPlayerState } = useContext(ZeroShotContext);
  const { load, play, stop, currentPosition, isPlaying } =
    useSingleAudioController();
  const [showInfo, setShowInfo] = useState(false);

  const handlePlayStop = useCallback(
    (time?: number) => {
      if (isPlaying) {
        stop();
      } else {
        play(time);
      }
      setAudioPlayerState({
        audioId: item.id,
        isPlaying: !isPlaying,
      });
      return () => {
        stop();
      };
    },
    [isPlaying, stop, play, setAudioPlayerState, item.id]
  );

  useEffect(() => {
    if (!audioPlayerState || !isPlaying) return;
    // 다른 오디오를 재생시킬 경우, 플레이중인 오디오를 정지시킴
    if (audioPlayerState.audioId !== item.id && audioPlayerState.isPlaying) {
      stop();
    }
  }, [audioPlayerState, isPlaying, stop, item.id]);

  useEffect(() => {
    if (!item?.audioBuffer) return;
    load(item.audioBuffer);
  }, [item?.audioBuffer, load]);

  return (
    <StyledZeroShotResultItem
      className={classNames(
        item.isExpanded && 'expanded',
        isSelected && 'selected'
      )}
      onClick={() => {
        !item.isExpanded && toggle(item.id);
      }}
    >
      <section className="inner">
        <section className="item-radio">
          <IconButton
            className="radio-button"
            iconColor={isSelected ? White : Grey[200]}
            width="1.5rem"
            height="1.5rem"
            icon={isSelected ? RadioButtonIcon : RadioButtonUnSelectIcon}
            iconSize="1.5rem"
            onClick={(e) => {
              e.stopPropagation();
              select(item.id, item.lang);
            }}
          />
          <section className="take-number">Take {index}</section>
        </section>
        <section className="item-name" title={item.text}>
          {item.text}
        </section>
        <section className="item-time">
          {formatSToMMSS(item.audioBuffer.duration)}
        </section>
        <section className="item-expand">
          <section className="item-expand">
            <IconButton
              className="expand-button"
              iconColor={White}
              onClick={(e) => {
                e.stopPropagation();
                toggle(item.id);
              }}
              width="2.5rem"
              height="2.5rem"
              iconSize="1.25rem"
              icon={ToggleDownIcon}
            />
          </section>
        </section>
      </section>
      <section className="item-player">
        {item.audioBuffer && (
          <Waveform
            audioBuffer={item.audioBuffer}
            playback={currentPosition}
            onPlaybackChange={handlePlayStop}
          />
        )}
      </section>
      <section className="player-button">
        <IconButton
          iconColor={White}
          width="1.25rem"
          height="1.25rem"
          iconSize="1.25rem"
          icon={isPlaying ? StopIcon : PlayIcon}
          onClick={(e) => {
            e.stopPropagation();
            handlePlayStop();
          }}
        />
        <Dropdown
          anchorElement={
            <IconButton
              iconColor={White}
              width="1.25rem"
              height="1.25rem"
              iconSize="1.25rem"
              icon={InfoIcon}
              onClick={() => {
                setShowInfo(true);
              }}
              disabled={!item.parameters}
            />
          }
          isOpen={showInfo}
          onClose={() => setShowInfo(false)}
          closeOnClickOutside={true}
          offsetY={10}
          style={{
            border: `1px solid ${Grey[200]}`,
            background: 'rgba(68, 68, 68, 1)',
            boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.25)',
            padding: '1rem 1.2rem',
          }}
        >
          {item.parameters && <ParameterInfoLayer values={item.parameters} />}
        </Dropdown>
        <IconButton
          iconColor={White}
          width="1.1rem"
          height="1.1rem"
          iconSize="1.1rem"
          icon={DeleteIcon}
          onClick={(e) => {
            e.stopPropagation();
            deleteFile(item.id);
          }}
        />
      </section>
    </StyledZeroShotResultItem>
  );
};
export default ZeroShotResultItem;
