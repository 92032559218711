import { Black, Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

import { FontSize, FontWeight } from '../../../styles/Typography';

export const StyledScreenPlayHeader = styled.header`
  background-color: ${Black};
  height: 3.375rem;
  border-bottom: 1px solid ${Grey[500]};
  .screenplay {
    display: flex;
    height: 100%;
    flex: auto;
    justify-content: center;
    align-items: center;
    gap: 0.75rem;
    .logo {
      width: 19.3125rem;
      height: 1.25rem;
    }
    .menu {
      height: 1.25rem;
      color: ${Grey[300]};
      text-align: center;
      font-family: Inter;
      font-size: 1.25rem;
      font-style: normal;
      font-weight: 400;
    }
  }
  /* tmp: zero-shot page */
  .btn-admin {
    padding-top: 0.1rem;
    padding-bottom: 0.1rem;
    position: absolute;
    top: 0.8rem;
    right: 8rem;
    z-index: 1;
    font-size: ${FontSize['Md']};
    font-weight: ${FontWeight['SemiBold']};
  }
`;
