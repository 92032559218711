import {
  CVCPanelOrderTypes,
  CVCPanelTypes,
  CVCVoiceFilePanelTypes,
  CVC_PANEL_ORDER_MAP,
} from '@/consts/cvc';
import {
  AUDIO_EDITOR_PANEL_HEIGHT_DEFAULT,
  MODEL_PANEL_DEFAULT,
  RESULTS_PANEL_DEFAULT,
  TOTAL_WIDTH,
  VOICE_FILES_PANEL_HEIGHT_DEFAULT,
} from '@/pages/cvc/config';
import { atom, selector } from 'recoil';

import { DEFAULT_TIMELINE_PANEL_HEIGHT } from '../pages/screenplay/TimelinePanel/TimelinePanel';

export const panelsModel = atom({
  key: 'cvc/panelModel',
  default: {
    [CVC_PANEL_ORDER_MAP.CONTROL]: true,
    [CVC_PANEL_ORDER_MAP.AUDIO_EDITOR]: true,
    [CVC_PANEL_ORDER_MAP.VOICE_FILES]: true,
    [CVC_PANEL_ORDER_MAP.RESULTS]: true,
  },
});

export const expandedPanelIndexModel = atom<CVCPanelOrderTypes | null>({
  key: 'cvc/expandedPanelIndexModel',
  default: null,
});

// 추후 전체 패널 숨김시 토글용 플래그를 하나 추가하여 사용 가능
const closedPanelState = selector({
  key: 'cvc/closedPanelState',
  get: ({ get }) => {
    const panels = get(panelsModel);
    return Object.keys(panels).reduce((pre, cur) => {
      return { ...pre, [cur]: false };
    }, panels);
  },
});

export const panelsModelSelector = selector({
  key: 'cvc/expandedPanelSelector',
  get: ({ get }) => {
    const expandedPanelIndex = get(expandedPanelIndexModel);

    const panels = get(panelsModel);
    if (expandedPanelIndex === null) return panels;
    return {
      ...get(closedPanelState),
      [expandedPanelIndex]: true,
    };
  },
});

export const getPanelRatio = (
  num: number,
  totalWidth = Math.max(document.body.clientWidth, TOTAL_WIDTH)
) => Math.min(num, (num / totalWidth) * 100);
export const panelDefaultSizeModel = atom({
  key: 'cvc/panelDefaultSizeModel',
  default: {
    [CVC_PANEL_ORDER_MAP.CONTROL]: getPanelRatio(MODEL_PANEL_DEFAULT),
    [CVC_PANEL_ORDER_MAP.RESULTS]: getPanelRatio(RESULTS_PANEL_DEFAULT),
    [CVC_PANEL_ORDER_MAP.AUDIO_EDITOR]: AUDIO_EDITOR_PANEL_HEIGHT_DEFAULT,
    [CVC_PANEL_ORDER_MAP.VOICE_FILES]: VOICE_FILES_PANEL_HEIGHT_DEFAULT,
  },
});

export const selectedVoiceFilePanelModel = atom<CVCVoiceFilePanelTypes | null>({
  key: 'cvc/selectedVoiceFilePanelModel',
  default: null,
});

export const selectedCVCPanelModel = atom<CVCPanelTypes | null>({
  key: 'cvc/selectedCVCPanelModel',
  default: null,
});

export const timelinePanelHeightModel = atom({
  key: 'cvc/timelinePanelHeightModel',
  default: DEFAULT_TIMELINE_PANEL_HEIGHT,
});
