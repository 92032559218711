import { useCallback, useEffect, useRef } from 'react';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { updateScenes } from '../api/project';
import {
  Scene,
  editingSceneIdModel,
  sceneListModel,
  selectedProjectIdModel,
} from '../stores/project';

const SceneName: React.FC<{ scene: Scene }> = ({ scene }) => {
  const setSceneList = useSetRecoilState(sceneListModel);
  const [editNameProjectId, setEditNameProjectId] =
    useRecoilState(editingSceneIdModel);
  const resetEditNameProjectId = useResetRecoilState(editingSceneIdModel);

  const onDoubleClick = useCallback(() => {
    setEditNameProjectId(scene.id);
  }, [setEditNameProjectId, scene.id]);

  const selectedProjectId = useRecoilValue(selectedProjectIdModel);
  const changeName = useCallback(
    (value: string) => {
      if (!value.trim()) return;
      setSceneList((oldList) => {
        const newList = oldList.map((pj) => {
          if (pj.id === scene.id) {
            return {
              ...pj,
              name: value,
            };
          }
          return pj;
        });
        updateScenes(selectedProjectId, newList);
        return newList;
      });
    },
    [setSceneList, scene.id, selectedProjectId]
  );

  const onBlur = useCallback(() => {
    resetEditNameProjectId();
  }, [resetEditNameProjectId]);

  const onKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLTextAreaElement>) => {
      if (e.code === 'Enter') {
        e.preventDefault();
        changeName(e.currentTarget.value);
        resetEditNameProjectId();
      }
      if (e.code === 'Escape') {
        resetEditNameProjectId();
      }
    },
    [resetEditNameProjectId, changeName]
  );

  const ref = useRef<HTMLTextAreaElement>(null);
  useEffect(() => {
    if (editNameProjectId === scene.id) {
      ref.current?.focus();
    }
  }, [editNameProjectId, scene.id]);

  return editNameProjectId !== scene.id ? (
    <strong className="list-item-title" onDoubleClick={onDoubleClick}>
      {scene.name}
    </strong>
  ) : (
    <strong className="list-item-title editing">
      <textarea
        ref={ref}
        rows={1}
        className="list-item-title-edit"
        onBlur={onBlur}
        onKeyDown={onKeyDown}
        placeholder={scene.name}
      />
    </strong>
  );
};
export default SceneName;
