import Button from '@/components/Button/Button';
import IconButton from '@/components/Button/IconButton';
import Modal from '@/components/Modal/Modal';
import ModalBody from '@/components/Modal/ModalBody';
import ModalFooter from '@/components/Modal/ModalFooter';
import ModalHeader from '@/components/Modal/ModalHeader';
import { Secondary } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import {
  useRecoilState,
  useRecoilValue,
  useResetRecoilState,
  useSetRecoilState,
} from 'recoil';

import { ReactComponent as BottomArrow } from '../assets/icons/BottomArrow.svg';
import { ReactComponent as RightArrow } from '../assets/icons/RightArrow.svg';
import useSessionStorage from '../hooks/useSessionStorage/useSessionStorage';
import {
  Project,
  editingLineIdModel,
  projectListModel,
  sceneListModel,
  selectedLineIdModel,
  selectedSceneIdModel,
  selectedTakeIdModel,
  takeListModel,
  usedVoiceListSelector,
} from '../stores/project';
import ProjectName from './ProjectName';

interface ProjectItemProps {
  isOpen: boolean;
  project: Project;
  closeOtherProjects: (id: string) => void;
  toggleItem: (id: string) => void;
}
const ProjectItem: React.FC<ProjectItemProps> = ({
  project,
  closeOtherProjects,
  isOpen,
  toggleItem,
}) => {
  const { t } = useTranslation('screenPlay');
  const createdAt = useMemo(() => {
    const date = new Date(project.createdAt);
    return `${date.getFullYear().toFixed(0).slice(-2)}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
  }, [project.createdAt]);
  const updatedAt = useMemo(() => {
    if (project.updatedAt === undefined) return createdAt;
    const date = new Date(project.updatedAt);
    return `${date.getFullYear().toFixed(0).slice(-2)}-${
      date.getMonth() + 1
    }-${date.getDate()}`;
  }, [project.updatedAt, createdAt]);
  const route = useLocation();
  const isCurrentProject = useMemo(() => {
    return route.pathname.startsWith(`/editor/${project.id}`);
  }, [route, project.id]);
  const { saveProject } = useSessionStorage();
  const resetSelectedScene = useResetRecoilState(selectedSceneIdModel);
  const setSelectedLineId = useSetRecoilState(selectedLineIdModel);
  const setSelectedTakeId = useSetRecoilState(selectedTakeIdModel);
  const setEditingLineId = useSetRecoilState(editingLineIdModel);
  const resetSelectedData = useCallback(() => {
    setSelectedLineId(null);
    setSelectedTakeId(null);
    setEditingLineId(null);
  }, [setSelectedLineId, setSelectedTakeId, setEditingLineId]);
  const changeProject = useCallback(() => {
    saveProject();
    resetSelectedScene();
    resetSelectedData();
    closeOtherProjects(project.id);
  }, [
    project.id,
    saveProject,
    resetSelectedScene,
    closeOtherProjects,
    resetSelectedData,
  ]);
  const usedVoice = useRecoilValue(usedVoiceListSelector);
  const sceneList = useRecoilValue(sceneListModel);
  const takeList = useRecoilValue(takeListModel);
  const [projectList, setProjectList] = useRecoilState(projectListModel);
  const resetSelectedSceneId = useResetRecoilState(selectedSceneIdModel);
  const exactProjectList = useMemo(() => {
    return projectList.filter((p) => p.id !== project.id);
  }, [projectList, project.id]);
  const navigate = useNavigate();
  const isImmutable = useMemo(
    () => exactProjectList.length < 1,
    [exactProjectList]
  );
  const deleteProject = useCallback(() => {
    if (isImmutable) return;
    const id = exactProjectList[0]?.id;
    window.localStorage.removeItem(project.id);
    setProjectList(exactProjectList);
    resetSelectedSceneId();
    navigate(`/editor/${id}`);
  }, [
    isImmutable,
    project.id,
    exactProjectList,
    navigate,
    setProjectList,
    resetSelectedSceneId,
  ]);
  const [isOpenDeleteModal, setIsOpenDeleteModal] = useState(false);

  return (
    <li>
      <section
        className={classNames(
          'list-item project',
          isCurrentProject && 'active'
        )}
      >
        <ProjectName project={project} />
        <span className="list-buttons">
          {!isCurrentProject && (
            <NavLink
              to={`/editor/${project.id}`}
              className="btn-open-project"
              onClick={changeProject}
            >
              {t('Open')}
            </NavLink>
          )}
          <IconButton
            color="transparent"
            className="btn-item-toggle"
            onClick={() => toggleItem(project.id)}
          >
            {isOpen ? <BottomArrow /> : <RightArrow />}
          </IconButton>
        </span>
      </section>
      {isOpen && (
        <section className="list-item-detail">
          <ul>
            <li className="project-item">
              <strong>{t('Created')}</strong>
              <span>{createdAt}</span>
            </li>
            <li className="project-item">
              <strong>{t('Last modified')}</strong>
              <span>{updatedAt}</span>
            </li>
            <li className="project-item">
              <strong>{t('Voices')}</strong>
              <span>{usedVoice.length}</span>
            </li>
            <li className="project-item">
              <strong>{t('Scenes')}</strong>
              <span>{sceneList.length}</span>
            </li>
            <li className="project-item">
              <strong>{t('Takes')}</strong>
              <span>{takeList.length}</span>
            </li>
            {project.teamMember && (
              <li className="project-item">
                <strong>{t('Team Member')}</strong>
                <span>{project.teamMember.length}</span>
              </li>
            )}
          </ul>
          {!isImmutable && (
            <section className="project-delete">
              <button
                className="button-reset btn-delete-project"
                onClick={() => setIsOpenDeleteModal(true)}
              >
                {t('Delete Project')}
              </button>
              <Modal
                isPortal={true}
                isOpen={isOpenDeleteModal}
                onClose={() => setIsOpenDeleteModal(false)}
              >
                <ModalHeader>{t('Delete Project')}</ModalHeader>
                <ModalBody>
                  {t('If you delete a Project, All datas will be lost.')}
                </ModalBody>
                <ModalFooter>
                  <Button
                    color="#ffffff"
                    onClick={() => setIsOpenDeleteModal(false)}
                    variant="outlined"
                  >
                    {t('Cancel')}
                  </Button>
                  <Button color={Secondary[200]} onClick={deleteProject}>
                    {t('Delete Anyway')}
                  </Button>
                </ModalFooter>
              </Modal>
            </section>
          )}
        </section>
      )}
    </li>
  );
};
export default ProjectItem;
