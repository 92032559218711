import Profile_4_1 from './images/4-1.png';
import Profile_5_1 from './images/5-1.png';
import Profile_FakeQueen from './images/flint/FakeQueen.png';
import Profile_Finnar from './images/flint/Finnar.png';
import Profile_Licorice from './images/flint/Licorice.png';
import Profile_SansarDrastic from './images/flint/SansarDrastic.png';
import Profile_SansarNormal from './images/flint/SansarNormal.png';
import Profile_YoungMagnus from './images/flint/YoungMagnus.png';

export const flintAgeGenderMap = {
  Leon: { age: 34.683, gender: 13.982 },
  Belle: { age: 7.673, gender: -2.934 },
  // flint 용 추가 voice
  // TODO: api 적용 후 age, gender 값 변경
  Licorice: { age: 25.526, gender: -4.889 },
  'Sansar-Drastic': { age: 47.152, gender: -7.875 },
  'Sansar-Normal': { age: 49.379, gender: -6.459 },
  'Young-Magnus': { age: 37.004, gender: 11.546 },
  'Fake-Queen': { age: 56.884, gender: -7.181 },
  Finnar: { age: 62.948, gender: 9.575 },
  Dimona: { age: 45.432, gender: -6.342 },
  Elrosa: { age: 22.535, gender: -8.261 },
  Lionel: { age: 55.467, gender: 12.358 },
};

export const flintVoiceInfo = [
  {
    id: 'Leon',
    name: 'Leon',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_4_1,
    description: {
      ko: '별이 되어라2 특별 출연 - 그랑리벨 정의단 레온',
      en: 'ASTRA: Knights of Veda Special : Leon',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 그랑리벨 정의단의 레온입니다.',
  },
  {
    id: 'Belle',
    name: 'Belle',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_5_1,
    description: {
      ko: '별이 되어라2 특별 출연 - 모험자의 여관 벨 스노우',
      en: 'ASTRA: Knights of Veda Special : Belle',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 모험자의 여관을 운영하는 벨 스노우 입니다.',
  },
  {
    id: 'Licorice',
    name: 'Licorice',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_Licorice,
    description: {
      ko: '보덴 기사단의 접수원, 리코리스 랑그리아',
      en: 'Licorice, receptionist of the Boden Knights',
      jp: 'ボーデン騎士団の受付、リコリス・ラングリア',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 보덴 기사단의 접수원, 리코리스 랑그리아입니다.',
  },
  {
    id: 'Sansar-Drastic',
    name: 'Sansar(Drastic)',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_SansarDrastic,
    description: {
      ko: '바타르의 하얀 어금니, 산사르',
      en: 'Knights of Veda, Sansar',
      jp: 'バタールの白い牙、サンサール',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 바타르의 하얀 어금니, 산사르입니다.',
  },
  {
    id: 'Sansar-Normal',
    name: 'Sansar(Normal)',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_SansarNormal,
    description: {
      ko: '바타르의 하얀 어금니, 산사르',
      en: 'Knights of Veda, Sansar',
      jp: 'バタールの白い牙、サンサール',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 바타르의 하얀 어금니, 산사르입니다.',
  },
  {
    id: 'Young-Magnus',
    name: 'Young Magnus',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_YoungMagnus,
    description: {
      ko: '오만한 마그누스',
      en: 'Magnus the Arrogant',
      jp: '傲慢なマグヌス',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 오만한 마그누스입니다.',
  },
  {
    id: 'Fake-Queen',
    name: 'Fake Queen',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_FakeQueen,
    description: {
      ko: '니자렘의 왕비이자 에드워드의 어머니, 자야 베하드',
      en: 'Knights of Veda, fake queen',
      jp: 'ニザレムの王妃であり、エドワードの母、ジャヤ・ベハード',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 니자렘의 왕비이자 에드워드의 어머니, 자야 베하드입니다.',
  },
  {
    id: 'Finnar',
    name: 'Finnar',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_Finnar,
    description: {
      ko: '보덴 기사단의 단장, 핀나르',
      en: 'Finnar, leader of the Boden Knights',
      jp: 'ボーデン騎士団の団長、フィンナル',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 보덴 기사단의 단장, 핀나르입니다.',
  },
  {
    id: 'Elrosa',
    name: 'Elrosa',
    language: 'ko',
    character: 'Female',
    description: {
      ko: '핀나르의 보좌관, 엘로사',
      en: 'Elrosa, Assistant of Finnar',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 핀나르의 보좌관, 엘로사입니다.',
  },
  {
    id: 'Lionel',
    name: 'Lionel',
    language: 'ko',
    character: 'Male',
    description: {
      ko: '베다의 기사, 라이오넬',
      en: 'Knights of Veda, Lionel',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 베다의 기사, 라이오넬입니다.',
  },
  {
    id: 'Dimona',
    name: 'Dimona',
    language: 'ko',
    character: 'Female',
    description: {
      ko: '정보 전달자, 디모나 페니골드',
      en: 'Messenger, Dimona',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 정보 전달자, 디모나 페니골드입니다.',
  },
];
