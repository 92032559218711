import Profile_Bareeds from './images/pearlAbyss/Bareeds.png';
import Profile_Birigongdeok from './images/pearlAbyss/Birigongdeok.png';
import Profile_Dohun from './images/pearlAbyss/Dohun.png';
import Profile_Durgeff from './images/pearlAbyss/Durgeff.png';
import Profile_Jordine from './images/pearlAbyss/Jordine.png';
import Profile_Yeonhwa from './images/pearlAbyss/Yeonhwa.png';

export const pearlAbyssAgeGenderMap = {
  Bareeds: { age: 69.378, gender: -7.254 },
  Jordine: { age: 52.628, gender: 13.434 },
  Dohun: { age: 56.119, gender: 8.16 },
  Durgeff: { age: 22.897, gender: -5.068 },
  Yeonhwa: { age: 45.299, gender: -9.542 },
  Birigongdeok: { age: 66.501, gender: -8.52 },
};

export const pearlAbyssVoiceInfo = [
  {
    id: 'Bareeds',
    name: 'Prince Bareeds III',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_Bareeds,
    description: {
      ko: '메디아 왕가의 최후 생존자.',
      en: 'The Last survivor of the Mediah Family.',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 메디아 왕가의 최후 생존자 바리즈 3세입니다.',
  },
  {
    id: 'Jordine',
    name: 'Jordine Ducas',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_Jordine,
    description: {
      ko: '젊고, 진취적이고 야망있는 하이델의 시종장.',
      en: "A Young, enterprising and ambitious, Heidel's chamberlain.",
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 하이델의 시종장 조르다인 입니다.',
  },
  {
    id: 'Dohun',
    name: 'Dohun',
    language: 'ko',
    character: 'Male',
    thumbnail: Profile_Dohun,
    description: {
      ko: '고운마루의 관상가, 항상 술에 절어있는 애주가.',
      en: 'A contemplative of the Gowoon Maru, all time drinker.',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 고운마루의 관상가 도훈입니다.',
  },
  {
    id: 'Durgeff',
    name: 'Durgeff',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_Durgeff,
    description: {
      ko: '매사에 신중하기로 이름난 드리간을 이끄는 실질적인 지도자.',
      en: 'The hands-on leader of Driegan, known for his prudence.',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 두르게프입니다.',
  },
  {
    id: 'Yeonhwa',
    name: 'Yeonhwa',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_Yeonhwa,
    description: {
      ko: '검은 죽음이 하서국에 도달하며 파견된 외교사절.',
      en: 'A diplomatic envoy sent as the Black Death reaches.',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 외교사절 연화입니다.',
  },
  {
    id: 'Birigongdeok',
    name: 'Birigongdeok',
    language: 'ko',
    character: 'Female',
    thumbnail: Profile_Birigongdeok,
    description: {
      ko: '아침의 나라의 영험한 무당.',
      en: 'The Spiritual Shaman of the Morninglands.',
    },
    script:
      '수퍼톤의 스크린플레이에 오신 것을 환영합니다. 저는 영험한 무당 비리공덕입니다.',
  },
];
