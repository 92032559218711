import { getResourceInfo, getUploadInfo, upload } from '@/api';
import {
  exportAudioBufferToFile,
  fetchAudio,
  getAudioBuffer,
  getAudioContext,
} from '@/util/audio';
import axios from 'axios';

import { SPEECH_CONTROL_LIST } from '../ControlPanel/SpeechTab';
import { Line, Parameter, Voice } from '../stores/project';

export type Language = 'ko' | 'en' | 'jp';
export const languageMap = {
  ko: 'ko-nn',
  en: 'en-us',
  jp: 'ja',
};
// 미국 리전
// const url = 'https://screenplay.api.supertone.ai/v1';
// 한국 리전
const url = 'https://screenplay-api.supertone.ai/v1';
export const ttsForVoice = async (
  voice: Voice,
  text: string,
  sessionId: string
) => {
  const { data } = await axios.post(
    `${url}/predictions/tts`,
    {
      text,
      tgt_embed: voice.id,
      lang: languageMap[voice.language as Language],
      age_target: voice.age,
      gender_target: voice.gender,
      pitch_shift: SPEECH_CONTROL_LIST[0].defaultValue,
      pitch_variance: SPEECH_CONTROL_LIST[1].defaultValue,
      speed: SPEECH_CONTROL_LIST[2].defaultValue,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'arraybuffer',
    }
  );
  const audioBuffer = await getAudioBuffer(data);
  /**
   * 서버 resource 절약을 위해 voice 확인용 tts 호출은 resource_id를 통해 캐싱한다.
   */
  const file = exportAudioBufferToFile(audioBuffer as AudioBuffer, 'tts.wav');
  const uploadInfo = await getUploadInfo(sessionId, 'audio.wav', 'audio/wav');
  upload(uploadInfo.data.data.upload_url, file);
  return {
    resource_id: uploadInfo.data.data.resource_id,
    audioBuffer,
  };
};

export const tts = async (
  line: Line,
  voice: Voice,
  parameter: Parameter,
  count = 1
) => {
  const { data } = await axios.post(
    `${url}/predictions/tts`,
    {
      text: line.text,
      tgt_embed: line.voiceId,
      lang: languageMap[line.language],
      age_target: voice.age,
      gender_target: voice.gender,
      pitch_shift: parameter.pitch_shift,
      pitch_variance: parameter.pitch_variance,
      speed: parameter.speed,
      num_takes: count,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'arraybuffer',
    }
  );
  const audioBuffer = await getAudioBuffer(data);
  return { audioBuffer, resource_id: null };
};
export const cvc = async (
  line: Line,
  // cvc 의 경우(최초 호출 빼고는) 녹음 또는 파일을 uploadAPI를 통해 저장하고 resourceAPI 로 가지고 있는다
  file: string | File,
  voice: Voice,
  parameter: Parameter,
  sessionId: string
) => {
  let source: File | null = null;
  let source_resource_id: string | null = null;
  if (typeof file === 'string') {
    const { data } = await getResourceInfo(file);
    const { arrayBuffer } = await fetchAudio(data.data.original.url);
    const audioBuffer = await getAudioBuffer(arrayBuffer);
    source_resource_id = data.data.resource_id;
    source = exportAudioBufferToFile(audioBuffer as AudioBuffer, 'audio.wav');
  } else {
    /**
     * [workaround]
     * 프로젝트가 변경 될 경우, sessionStorage가 file을 저장하지 못하기 때문에,
     * resource_id를 받아서 저장해두어야 프로젝트 변경후 regenerate가 정상 동작 할 수 있다.
     */
    const uploadInfo = await getUploadInfo(sessionId, 'audio.wav', 'audio/wav');
    upload(uploadInfo.data.data.upload_url, file);
    source_resource_id = uploadInfo.data.data.resource_id;
    source = file;
  }
  const { data } = await axios.post(
    `${url}/predictions/cvc`,
    {
      src: source,
      tgt_embed: line.voiceId,
      lang: languageMap[line.language],
      age_target: voice.age,
      gender_target: voice.gender,
      pitch_shift: parameter.pitch_shift,
      pitch_variance: parameter.pitch_variance,
      speed: parameter.speed,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'arraybuffer',
    }
  );
  const audioBuffer = await getAudioBuffer(data);
  return { audioBuffer, resource_id: source_resource_id };
};

export interface ZeroShotParameter extends Parameter {
  similarity: number;
}
export type ZeroShotParameterKey = keyof ZeroShotParameter;
export const ttsZeroShot = async (
  tgt: File,
  text: string,
  lang: Language,
  parameter: ZeroShotParameter,
  num_takes: number
) => {
  const { data } = await axios.post(
    `${url}/predictions/tts`,
    {
      text,
      tgt: tgt,
      lang: languageMap[lang],
      pitch_shift: parameter.pitch_shift,
      pitch_variance: parameter.pitch_variance,
      speed: parameter.speed,
      similarity: parameter.similarity,
      num_takes,
    },
    {
      headers: { 'Content-Type': 'multipart/form-data' },
      responseType: 'arraybuffer',
    }
  );

  const audioCtx = getAudioContext();
  const audioBuffer = await getAudioBuffer(data);
  let buffers = [];
  for (let i = 0; i < (audioBuffer?.numberOfChannels || 0); i++) {
    const channelData = audioBuffer?.getChannelData(i);
    if (channelData) {
      let buffer = audioCtx.createBuffer(
        1,
        channelData.length,
        audioCtx.sampleRate
      );
      buffer.copyToChannel(channelData, 0);
      buffers.push(buffer);
    }
  }
  return buffers;
};
