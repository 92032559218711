import { ResourceStatus } from '@/stores/resource';
import {
  PropsWithChildren,
  createContext,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { io } from 'socket.io-client';

export const conditionalCallback = (
  callback: any,
  resources: ResourceStatus,
  key: string = 'resource_id'
) => {
  return (data: any) => {
    if (resources[data[key]]) {
      callback(data);
    }
  };
};

const WS_URL = process.env.REACT_APP_SOCKET_API_HOST as string;

export const WS_EVENTS = {
  CONNECTED: 'connected',
  JOB_PENDING: 'job_pending',
  JOB_PROCESSING: 'job_processing',
  JOB_DONE: 'job_done',
  JOB_FAILED: 'job_failed',
  DISCONNECT: 'disconnect',
};
interface WebSocketEmitter {
  on: (event: string, callback: (data: any) => void) => void;
  off: (event: string, callback: (data: any) => void) => void;
  sessionId: string;
}
export const WebSocketContext = createContext<WebSocketEmitter>(
  {} as WebSocketEmitter
);
interface Listener {
  [eventName: string]: {
    callback: ((data: any) => void)[];
  };
}
export const WebSocketContextProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const [sessionId, setSessionId] = useState<string>('');
  const [listeners, setListeners] = useState<Listener>({} as Listener);
  const on = useCallback((event: string, callback: (data: any) => void) => {
    setListeners((prev) => {
      const newListener = {
        ...prev,
        [event]: {
          callback: [...(prev[event]?.callback ?? []), callback],
        },
      };
      return newListener;
    });
  }, []);
  const off = useCallback((event: string, callback: (data: any) => void) => {
    setListeners((prev) => {
      const newListener = {
        ...prev,
        [event]: {
          callback: prev[event]?.callback?.filter((cb) => cb !== callback),
        },
      };
      return newListener;
    });
  }, []);
  const [socketIo, setSocketIo] = useState<any>(null);
  useEffect(() => {
    !socketIo && setSocketIo(io(WS_URL));
  }, [socketIo]);
  useEffect(() => {
    if (!socketIo) return;
    const onConnect = ({ session_id }: any) => {
      setSessionId(session_id);
    };
    const onPending = ({ job_id, resource_id }: any) => {
      listeners[WS_EVENTS.JOB_PENDING]?.callback?.forEach((callback) => {
        callback({ job_id, resource_id });
      });
    };
    const onProcessing = ({ job_id, resource_id }: any) => {
      listeners[WS_EVENTS.JOB_PROCESSING]?.callback?.forEach((callback) => {
        callback({ job_id, resource_id });
      });
    };
    const onComplete = ({ job_id, resource_id }: any) => {
      listeners[WS_EVENTS.JOB_DONE]?.callback?.forEach((callback) => {
        callback({ job_id, resource_id });
      });
    };
    const onFailed = ({ job_id, resource_id }: any) => {
      listeners[WS_EVENTS.JOB_FAILED]?.callback?.forEach((callback) => {
        callback({ job_id, resource_id });
      });
    };
    socketIo.on(WS_EVENTS.CONNECTED, onConnect);
    socketIo.on(WS_EVENTS.JOB_PENDING, onPending);
    socketIo.on(WS_EVENTS.JOB_PROCESSING, onProcessing);
    socketIo.on(WS_EVENTS.JOB_DONE, onComplete);
    socketIo.on(WS_EVENTS.JOB_FAILED, onFailed);
    return () => {
      socketIo.off(WS_EVENTS.CONNECTED, onConnect);
      socketIo.off(WS_EVENTS.JOB_PENDING, onPending);
      socketIo.off(WS_EVENTS.JOB_PROCESSING, onProcessing);
      socketIo.off(WS_EVENTS.JOB_DONE, onComplete);
      socketIo.off(WS_EVENTS.JOB_FAILED, onFailed);
    };
  }, [socketIo, listeners]);

  return (
    <WebSocketContext.Provider value={{ on, off, sessionId }}>
      {children}
    </WebSocketContext.Provider>
  );
};
