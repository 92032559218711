import { AudioSource } from './index';

export const createAudioNodes = (
  audioContext: AudioContext,
  audioSources: AudioSource[],
  gainValueNode: GainNode,
  startPosition: number,
  totalDuration: number,
  onEnded?: () => void
): AudioScheduledSourceNode[] => {
  let runningAudioNodeCount = 0;

  const handleAudioNodeEnded = (event: Event) => {
    const audioNode = event.target as AudioScheduledSourceNode;
    audioNode.removeEventListener('ended', handleAudioNodeEnded);
    audioNode.disconnect();

    runningAudioNodeCount--;

    if (runningAudioNodeCount === 0) {
      onEnded?.();
    }
  };

  return audioSources
    .filter(({ position, duration }) => position + duration > startPosition)
    .map(({ position, duration, audioBuffer, type }) => {
      const audioNode = audioContext.createBufferSource();
      audioNode.buffer = audioBuffer;
      audioNode.connect(gainValueNode);
      // bgm인 경우 loop
      if (type === 'audio') {
        audioNode.loop = true;
      }
      audioNode.addEventListener('ended', handleAudioNodeEnded);

      const when = position - startPosition;
      if (when >= 0) {
        const newDuration =
          type === 'audio' ? totalDuration - startPosition : duration;
        audioNode.start(when + audioContext.currentTime, 0, newDuration);
      } else {
        const newDuration =
          type === 'audio' ? totalDuration - startPosition : duration + when;
        audioNode.start(0, -when, newDuration);
      }

      runningAudioNodeCount++;
      return audioNode;
    });
};
