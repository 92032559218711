import ConfirmModal from '@/components/Modal/ConfirmModal/ConfirmModal';
import UserInfo from '@/user/UserInfo';
import { useSUPAuth } from '@supertone-inc/auth-sdk-react';
import { useCallback, useMemo, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';

import Button from '../../../components/Button/Button';
import { Primary } from '../../../styles/Colors';
import { ReactComponent as Logo } from '../assets/images/Logo.svg';
import { StyledScreenPlayHeader } from '../styles/StyledScreenPlayHeader';

const ScreenPlayHeader = () => {
  const { user } = useSUPAuth();
  const navigate = useNavigate();

  const isAdmin = useMemo(() => {
    let isAdmin = false;
    if (!user?.permissions) return isAdmin;
    user.permissions.forEach((permission) => {
      permission.includes('/screenplay/zero-shot') && (isAdmin = true);
    });
    return isAdmin;
  }, [user?.permissions]);

  // admin 에서 로고 클릭시 스크린 플레이로 이동
  const { pathname } = useLocation();
  // 제로샷 페이지 진입 여부
  const isZeroShotPage = useMemo(
    () => pathname.startsWith('/admin/zero-shot'),
    [pathname]
  );
  // 스크린 플레이로 이동시, 컨펌 모듈 오픈을 위한 state
  const [isOpenConfirm, setIsOpenConfirm] = useState(false);
  // 스크린 플레이로 이동
  const goHome = useCallback(() => {
    if (isZeroShotPage) {
      setIsOpenConfirm(true);
    } else {
      return;
    }
  }, [isZeroShotPage]);

  return (
    <StyledScreenPlayHeader>
      <section className="screenplay">
        <h1
          className="logo"
          style={isZeroShotPage ? { cursor: 'pointer' } : {}}
          onClick={goHome}
        >
          <Logo />
        </h1>
      </section>
      <UserInfo />
      {/* tmp: zero-shot page */}
      {isAdmin && !isZeroShotPage && (
        <Button
          variant="outlined"
          className="btn-admin"
          color={Primary[400]}
          size="sm"
          onClick={() => {
            navigate('/admin/zero-shot');
          }}
        >
          Zero Shot
        </Button>
      )}
      {/* {스크린 플레이로 이동 할 경우, 데이터 휘발 됨을 알리는 경고창} */}
      <ConfirmModal
        isPortal={false}
        isOpen={isOpenConfirm}
        title="ScreenPlay로 이동"
        className="confirm-modal"
        confirmText="OK"
        cancelText="Cancel"
        message="작업중이던 내용이 사라지게 됩니다. 계속하시겠습니까?"
        onConfirm={() => {
          navigate('/');
          setIsOpenConfirm(false);
        }}
        onCancel={() => {
          setIsOpenConfirm(false);
        }}
      />
    </StyledScreenPlayHeader>
  );
};
export default ScreenPlayHeader;
