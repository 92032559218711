import classNames from 'classnames';
import { PropsWithChildren } from 'react';
import { createPortal } from 'react-dom';

import { Grey, White } from '../../styles/Colors';
import { ReactComponent as CloseIcon } from '../assets/icons/CloseIcon.svg';
import IconButton from '../Button/IconButton';
import { StyledModal } from './StyledModal';

export interface ModalProps {
  isOpen: boolean;
  onClose?: () => void;
  isPortal?: boolean;
  hideCloseButton?: boolean;
  hideBackdrop?: boolean;
  backdropColor?: string;
  className?: string;
}

const Modal = ({
  children,
  isOpen,
  onClose,
  isPortal = true,
  hideCloseButton = false,
  hideBackdrop = false,
  backdropColor = Grey['800'],
  className,
}: PropsWithChildren<ModalProps>) => {
  const portal = document.getElementById('portal');
  if (!portal || !isOpen) return null;

  const ModalContent = () => (
    <StyledModal
      className={classNames('sup-modal', className)}
      backdropColor={backdropColor}
      isPortal={isPortal}
    >
      {!hideBackdrop && <div className="modal-overlay" onClick={onClose}></div>}
      <div className="modal">
        {!hideCloseButton && (
          <IconButton
            variant="none"
            onClick={onClose}
            color={White}
            className="modal-close-button"
          >
            <CloseIcon />
          </IconButton>
        )}
        {children}
      </div>
    </StyledModal>
  );

  return isPortal ? createPortal(<ModalContent />, portal) : <ModalContent />;
};

export default Modal;
