import { Dropdown } from '@/components/Dropdown';
import classNames from 'classnames';
import { useCallback } from 'react';

import Flag_JP from '../assets/images/Flag_JP.svg';
import Flag_KR from '../assets/images/Flag_KR.svg';
import Flag_US from '../assets/images/Flag_US.svg';
import { Language, languageList } from '../stores/data/config';
import { Line } from '../stores/project';
import { StyledLanguageDropDownLayer } from '../styles/StyledDropDown';

export const LanguageMap: Record<
  Language,
  {
    thumb: string;
    label: string;
  }
> = {
  ko: {
    thumb: Flag_KR,
    label: 'KOR',
  },
  en: {
    thumb: Flag_US,
    label: 'ENG',
  },
  jp: {
    thumb: Flag_JP,
    label: 'JPN',
  },
};

interface LanguageDownLayerProps {
  isOpenLayer: boolean;
  onClose: () => void;
  preventRef?: React.RefObject<HTMLElement>;
  language: Language;
  onChange: (line: Line) => void;
}
const LanguageDropDownLayer: React.FC<LanguageDownLayerProps> = ({
  isOpenLayer,
  onClose,
  preventRef,
  language,
  onChange,
}) => {
  const onClickLanguage = useCallback(
    (lang: Language) => {
      lang !== language && onChange({ language: lang } as Line);
      onClose();
    },
    [language, onChange, onClose]
  );
  return (
    <Dropdown
      style={{
        padding: 0,
        background: 'none',
        border: 0,
        marginLeft: '0.25rem',
        marginTop: '1rem',
      }}
      placement="bottom-right"
      isOpen={isOpenLayer}
      onClose={onClose}
      closeOnClickOutside={true}
      preventRef={preventRef}
    >
      <StyledLanguageDropDownLayer>
        <ul>
          {languageList.map((lang) => {
            return (
              <li
                className={classNames(
                  'dropdown-item',
                  language === lang && 'selected'
                )}
                key={`lang-${lang}`}
                onClick={() => onClickLanguage(lang)}
              >
                <img
                  className="flag"
                  alt={lang}
                  src={LanguageMap[lang].thumb}
                />
                <span className="label">{LanguageMap[lang].label}</span>
              </li>
            );
          })}
        </ul>
      </StyledLanguageDropDownLayer>
    </Dropdown>
  );
};
export default LanguageDropDownLayer;
