import { Grey } from '@/styles/Colors';
import styled from '@emotion/styled';

const StyledZeroShotPage = styled.div`
  .admin-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    height: calc(100vh - 3.375rem);
    .admin-grid-item {
      padding: 1.25rem 1.5rem;
      height: 100%;
      overflow: hidden;
    }
    .zero-shot-tts {
      min-width: 35.75rem;
      background-color: ${Grey[500]};
      margin: 0 3px;
    }
    .zero-shot-source,
    .zero-shot-result {
      flex: 1;
      display: flex;
      flex-direction: column;
      background-color: ${Grey[600]};
    }
    .zero-shot-source {
      border-right: 1px solid ${Grey[500]};
    }
    .zero-shot-result {
      border-left: 1px solid ${Grey[500]};
    }
  }
`;

export default StyledZeroShotPage;
