import Button from '@/components/Button/Button';
import { languageMap } from '@/pages/screenplay/api';
import { Primary, White } from '@/styles/Colors';
import classNames from 'classnames';
import { useCallback, useState } from 'react';

import { ReactComponent as DownloadIcon } from '../components/assets/icons/DownloadIcon.svg';
import Checkbox from '../components/Checkbox';
import { Language, langLabelMap } from './ZeroShotResultPanel';

interface DownloadFileLayerProps {
  languages: Language[];
  fileName: string;
  download: (fileName: string, languages: Language[]) => void;
}
const ZeroShotDownloadFileLayer = ({
  fileName,
  languages,
  download,
}: DownloadFileLayerProps) => {
  const [languageCheckStatus, setLanguageCheckStatus] = useState(
    languages.map(() => true)
  );
  const [saveName, setSaveName] = useState(fileName.split('.')[0]);
  const onDownload = useCallback(() => {
    if (!saveName.trim()) {
      return alert('파일명을 입력해 주세요.');
    }
    download(
      saveName,
      languages.filter((_, index) => languageCheckStatus[index])
    );
  }, [download, languages, languageCheckStatus, saveName]);
  return (
    <section>
      <section className="dropdown-title">Export Option</section>
      <section className="download-file-name">
        <section className="download-file-title">File Name (.zip)</section>
        <input
          className="download-file-input"
          type="text"
          value={saveName}
          onChange={(e) => setSaveName(e.target.value)}
        />
      </section>
      <section className="download-file-language">
        <strong className="title">Select Language</strong>
        <ul>
          {Object.keys(languageMap).map((lang, index) => {
            const disabled = !languages.includes(lang as Language);
            return (
              <li
                className={classNames('lang-check', disabled && 'disabled')}
                key={`download_selection${index}`}
              >
                <Checkbox
                  selectColorSet={{
                    icon: White,
                    bg: Primary[400],
                    border: Primary[400],
                  }}
                  disabled={disabled}
                  checked={languageCheckStatus[index]}
                  onChange={() => {
                    const newCheckStatus = [...languageCheckStatus];
                    newCheckStatus[index] = !newCheckStatus[index];
                    setLanguageCheckStatus(newCheckStatus);
                  }}
                  caption={langLabelMap[lang as Language]}
                />
              </li>
            );
          })}
        </ul>
        <p className="description">
          {!languages.length
            ? '파일이 선택되지 않아 기본값으로 ZIP파일이 다운로드 됩니다.'
            : '선택하지 않은 언어는 기본값으로 출력되어ZIP 파일에 포함됩니다.'}
        </p>
      </section>
      <section className="download-button-area">
        <Button startIcon={<DownloadIcon />} onClick={onDownload}>
          Download
        </Button>
      </section>
    </section>
  );
};
export default ZeroShotDownloadFileLayer;
