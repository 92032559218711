import Button from '@/components/Button/Button';
import { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useProject from '../hooks/useProject';
import { projectListModel } from '../stores/project';
import ProjectItem from './ProjectItem';

const ProjectTab = () => {
  const { t } = useTranslation('screenPlay');
  const projectList = useRecoilValue(projectListModel);
  const { createProject } = useProject();
  const [openIds, setOpenIds] = useState<string[]>([]);
  const closeOtherProjects = useCallback((id: string) => {
    setOpenIds([id]);
  }, []);
  const toggleItem = useCallback(
    (id: string) => {
      if (openIds.includes(id)) {
        setOpenIds(openIds.filter((i) => i !== id));
      } else {
        setOpenIds([...openIds, id]);
      }
    },
    [openIds]
  );
  return (
    <section className="project-tab">
      <section className="create-new">
        <Button
          size="lg"
          color="transparent"
          className="btn-create-new-item"
          onClick={createProject}
        >
          {t('Create New Project')}
        </Button>
      </section>
      <section className="project-list">
        <ul>
          {projectList.map((project) => (
            <ProjectItem
              key={project.id}
              project={project}
              isOpen={openIds.includes(project.id)}
              toggleItem={toggleItem}
              closeOtherProjects={closeOtherProjects}
            />
          ))}
        </ul>
      </section>
    </section>
  );
};
export default ProjectTab;
