import Title from '@/components/Title/Title';
import { useTranslation } from 'react-i18next';
import { useRecoilValue } from 'recoil';

import useUpdateLine from '../hooks/useUpdateLine';
import { lineListModel, selectedSceneIdSelector } from '../stores/project';
import StyledSceneWriterPanel from '../styles/StyledSceneWriterPanel';
import LineControl from './LineControl';
import SceneWriterLineItem from './SceneWriterLineItem';

const SceneWriterPanel = () => {
  const { t } = useTranslation('screenPlay');
  const lineList = useRecoilValue(lineListModel);
  const selectedSceneId = useRecoilValue(selectedSceneIdSelector);
  const onUpdateLine = useUpdateLine();
  return (
    <StyledSceneWriterPanel className="sp-top-panel sp-scene-writer">
      <section className="sp-scene-title">
        <Title size="lg">{t('Scene Writer')}</Title>
        <LineControl />
      </section>
      <section className="sp-scene-content">
        <section className="sp-scene-content-list">
          <ul>
            {lineList.map((line, index) => (
              <SceneWriterLineItem
                onUpdateLine={onUpdateLine}
                key={`${selectedSceneId}_${line.id}`}
                line={line}
                num={index + 1}
              />
            ))}
          </ul>
        </section>
      </section>
    </StyledSceneWriterPanel>
  );
};

export default SceneWriterPanel;
