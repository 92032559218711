import { Grey, Secondary, White } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import LinePlayIcon from '../assets/icons/LinePlayIcon.svg';
import LineStopIcon from '../assets/icons/LineStopIcon.svg';
import AddIconHover from '../assets/images/AddIcon_hover.svg';
import AddIcon from '../assets/images/AddIcon.svg';
import ProjectIcon from '../assets/images/ProjectIcon.svg';

const StyledProjectPanel = styled.section`
  box-shadow: inset 0px -1rem 2.25rem 0px rgba(0, 0, 0, 0.24);
  .create-new {
    height: 6rem;
    align-items: center;
    display: flex;
    border-bottom: 1px solid ${Grey[500]};
    .btn-create-new-item {
      color: ${Grey[300]};
      font-size: 1rem;
      ::before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 2.5rem;
        height: 2.5rem;
        margin-right: 0.85rem;
        background-image: url(${AddIcon});
        background-size: cover;
      }
      &:hover {
        color: ${Grey[50]};
        ::before {
          background-image: url(${AddIconHover});
        }
      }
    }
    &.add-voice {
      border-bottom: 1px solid ${Grey[500]};
    }
  }
  .list-item {
    display: flex;
    padding: 1rem 1.25rem;
    height: 4rem;
    justify-content: space-between;
    align-items: center;
    &:hover {
      background-color: ${Grey[500]};
      .btn-open-project {
        display: inline-flex !important;
      }
    }
    &.active {
      background-color: ${Grey[800]};
      .list-item-title {
        &::before {
          width: 1.5625rem;
          height: 1.5625rem;
        }
      }
      .btn-scene-toggle {
        color: ${Grey[50]};
      }
    }
    .btn-open-project {
      display: none !important;
      border: 1px solid ${White};
      border-radius: 12.5rem;
      color: ${White};
      width: 3.75rem;
      display: inline-flex;
      padding: 0.56rem 0;
      line-height: 100%;
      text-decoration: none;
      font-size: 0.875rem;
      justify-content: center;
      vertical-align: middle;
      &:hover {
        background-color: ${Grey[300]};
      }
    }
    .btn-item-toggle {
      vertical-align: middle;
    }
    .list-buttons {
      display: inline-flex !important;
      align-items: center;
      gap: 0.69rem;
    }
    .btn-voice-icon {
      background-color: transparent;
      border: none;
    }
    .list-item-title {
      user-select: none;
      cursor: text;
      font-weight: ${FontWeight['SemiBold']};
      display: -webkit-box;
      -webkit-box-orient: vertical;
      -webkit-line-clamp: 1;
      overflow: hidden;
      margin-right: 1rem;
      &::before {
        content: '';
        vertical-align: middle;
        display: inline-block;
        width: 1.3125rem;
        height: 1.3125rem;
        margin-right: 0.44rem;
        background-image: url(${ProjectIcon});
        background-size: cover;
      }
      &.editing {
        white-space: nowrap;
        display: flex;
      }
    }
    .list-item-title-edit {
      background-color: transparent;
      border: 0;
      height: calc(1.3125rem - 4px);
      line-height: 100%;
      color: ${Grey[50]};
      white-space: nowrap;
      overflow-y: hidden;
      display: -webkit-box;
      width: calc(100% - 1rem);
      border: none;
      outline: none;
    }
    &.voice {
      height: 4rem;
      .btn-voice-icon {
        width: 2rem;
        height: 2rem;
        display: none;
        :hover {
          background-color: ${Grey[600]};
        }
      }
      &:hover {
        .btn-voice-icon {
          display: inline-flex;
        }
      }
      .voice-profile {
        display: inline-flex;
        align-items: center;
        gap: 0.75rem;
      }
    }
    .btn-voice {
      background-color: transparent;
      border: 0;
      padding: 0;
      outline: 0;
      cursor: pointer;
      width: 2.5rem;
      height: 2.5rem;
      display: flex;
      justify-content: center;
      align-items: center;
      position: relative;
      &[disabled] {
        cursor: not-allowed;
      }
      &:hover {
        img {
          opacity: 0.5;
        }
        &::before {
          content: '';
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
          width: 2rem;
          height: 2rem;
          background-image: url(${LinePlayIcon});
          background-size: cover;
          margin: auto;
          z-index: 1;
        }
      }
      &.playing:hover {
        &::before {
          background-image: url(${LineStopIcon});
        }
      }
      .sup-loading-dots {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
      }
      img {
        width: 2.5rem;
        height: 2.5rem;
        &.empty {
          width: 1.5rem;
          height: 1.5rem;
        }
      }
    }
  }

  .project-list {
    .list-item {
      border-bottom: 1px solid ${Grey[500]};
    }
    .list-item-detail {
      border-bottom: 1px solid ${Grey[500]};
    }
    .project-item {
      display: grid;
      grid-template-columns: 50% 50%;
      padding: 0.75rem 1.25rem;
      white-space: nowrap;
      strong {
        color: ${Grey[100]};
        font-weight: ${FontWeight['Regular']};
      }
      span {
        text-align: right;
        color: ${Grey[400]};
      }
    }
  }
  .project-delete {
    margin: 1rem 1.25rem;
    text-align: right;
    .btn-delete-project {
      cursor: pointer;
      color: ${Grey[400]};
      background-color: transparent;
      border: 0;
      outline: 0;
      padding: 0.55rem 1.25rem;
      border-radius: 0.25rem;
      border: 1px solid ${Grey[400]};
      &:hover {
        color: ${Secondary[200]};
        border: 1px solid ${Secondary[200]};
      }
    }
  }
`;

export default StyledProjectPanel;
