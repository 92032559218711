import { Dropdown } from '@/components/Dropdown';
import classNames from 'classnames';
import { useCallback } from 'react';
import { useRecoilValue } from 'recoil';

import { Line, usedVoiceListSelector } from '../stores/project';
import { StyledVoiceListDropDown } from '../styles/StyledDropDown';
import StyledVoiceProfile from '../styles/StyledVoiceProfile';

interface VoiceListDropDownLayerProps {
  isOpenLayer: boolean;
  onClose: () => void;
  onChange: (line: Line) => void;
  voiceId: string;
  preventRef?: React.RefObject<HTMLElement>;
}
const VoiceListDropDownLayer: React.FC<VoiceListDropDownLayerProps> = ({
  isOpenLayer,
  onClose,
  onChange,
  voiceId,
  preventRef,
}) => {
  const voiceList = useRecoilValue(usedVoiceListSelector);
  const onClickVoice = useCallback(
    (id: string) => {
      id !== voiceId && onChange({ voiceId: id } as Line);
      onClose();
    },
    [voiceId, onChange, onClose]
  );
  return (
    <Dropdown
      isOpen={isOpenLayer}
      onClose={onClose}
      style={{
        padding: 0,
        background: 'none',
        border: 0,
        marginLeft: '0.8rem',
        marginTop: '1rem',
      }}
      placement="bottom-right"
      preventRef={preventRef}
      closeOnClickOutside={true}
    >
      <StyledVoiceListDropDown>
        {voiceList.map((voice) => {
          return (
            <li
              className={classNames(
                'voice-dropdown-item',
                voice.id === voiceId && 'selected'
              )}
              key={`dropdown-${voice.id}`}
              onClick={() => onClickVoice(voice.id)}
            >
              <StyledVoiceProfile>
                {voice.thumbnail ? (
                  <span className="voice-image">
                    <img src={voice.thumbnail} alt="" />
                  </span>
                ) : (
                  <>
                    <span className="voice-image empty">
                      <em>{voice.name.charAt(0)}</em>
                    </span>{' '}
                    {voice.name}
                  </>
                )}
              </StyledVoiceProfile>
            </li>
          );
        })}
      </StyledVoiceListDropDown>
    </Dropdown>
  );
};

export default VoiceListDropDownLayer;
