import { Black, Grey } from '@/styles/Colors';
import { FontWeight } from '@/styles/Typography';
import styled from '@emotion/styled';

import VoiceDefaultImage from '../assets/images/VoiceDefaultImage.svg';

const StyledVoiceProfile = styled.span`
  .voice-image {
    position: relative;
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 50%;
    background-color: ${Black};
    display: inline-flex;
    background-size: cover;
    img {
      border-radius: 50%;
    }
    &.empty {
      /* ::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        width: 1.5rem;
        height: 1.5rem;
        background-image: url(${VoiceDefaultImage});
        background-size: cover;
        margin: auto;
      } */
      align-items: center;
      ::before {
        background-image: none;
      }
      em {
        font-style: normal;
        width: 100%;
        text-align: center;
        font-size: 1.25rem;
        color: ${Grey[400]};
        font-weight: ${FontWeight.Bold};
      }
    }
  }
`;
export default StyledVoiceProfile;
